<div class="modal-dialog-scrollable">
  <div class="modal-header">
    <h5 class="modal-title model-lable">View Account</h5>
    <button
      type="button"
      class="btn-close"
      (click)="closeModel()"
      aria-label="Close"
    ></button>
  </div>
  <div class="modal-body custom-model-design">
    <div class="container-fluid h-100">
      <div class="col-12">
        <div class="custom-view-account">
          <div class="row mb-3">
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Name: </label>
              </div>
              <div class="col-9">
                <span>{{ userData.name }}</span>
              </div>
            </div>
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Phone: </label>
              </div>
              <div class="col-9">
                <span>{{ userData.officePhone }}</span>
              </div>
            </div>
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Website: </label>
              </div>
              <div class="col-9">
                <span>{{ userData.website }}</span>
              </div>
            </div>
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Email: </label>
              </div>
              <div class="col-9">
                <span>{{ userData.emailAddress }}</span>
              </div>
            </div>

            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Fax: </label>
              </div>
              <div class="col-9">
                <span>{{ userData.fax }}</span>
              </div>
            </div>
            <div class="d-flex col-6 mb-3"></div>
            <div class="col-6 mb-3">
              <div
                class="col-12 mb-3"
                style="justify-content: center !important"
              >
                <h2 class="design-color">Primary Address</h2>
              </div>
              <div>
                <div class="d-flex col-12 mb-3">
                  <div class="col-3">
                    <label>Street: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData.accountAddress?.[0]?.street }}</span>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="col-3">
                    <label>City: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData.accountAddress?.[0]?.city }}</span>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="col-3">
                    <label>State: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData.accountAddress?.[0]?.state }}</span>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="col-3">
                    <label>Postal Code: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData.accountAddress?.[0]?.postalCode }}</span>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="col-3">
                    <label>Country: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData.accountAddress?.[0]?.country }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 mb-3">
              <div
                class="col-12 mb-3"
                style="justify-content: center !important"
              >
                <h2 class="design-color">Other Address</h2>
              </div>
              <div>
                <div class="d-flex col-12 mb-3">
                  <div class="col-3">
                    <label>Street: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData.accountAddress?.[1]?.street }}</span>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="col-3">
                    <label>City: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData.accountAddress?.[1]?.city }}</span>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="col-3">
                    <label>State: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData.accountAddress?.[1]?.state }}</span>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="col-3">
                    <label>Postal Code: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData.accountAddress?.[1]?.postalCode }}</span>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="col-3">
                    <label>Country: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData.accountAddress?.[1]?.country }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Description: </label>
              </div>
              <div class="col-9">
                <span>{{ userData.description }}</span>
              </div>
            </div>
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Assigned to: </label>
              </div>
              <div class="col-9">
                <span>{{ userData.assignedName }}</span>
              </div>
            </div>
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Type: </label>
              </div>
              <div class="col-9">
                <span>{{ userData.typeStr }}</span>
              </div>
            </div>
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Industry: </label>
              </div>
              <div class="col-9">
                <span>{{ userData.industryStr }}</span>
              </div>
            </div>
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Annual Revenue: </label>
              </div>
              <div class="col-9">
                <span>{{ userData.totalRevanu }}</span>
              </div>
            </div>
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Employee: </label>
              </div>
              <div class="col-9">
                <span>{{ userData.employee }}</span>
              </div>
            </div>
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Member Of: </label>
              </div>
              <div class="col-9">
                <span>{{ userData.memberOf }}</span>
              </div>
            </div>
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Campaign </label>
              </div>
              <div class="col-9">
                <span>{{ userData.campaign }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-cancel" (click)="closeModel()">
      Close
    </button>
    <button type="button" class="btn btn-submit" (click)="edit(id)">
      Edit
    </button>
  </div>
</div>

import { Component} from "@angular/core";
import { ApiService } from "src/app/helpers/services/api.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent {
  loading = false;
  modelTitle = "";
  constructor(
    private api: ApiService,
  ) {}

  onReload() {
    this.api.reloadData.next(1);
    this.api.reloadData1.next(1);
    this.api.reloadData2.next(1);
  }
}

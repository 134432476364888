import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "src/app/helpers/services/api.service";
import { ToastService } from "src/app/helpers/services/toast.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm!: FormGroup;
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  token: string = "";
  errorMessage: string | null = null;

  constructor(
    private _fb: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    public api_service: ApiService,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((param) => {
      this.token = param["token"];
      this.checkForgotPasswordToken();
    });
    this.resetPasswordForm = this._fb.group(
      {
        password: ["", [Validators.required, Validators.minLength(6)]],
        confirmPassword: ["", [Validators.required, Validators.minLength(6)]],
      },
      { validator: this.passwordMatchValidator }
    );
  }
  checkForgotPasswordToken() {
    this.api_service.checkForgotPasswordToken(this.token).subscribe(
      (res) => {},
      (error) => {
        console.error("Error validating token", error);
        this.errorMessage = "There was an error validating your token.";
        this.router.navigate(["/invalid-token"]);
      }
    );
  }

  get f(): any {
    return this.resetPasswordForm.controls;
  }

  get passwordsMismatch(): boolean {
    return this.resetPasswordForm.hasError("passwordMismatch");
  }

  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  toggleShowConfirmPassword(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const password = control.get("password")?.value;
    const confirmPassword = control.get("confirmPassword")?.value;
    return password && confirmPassword && password !== confirmPassword
      ? { passwordMismatch: true }
      : null;
  }

  onSubmit() {
    if (this.resetPasswordForm.invalid) {
      this.resetPasswordForm.markAllAsTouched();
      return;
    }
    const newPassword = this.resetPasswordForm.value.password;
    const confirmNewPassword = this.resetPasswordForm.value.confirmPassword;
    const forgotPasswordToken = this.token;
    this.api_service
      .restPassword(forgotPasswordToken, newPassword, confirmNewPassword)
      .subscribe(
        (res: any) => {
          this.toast.setSuccessPopupMessage(res.message);
          this.router.navigate(["/login"]);
        },
        (error) => {
          this.toast.setErrorPopupMessage(error.message);
        }
      );
  }
}

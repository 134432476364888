<div class="container-fluid h-100 padding-30px">
    <div class="row margin-right-0px">
        <div class="progress-spinner" *ngIf="loading">
            <p-progressSpinner></p-progressSpinner>
        </div>
        <div class="col-12">
            <p-card header="Linkdin Scrapping Login">
                <form [formGroup]="form" class="custom-form-container">
                    <div class="row">
                        <div class="col-6 form-col">
                            <label>Email
                                <span class="text-invalid"> *</span>
                            </label>
                            <input pInputText type="text" class="form-control" placeholder="Email"
                                formControlName="email" />
                            <div *ngIf="formControl.email.invalid && (formControl.email.dirty || formControl.email.touched)"
                                class="text-invalid" role="alert">
                                <small *ngIf="formControl.email.errors?.required"> Email is required. </small>
                                <small *ngIf="formControl.email.errors?.email">
                                    Please enter valid email address.
                                </small>
                            </div>
                        </div>
                        <div class="col-6 form-col password-container">
                            <label>Password <span class="text-invalid"> *</span>
                            </label>
                            <input formControlName="password" [type]="showPassword ? 'text' : 'password'"
                                class="form-control" placeholder="Password" />
                            <div class="view-password">
                                <i alt="show" [class]="showPassword ? 'bi bi-eye' : 'bi bi-eye-slash'"
                                    (click)="showPassword = !showPassword"></i>
                            </div>
                            <div *ngIf="formControl.password.invalid && (formControl.password.dirty || formControl.password.touched)"
                            class="text-invalid" role="alert">
                            <small *ngIf="formControl.password.errors?.required"> Password is required. </small>
                        </div>
                        </div>
                        <div class="col-6 form-col">
                            <label>Search Keyword <span class="text-invalid"> *</span>

                            </label>
                            <input pInputText type="text" class="form-control" placeholder="Search Keyword"
                                formControlName="search" />
                                <div *ngIf="formControl.search.invalid && (formControl.search.dirty || formControl.search.touched)"
                            class="text-invalid" role="alert">
                            <small *ngIf="formControl.search.errors?.required"> Search Keyword is required. </small>
                        </div>
                        </div>
                        <div class="col-6 form-col">
                            <label>Total Fetch Records <span class="text-invalid"> *</span>
                            </label>
                            <input pInputText type="number" class="form-control" placeholder="Total Fetch Records"
                                formControlName="fetchRecords" />
                                <div *ngIf="formControl.fetchRecords.invalid && (formControl.fetchRecords.dirty || formControl.fetchRecords.touched)"
                            class="text-invalid" role="alert">
                            <small *ngIf="formControl.fetchRecords.errors?.required"> Total Fetch Records is required. </small>
                        </div>
                        </div>
                    </div>
                    <div class="row d-flex mt-4">
                        <div class="col-12 text-end">
                            <button type="submit" class="btn btn-cancel" (click)="onCancel()">
                                Cancel
                            </button>
                            <button type="submit" style="margin-left: 16px !important"
                                class="custom-button-design btn btn-primary btn-lg" (click)="onSubmit()">
                                Submit </button>
                        </div>
                    </div>
                </form>
            </p-card>
        </div>
    </div>
</div>
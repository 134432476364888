import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { API_POINTS } from "src/app/helpers/model/endpoint.model";
import { ApiService } from "src/app/helpers/services/api.service";
import { ToastService } from "src/app/helpers/services/toast.service";
import * as fileObj from "file-saver";

@Component({
  selector: "app-leads-import",
  templateUrl: "./leads-import.component.html",
  styleUrls: ["./leads-import.component.css"],
})
export class LeadsImportComponent {
  @Output() loadingOrg = new EventEmitter<any>();
  @Output() downloadLead = new EventEmitter<string>();
  @Input() importLead = false;
  @Input() importContact = false;
  @Input() data: string = "";
  selectedFile: any;
  endpoint: any = API_POINTS;
  uploadedFilesError: any[] = [];
  files: any;
  addErrorImportPopup = false;
  loading = false;
  constructor(
    public _fb: FormBuilder,
    public http: HttpClient,
    public api: ApiService,
    private toast: ToastService,
  ) {}

  onUpload(event: any) {
    this.files = event?.target?.files[0];
    this.selectedFile = event?.target?.files[0]?.name;
  }

  setLoading(val: any): void {
    this.loadingOrg.emit(val);
  }

  onImportLeadPopUp() {
    this.addErrorImportPopup = true;
  }
  onImportFile() {
    this.loading = true;
    const formData = new FormData();
    formData.append("fileData", this.files);
    formData.append("importExcelType", this.data);
    this.api.post(this.endpoint.importFile, formData).subscribe(
      (res: any) => {
        if (res.status === "SUCCESS") {
          this.toast.setSuccessPopupMessage(res?.message);
          this.addErrorImportPopup = false;
          this.setLoading(res.status);
        }
        if (res.status === "API_STATUS_VALIDATION_FAILURE") {
          this.toast.setErrorPopupMessage(res?.message);
          this.uploadedFilesError = res?.data;
          this.addErrorImportPopup = true;
        }
        this.loading = false;
      },
      (error: any) => {
        this.loading = false;
        this.toast.setErrorPopupMessage(error?.error?.message);
      },
    );
  }
  onDownloadLead() {
    let downloadType = this.data;
    this.loading = true;
    this.api
      .callDownloadFile(this.endpoint.downloadLeadTemplate + downloadType)
      .subscribe(
        (res: any) => {
          fileObj.saveAs(
            res,
            this.importLead ? "Sample_Lead.xlsx" : "Sample_Contact.xlsx",
          );
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.toast.setErrorPopupMessage(error?.error?.message);
        },
      );
  }
}

<div class="container-fluid h-100 padding-30px">
  <div class="row margin-right-0px">
    <div class="progress-spinner" *ngIf="loading">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="col-12">
      <p-card header="{{ !editContactId ? 'Create Contact' : 'Edit Contact' }}">
        <form [formGroup]="contactForm" class="custom-form-container">
          <p-accordion [multiple]="true" [activeIndex]="[0, 1]">
            <p-accordionTab header="Overview">
              <div class="row">
                <div class="col-6 form-col">
                  <label>First Name</label>
                  <input
                    class="form-control"
                    placeholder="FirstName"
                    formControlName="firstName"
                  />
                  <div
                    *ngIf="
                      formAltaControls.firstName?.invalid &&
                      (formAltaControls.firstName?.dirty ||
                        formAltaControls.firstName?.touched)
                    "
                    class="text-invalid"
                    role="alert"
                  >
                    <small
                      *ngIf="formAltaControls.firstName?.errors?.maxlength"
                    >
                      First name cannot be more than 200 characters long.
                    </small>
                  </div>
                </div>
                <div class="col-6 form-col">
                  <label>Last Name <span class="text-invalid"> *</span></label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="LastName"
                    formControlName="lastName"
                  />
                  <div
                    *ngIf="
                      formAltaControls.lastName.invalid &&
                      (formAltaControls.lastName.dirty ||
                        formAltaControls.lastName.touched)
                    "
                    class="text-invalid"
                    role="alert"
                  >
                    <small *ngIf="formAltaControls.lastName.errors?.required">
                      Last name is required
                    </small>
                    <small *ngIf="formAltaControls.lastName.errors?.maxlength">
                      last name cannot be more than 200 characters long.
                    </small>
                  </div>
                </div>
                <div class="col-6 form-col">
                  <label>Office Phone</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Office Phone"
                    formControlName="officePhone"
                  />
                  <div
                    *ngIf="
                      formAltaControls.officePhone?.invalid &&
                      (formAltaControls.officePhone?.dirty ||
                        formAltaControls.officePhone?.touched)
                    "
                    class="text-invalid"
                    role="alert"
                  >
                    <small
                      *ngIf="formAltaControls.officePhone?.errors?.maxlength"
                    >
                      Office phone cannot be more than 15 characters long.
                    </small>
                  </div>
                </div>
                <div class="col-6 form-col">
                  <label>Mobile</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Mobile"
                    formControlName="mobile"
                  />
                  <div
                    *ngIf="
                      formAltaControls.mobile?.invalid &&
                      (formAltaControls.mobile?.dirty ||
                        formAltaControls.mobile?.touched)
                    "
                    class="text-invalid"
                    role="alert"
                  >
                    <small *ngIf="formAltaControls.mobile?.errors?.maxlength">
                      Mobile cannot be more than 15 characters long.
                    </small>
                  </div>
                </div>
                <div class="col-6 form-col">
                  <label>Title</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Title"
                    formControlName="title"
                  />
                  <div
                    *ngIf="
                      formAltaControls.title?.invalid &&
                      (formAltaControls.title?.dirty ||
                        formAltaControls.title?.touched)
                    "
                    class="text-invalid"
                    role="alert"
                  >
                    <small *ngIf="formAltaControls.title?.errors?.maxlength">
                      Title cannot be more than 500 characters long.
                    </small>
                  </div>
                </div>
                <div class="col-6 form-col">
                  <label>Department</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Department"
                    formControlName="department"
                  />
                  <div
                    *ngIf="
                      formAltaControls.department?.invalid &&
                      (formAltaControls.department?.dirty ||
                        formAltaControls.department?.touched)
                    "
                    class="text-invalid"
                    role="alert"
                  >
                    <small
                      *ngIf="formAltaControls.department?.errors?.maxlength"
                    >
                      Department cannot be more than 500 characters long.
                    </small>
                  </div>
                </div>
                <div class="col-6 form-col">
                  <label
                    >Account Name <span class="text-invalid"> *</span></label
                  >
                  <!-- <input type="text" class="form-control" placeholder="Account Name" formControlName="accountName" /> -->
                  <div>
                    <p-dropdown
                      styleClass="width-100-perc"
                      [options]="accountOptions"
                      formControlName="accountName"
                      optionLabel="name"
                      optionValue="id"
                      [showClear]="true"
                      placeholder="--- Select Account name ---"
                    ></p-dropdown>
                  </div>
                  <div
                    *ngIf="
                      formAltaControls.accountName.invalid &&
                      (formAltaControls.accountName.dirty ||
                        formAltaControls.accountName.touched)
                    "
                    class="text-invalid"
                    role="alert"
                  >
                    <small
                      *ngIf="formAltaControls.accountName.errors?.required"
                    >
                      Account name is required
                    </small>
                  </div>
                </div>
                <div class="col-6 form-col">
                  <label>Fax</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Fax"
                    formControlName="fax"
                  />
                  <div
                    *ngIf="
                      formAltaControls.fax?.invalid &&
                      (formAltaControls.fax?.dirty ||
                        formAltaControls.fax?.touched)
                    "
                    class="text-invalid"
                    role="alert"
                  >
                    <small *ngIf="formAltaControls.fax?.errors?.maxlength">
                      Fax cannot be more than 500 characters long.
                    </small>
                  </div>
                </div>
                <div
                  class="col-12 form-col"
                  formArrayName="emailAddressDetails"
                >
                  <label>Email Address</label>
                  <div
                    class="col-6"
                    style="
                      display: flex;
                      justify-content: space-between;"
                    *ngFor="
                      let email of emailAddressDetailsArray?.controls;
                      let i = index
                    "
                  >
                    <div
                      class="col-11"
                      style="margin-bottom: 10px"
                      [formGroupName]="i"
                    >
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Email Address"
                        formControlName="emailAddress"
                      />
                      <div
                        *ngIf="
                          emailAddressDetailsArray.controls[i].get(
                            'emailAddress'
                          )?.errors &&
                          (emailAddressDetailsArray.controls[i].get(
                            'emailAddress'
                          )?.dirty ||
                            emailAddressDetailsArray.controls[i].get(
                              'emailAddress'
                            )?.touched)
                        "
                        class="text-invalid"
                        role="alert"
                      >
                        <small
                          *ngIf="
                            emailAddressDetailsArray.controls[i].get(
                              'emailAddress'
                            )?.errors?.maxlength
                          "
                        >
                          Email address cannot be more than 200 characters long.
                        </small>
                        <small
                          *ngIf="
                            emailAddressDetailsArray.controls[i].get(
                              'emailAddress'
                            )?.errors?.pattern
                          "
                        >
                          Please enter valid email address
                        </small>
                      </div>
                    </div>
                    <button
                      type="submit"
                      *ngIf="i > 0"
                      class="custom-add-button-design btn btn-primary col-1"
                      (click)="removeEmailAddress(i)"
                    >
                      -
                    </button>
                    <button
                      type="submit"
                      *ngIf="i == 0"
                      class="custom-add-button-design btn btn-primary col-1"
                      (click)="addEmailAddress()"
                    >
                      +
                    </button>
                  </div>
                </div>
                <!-- <div class="row"> -->
                <div class="col-6 form-col mt-4">
                  <div [formGroup]="primaryAddressDetails">
                    <h4 class="design-color">Primary Address</h4>
                    <div class="mb-2">
                      <label>Address</label>
                      <textarea
                        class="form-control"
                        placeholder="Address"
                        id="floatingTextarea"
                        formControlName="address"
                      ></textarea>
                      <div
                        class="text-invalid"
                        *ngIf="
                          contactForm
                            .get('primaryAddressDetails.address')
                            ?.hasError('maxlength') &&
                          (contactForm.get('primaryAddressDetails.address')
                            ?.touched ||
                            contactForm?.get('primaryAddressDetails.address')
                              ?.dirty)
                        "
                      >
                        Address cannot be more than 500 characters long.
                      </div>
                    </div>

                    <div class="mb-2">
                      <label>City</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="City"
                        formControlName="city"
                      />
                      <div
                        class="text-invalid"
                        *ngIf="
                          contactForm
                            .get('primaryAddressDetails.city')
                            ?.hasError('maxlength') &&
                          (contactForm.get('primaryAddressDetails.city')
                            ?.touched ||
                            contactForm?.get('primaryAddressDetails.city')
                              ?.dirty)
                        "
                      >
                        City cannot be more than 200 characters long.
                      </div>
                    </div>
                    <div class="mb-2">
                      <label>State</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="State"
                        formControlName="state"
                      />
                      <div
                        class="text-invalid"
                        *ngIf="
                          contactForm
                            .get('primaryAddressDetails.state')
                            ?.hasError('maxlength') &&
                          (contactForm.get('primaryAddressDetails.state')
                            ?.touched ||
                            contactForm?.get('primaryAddressDetails.state')
                              ?.dirty)
                        "
                      >
                        State cannot be more than 200 characters long.
                      </div>
                    </div>
                    <div class="mb-2">
                      <label>Postal Code</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Postal Code"
                        formControlName="postalCode"
                      />
                      <div
                        class="text-invalid"
                        *ngIf="
                          contactForm
                            .get('primaryAddressDetails.postalCode')
                            ?.hasError('maxlength') &&
                          (contactForm.get('primaryAddressDetails.postalCode')
                            ?.touched ||
                            contactForm?.get('primaryAddressDetails.postalCode')
                              ?.dirty)
                        "
                      >
                        Postal code cannot be more than 200 characters long.
                      </div>
                    </div>
                    <div class="mb-2">
                      <label>Country</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Country"
                        formControlName="country"
                      />
                      <div
                        class="text-invalid"
                        *ngIf="
                          contactForm
                            .get('primaryAddressDetails.country')
                            ?.hasError('maxlength') &&
                          (contactForm.get('primaryAddressDetails.country')
                            ?.touched ||
                            contactForm?.get('primaryAddressDetails.country')
                              ?.dirty)
                        "
                      >
                        Country cannot be more than 200 characters long.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 form-col mt-4">
                  <div [formGroup]="otherAddressDetails">
                    <h4 class="design-color">Other Address</h4>
                    <div class="mb-2">
                      <label>Other Address</label>
                      <textarea
                        class="form-control"
                        placeholder="Other Address"
                        id="floatingTextarea"
                        formControlName="address"
                      ></textarea>
                      <div
                        class="text-invalid"
                        *ngIf="
                          contactForm
                            .get('otherAddressDetails.address')
                            ?.hasError('maxlength') &&
                          (contactForm.get('otherAddressDetails.address')
                            ?.touched ||
                            contactForm?.get('otherAddressDetails.address')
                              ?.dirty)
                        "
                      >
                        Address cannot be more than 500 characters long.
                      </div>
                    </div>
                    <div class="mb-2">
                      <label>City</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="City"
                        formControlName="city"
                      />
                      <div
                        class="text-invalid"
                        *ngIf="
                          contactForm
                            .get('otherAddressDetails.city')
                            ?.hasError('maxlength') &&
                          (contactForm.get('otherAddressDetails.city')
                            ?.touched ||
                            contactForm?.get('otherAddressDetails.city')?.dirty)
                        "
                      >
                        City cannot be more than 200 characters long.
                      </div>
                    </div>
                    <div class="mb-2">
                      <label>State</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="State"
                        formControlName="state"
                      />
                      <div
                        class="text-invalid"
                        *ngIf="
                          contactForm
                            .get('otherAddressDetails.state')
                            ?.hasError('maxlength') &&
                          (contactForm.get('otherAddressDetails.state')
                            ?.touched ||
                            contactForm?.get('otherAddressDetails.state')
                              ?.dirty)
                        "
                      >
                        State cannot be more than 200 characters long.
                      </div>
                    </div>
                    <div class="mb-2">
                      <label>Postal Code</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Postal Code"
                        formControlName="postalCode"
                      />
                      <div
                        class="text-invalid"
                        *ngIf="
                          contactForm
                            .get('otherAddressDetails.postalCode')
                            ?.hasError('maxlength') &&
                          (contactForm.get('otherAddressDetails.postalCode')
                            ?.touched ||
                            contactForm?.get('otherAddressDetails.postalCode')
                              ?.dirty)
                        "
                      >
                        Postal code cannot be more than 200 characters long.
                      </div>
                    </div>
                    <div class="mb-2">
                      <label>Country</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Country"
                        formControlName="country"
                      />
                      <div
                        class="text-invalid"
                        *ngIf="
                          contactForm
                            .get('otherAddressDetails.country')
                            ?.hasError('maxlength') &&
                          (contactForm.get('otherAddressDetails.country')
                            ?.touched ||
                            contactForm?.get('otherAddressDetails.country')
                              ?.dirty)
                        "
                      >
                        Country cannot be more than 200 characters long.
                      </div>
                    </div>
                  </div>
                  <div class="col-6 form-col">
                    <input
                      class="form-check-input"
                      style="margin-top: 6px; margin-right: 10px"
                      formControlName="copyAddress"
                      type="checkbox"
                      (change)="onCopyAddressChange()"
                    />
                    <label>Copy address from primary address</label>
                  </div>
                </div>
                <!-- </div> -->
                <div class="col-12 form-col">
                  <label>Description</label>
                  <textarea
                    class="form-control"
                    placeholder="Description"
                    rows="4"
                    cols="50"
                    id="floatingTextarea"
                    formControlName="description"
                  ></textarea>
                  <div
                    *ngIf="
                      formAltaControls.description?.invalid &&
                      (formAltaControls.description?.dirty ||
                        formAltaControls.description?.touched)
                    "
                    class="text-invalid"
                    role="alert"
                  >
                    <small
                      *ngIf="formAltaControls.description?.errors?.maxlength"
                    >
                      Description cannot be more than 500 characters long.
                    </small>
                  </div>
                </div>
              </div>
            </p-accordionTab>
            <p-accordionTab header="More Information">
              <div class="row">
                <div class="col-6 form-col">
                  <label>Lead Source</label>
                  <!-- <input type="text" class="form-control" placeholder="Lead Source" formControlName="leadSource" /> -->
                  <div>
                    <p-dropdown
                      styleClass="width-100-perc"
                      [options]="dropdownOptions"
                      formControlName="leadSource"
                      optionLabel="key"
                      optionValue="value"
                      [showClear]="true"
                      placeholder="--- Select Lead source---"
                    ></p-dropdown>
                  </div>
                </div>
                <div class="col-6 form-col">
                  <label>Report to</label>
                  <!-- <input type="text" class="form-control" placeholder="Report to" formControlName="reportTo" /> -->
                  <div>
                    <p-dropdown
                      styleClass="width-100-perc"
                      [options]="userDetailOptions"
                      formControlName="reportTo"
                      optionLabel="name"
                      optionValue="id"
                      [showClear]="true"
                      placeholder="--- Select Report to ---"
                    ></p-dropdown>
                  </div>
                </div>
                <div class="col-6 form-col">
                  <label>Campaign</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Campaign"
                    formControlName="campaign"
                  />
                  <!-- <select  class="form-control form-select" formControlName="campaign" name="cars" id="cars">
          <option hidden value="">---Select Campaign---</option>
          <option [value]="status_item.value" *ngFor="let status_item of dropdownType">
            {{status_item.value}}
            </option>
        </select> -->
                  <div
                    *ngIf="
                      formAltaControls.campaign?.invalid &&
                      (formAltaControls.campaign?.dirty ||
                        formAltaControls.campaign?.touched)
                    "
                    class="text-invalid"
                    role="alert"
                  >
                    <small *ngIf="formAltaControls.campaign?.errors?.maxlength">
                      Campaign cannot be more than 500 characters long.
                    </small>
                  </div>
                </div>
              </div>
            </p-accordionTab>
          </p-accordion>
          <!-- submit form-->
          <div class="row d-flex mt-4">
            <div class="col-12 text-end">
              <button type="submit" class="btn btn-cancel" (click)="onCancel()">
                Cancel
              </button>
              <button
                type="submit"
                style="margin-left: 16px !important"
                class="custom-button-design btn btn-primary btn-lg"
                (click)="onSubmit()"
              >
                {{ !editContactId ? "Submit" : "Update" }}
              </button>
            </div>
          </div>
        </form>
      </p-card>
    </div>
  </div>
</div>

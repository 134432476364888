<div class="container">
  <img src="../../../assets/images/image.jpg" alt="" />
  <div class="card" style="width: 20rem;">
    <div class="card-body">
      <h3 class="card-title text-center">Token Expired!</h3>
      <p class="card-text text-center">
        Your forgot password request has expired.<br />Use Forgot Password again
        to continue.
      </p>
    </div>
  </div>
</div>

<div class="container-fluid h-100 padding-30px">
  <div class="col-12">
    <p-card header="{{ !this?.leadId ? 'Create Lead' : 'Edit Lead' }}">
      <form [formGroup]="form" class="custom-form-container">
        <p-accordion [multiple]="true" [activeIndex]="[0, 1, 2]">
          <p-accordionTab header="Overview">
            <div class="row">
              <div class="col-6 form-col">
                <label>First Name<span class="invaild-text"> *</span></label>
                <input
                  pInputText
                  type="text"
                  class="form-control"
                  placeholder="First Name"
                  formControlName="firstName"
                />
                <div
                  *ngIf="
                    formAltaControls.firstName.invalid &&
                    (formAltaControls.firstName.dirty ||
                      formAltaControls.firstName.touched)
                  "
                  class="text-invalid"
                  role="alert"
                >
                  <small *ngIf="formAltaControls.firstName.errors?.required">
                    First name is required
                  </small>
                  <small *ngIf="formAltaControls.firstName.errors?.maxlength">
                    First name cannot be more than 200 characters long.
                  </small>
                </div>
                <div class="form-required" *ngIf="isInvalid">
                  {{ validationErrors["firstName"] }}
                </div>
              </div>
              <div class="col-6 form-col">
                <label>Last Name</label>
                <input
                  pInputText
                  type="test"
                  class="form-control"
                  placeholder="Last Name"
                  formControlName="lastName"
                  name="lastName"
                />
                <div
                  *ngIf="
                    formAltaControls.lastName.invalid &&
                    (formAltaControls.lastName.dirty ||
                      formAltaControls.lastName.touched)
                  "
                  class="text-invalid"
                  role="alert"
                >
                  <small *ngIf="formAltaControls.lastName.errors?.maxlength">
                    Last name cannot be more than 200 characters long.
                  </small>
                </div>
                <div class="form-required" *ngIf="isInvalid">
                  {{ validationErrors["lastName"] }}
                </div>
              </div>
              <div class="col-6 form-col">
                <label>Office Phone</label>
                <input
                  pInputText
                  type="text"
                  class="form-control"
                  placeholder="Office Phone"
                  formControlName="officePhone"
                />
                <div
                  *ngIf="
                    formAltaControls.officePhone?.invalid &&
                    (formAltaControls.officePhone?.dirty ||
                      formAltaControls.officePhone?.touched)
                  "
                  class="text-invalid"
                  role="alert"
                >
                  <small
                    *ngIf="formAltaControls.officePhone?.errors?.maxlength"
                  >
                    Office Phone cannot be more than 15 characters long.
                  </small>
                </div>
                <div class="form-required" *ngIf="isInvalid">
                  {{ validationErrors["officePhone"] }}
                </div>
              </div>
              <div class="col-6 form-col">
                <label>Title</label>
                <input
                  pInputText
                  type="text"
                  class="form-control"
                  placeholder="Title"
                  formControlName="title"
                />
                <div
                  *ngIf="
                    formAltaControls.title?.invalid &&
                    (formAltaControls.title?.dirty ||
                      formAltaControls.title?.touched)
                  "
                  class="text-invalid"
                  role="alert"
                >
                  <small *ngIf="formAltaControls.title?.errors?.maxlength">
                    Title cannot be more than 200 characters long.
                  </small>
                </div>
                <div class="form-required" *ngIf="isInvalid">
                  {{ validationErrors["title"] }}
                </div>
              </div>

              <div class="col-6 form-col">
                <label>Department</label>
                <input
                  pInputText
                  type="text"
                  class="form-control"
                  placeholder="Department"
                  formControlName="department"
                />
                <div
                  *ngIf="
                    formAltaControls.department?.invalid &&
                    (formAltaControls.department?.dirty ||
                      formAltaControls.department?.touched)
                  "
                  class="text-invalid"
                  role="alert"
                >
                  <small *ngIf="formAltaControls.department?.errors?.maxlength">
                    Department cannot be more than 200 characters long.
                  </small>
                </div>
                <div class="form-required" *ngIf="isInvalid">
                  {{ validationErrors["department"] }}
                </div>
              </div>
              <div class="col-6 form-col">
                <label>Fax</label>
                <input
                  pInputText
                  type="text"
                  class="form-control"
                  placeholder="Fax"
                  formControlName="fax"
                />
                <div
                  *ngIf="
                    formAltaControls.fax?.invalid &&
                    (formAltaControls.fax?.dirty ||
                      formAltaControls.fax?.touched)
                  "
                  class="text-invalid"
                  role="alert"
                >
                  <small *ngIf="formAltaControls.fax?.errors?.maxlength">
                    Fax cannot be more than 200 characters long.
                  </small>
                </div>
                <div class="form-required" *ngIf="isInvalid">
                  {{ validationErrors["fax"] }}
                </div>
              </div>
              <!-- <input pInputText type="text" class="form-control" placeholder="Account Name" formControlName="accountName" /> -->
              <div class="col-6 form-col">
                <div>
                  <label
                    >Account Name<span class="invaild-text"> *</span></label
                  >
                </div>
                <div
                  class="col-12"
                  style="display: flex; justify-content: space-between;"
                >
                  <div class="col-11">
                    <p-dropdown
                      styleClass="width-100-perc"
                      [options]="accountOptions"
                      formControlName="accountName"
                      optionLabel="name"
                      optionValue="id"
                      [showClear]="true"
                      placeholder="--- Select Account ---"
                    ></p-dropdown>
                    <div
                      *ngIf="
                        formAltaControls.accountName.invalid &&
                        (formAltaControls.accountName.dirty ||
                          formAltaControls.accountName.touched)
                      "
                      class="text-invalid"
                      role="alert"
                    >
                      <small
                        *ngIf="formAltaControls.accountName.errors?.required"
                      >
                        Account name is required
                      </small>
                    </div>
                    <div class="form-required" *ngIf="isInvalid">
                      {{ validationErrors["accountName"] }}
                    </div>
                  </div>
                  <button
                    type="submit"
                    class="custom-add-button-design btn btn-primary col-1"
                    (click)="openAccountPopup()"
                  >
                    <i class="bi bi-plus"></i>
                  </button>
                </div>
              </div>
              <div class="col-6 form-col">
                <label>Website</label>
                <input
                  pInputText
                  type="text"
                  class="form-control"
                  placeholder="Website"
                  formControlName="website"
                />
                <div
                  *ngIf="
                    formAltaControls.website?.invalid &&
                    (formAltaControls.website?.dirty ||
                      formAltaControls.website?.touched)
                  "
                  class="text-invalid"
                  role="alert"
                >
                  <small *ngIf="formAltaControls.website?.errors?.maxlength">
                    Website cannot be more than 200 characters long.
                  </small>
                </div>
                <div class="form-required" *ngIf="isInvalid">
                  {{ validationErrors["website"] }}
                </div>
              </div>
              <div class="col-6 form-col">
                <label>LinkedIn Url</label>
                <input
                  pInputText
                  type="text"
                  class="form-control"
                  placeholder="LinkedIn Url"
                  formControlName="linkedInUrl"
                />
                <div
                  *ngIf="
                    formAltaControls.linkedInUrl?.invalid &&
                    (formAltaControls.linkedInUrl?.dirty ||
                      formAltaControls.linkedInUrl?.touched)
                  "
                  class="text-invalid"
                  role="alert"
                >
                  <small
                    *ngIf="formAltaControls.linkedInUrl?.errors?.maxlength"
                  >
                    LinkedIn Url cannot be more than 200 characters long.
                  </small>
                </div>
                <div class="form-required" *ngIf="isInvalid">
                  {{ validationErrors["linkedInUrl"] }}
                </div>
              </div>
              <div class="col-6 form-col"></div>
              <div class="col-6 form-col mt-4">
                <div [formGroup]="primaryAddress">
                  <h4 class="design-color">Primary Address</h4>
                  <div class="col-12 form-col">
                    <label>Address </label>
                    <input
                      pInputText
                      type="text"
                      class="form-control"
                      placeholder="Address"
                      formControlName="addressDetail"
                    />
                    <div
                      class="text-invalid"
                      *ngIf="
                        form
                          .get('primaryAddress.addressDetail')
                          ?.hasError('maxlength') &&
                        (form.get('primaryAddress.addressDetail')?.touched ||
                          form?.get('primaryAddress.addressDetail')?.dirty)
                      "
                    >
                      Address cannot be more than 500 characters long.
                    </div>
                    <div class="form-required" *ngIf="isInvalid">
                      {{ validationErrors["addressDetail"] }}
                    </div>
                  </div>
                  <div class="col-12 form-col">
                    <label>City </label>
                    <input
                      pInputText
                      type="text"
                      class="form-control"
                      placeholder="City"
                      formControlName="city"
                    />
                    <div
                      class="text-invalid"
                      *ngIf="
                        form
                          .get('primaryAddress.city')
                          ?.hasError('maxlength') &&
                        (form.get('primaryAddress.city')?.touched ||
                          form?.get('primaryAddress.city')?.dirty)
                      "
                    >
                      City cannot be more than 200 characters long.
                    </div>
                    <div class="form-required" *ngIf="isInvalid">
                      {{ validationErrors["primaryAddress.city"] }}
                    </div>
                  </div>
                  <div class="col-12 form-col">
                    <label>State </label>
                    <input
                      pInputText
                      type="text"
                      class="form-control"
                      placeholder="State"
                      formControlName="state"
                    />
                    <div
                      class="text-invalid"
                      *ngIf="
                        form
                          .get('primaryAddress.state')
                          ?.hasError('maxlength') &&
                        (form.get('primaryAddress.state')?.touched ||
                          form?.get('primaryAddress.state')?.dirty)
                      "
                    >
                      State cannot be more than 200 characters long.
                    </div>
                    <div class="form-required" *ngIf="isInvalid">
                      {{ validationErrors["primaryAddress.city"] }}
                    </div>
                  </div>
                  <div class="col-12 form-col">
                    <label>Postal code </label>
                    <input
                      pInputText
                      type="text"
                      class="form-control"
                      placeholder="Postal Code"
                      formControlName="postalCode"
                    />
                    <div
                      class="text-invalid"
                      *ngIf="
                        form
                          .get('primaryAddress.postalCode')
                          ?.hasError('maxlength') &&
                        (form.get('primaryAddress.postalCode')?.touched ||
                          form?.get('primaryAddress.postalCode')?.dirty)
                      "
                    >
                      Postal code cannot be more than 200 characters long.
                    </div>
                    <div class="form-required" *ngIf="isInvalid">
                      {{ validationErrors["primaryAddress.postalCode"] }}
                    </div>
                  </div>
                  <div class="col-12 form-col">
                    <label>Country </label>
                    <input
                      pInputText
                      type="text"
                      class="form-control"
                      placeholder="Country"
                      formControlName="country"
                    />
                    <div
                      class="text-invalid"
                      *ngIf="
                        form
                          .get('primaryAddress.country')
                          ?.hasError('maxlength') &&
                        (form.get('primaryAddress.country')?.touched ||
                          form?.get('primaryAddress.country')?.dirty)
                      "
                    >
                      country cannot be more than 200 characters long.
                    </div>
                    <div class="form-required" *ngIf="isInvalid">
                      {{ validationErrors["primaryAddress.country"] }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6 form-col mt-4">
                <div [formGroup]="otherAddress">
                  <h4 class="design-color">Other Address</h4>
                  <div class="col-12 form-col">
                    <label>Address </label>
                    <input
                      pInputText
                      type="text"
                      class="form-control"
                      placeholder="Address"
                      formControlName="addressDetail"
                    />
                    <div
                      class="text-invalid"
                      *ngIf="
                        form
                          .get('otherAddress.addressDetail')
                          ?.hasError('maxlength') &&
                        (form.get('otherAddress.addressDetail')?.touched ||
                          form?.get('otherAddress.addressDetail')?.dirty)
                      "
                    >
                      Address cannot be more than 500 characters long.
                    </div>
                    <div class="form-required" *ngIf="isInvalid">
                      {{ validationErrors["otherAddress.addressDetail"] }}
                    </div>
                  </div>
                  <div class="col-12 form-col">
                    <label>City </label>
                    <input
                      pInputText
                      type="text"
                      class="form-control"
                      placeholder="City"
                      formControlName="city"
                    />
                    <div
                      class="text-invalid"
                      *ngIf="
                        form.get('otherAddress.city')?.hasError('maxlength') &&
                        (form.get('otherAddress.city')?.touched ||
                          form?.get('otherAddress.city')?.dirty)
                      "
                    >
                      City cannot be more than 500 characters long.
                    </div>
                    <div class="form-required" *ngIf="isInvalid">
                      {{ validationErrors["otherAddress.addressDetail"] }}
                    </div>
                  </div>
                  <div class="col-12 form-col">
                    <label>State </label>
                    <input
                      pInputText
                      type="text"
                      class="form-control"
                      placeholder="State"
                      formControlName="state"
                    />
                    <div
                      class="text-invalid"
                      *ngIf="
                        form.get('otherAddress.state')?.hasError('maxlength') &&
                        (form.get('otherAddress.state')?.touched ||
                          form?.get('otherAddress.state')?.dirty)
                      "
                    >
                      State cannot be more than 500 characters long.
                    </div>
                    <div class="form-required" *ngIf="isInvalid">
                      {{ validationErrors["otherAddress.addressDetail"] }}
                    </div>
                  </div>
                  <div class="col-12 form-col">
                    <label>Postal code </label>
                    <input
                      pInputText
                      type="text"
                      class="form-control"
                      placeholder="Postal Code"
                      formControlName="postalCode"
                    />
                    <div
                      class="text-invalid"
                      *ngIf="
                        form
                          .get('otherAddress.postalCode')
                          ?.hasError('maxlength') &&
                        (form.get('otherAddress.postalCode')?.touched ||
                          form?.get('otherAddress.postalCode')?.dirty)
                      "
                    >
                      Postal code cannot be more than 500 characters long.
                    </div>
                    <div class="form-required" *ngIf="isInvalid">
                      {{ validationErrors["otherAddress.postalCode"] }}
                    </div>
                  </div>
                  <div class="col-12 form-col">
                    <label>Country </label>
                    <input
                      pInputText
                      type="text"
                      class="form-control"
                      placeholder="Country"
                      formControlName="country"
                    />
                    <div
                      class="text-invalid"
                      *ngIf="
                        form
                          .get('otherAddress.country')
                          ?.hasError('maxlength') &&
                        (form.get('otherAddress.country')?.touched ||
                          form?.get('otherAddress.country')?.dirty)
                      "
                    >
                      Country cannot be more than 500 characters long.
                    </div>
                    <div class="form-required" *ngIf="isInvalid">
                      {{ validationErrors["otherAddress.country"] }}
                    </div>
                  </div>
                </div>
                <div class="col-6 form-col">
                  <input
                    class="form-check-input"
                    style="margin-top: 6px; margin-right: 10px;"
                    formControlName="copyAddress"
                    type="checkbox"
                    (change)="onCopyAddressChange()"
                  />
                  <label>Copy address from primary address</label>
                </div>
              </div>
              <div class="col-6 form-col" formArrayName="emailaddress">
                <label>Email</label>
                <div
                  class="form-field-email"
                  *ngFor="let email of emailaddress?.controls; let i = index"
                >
                  <div
                    class="col-11"
                    style="margin-bottom: 10px;"
                    [formGroupName]="i"
                  >
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Email"
                      formControlName="email"
                      email
                    />

                    <div
                      *ngIf="
                      email.get('email')?.errors &&
                      (email.get('email')?.dirty || email.get('email')?.touched)
                    "
                    >
                      <small *ngIf="email.get('email')?.errors?.email">
                        Please enter a valid email address.
                      </small>
                    </div>
                  </div>
                  <button
                    type="button"
                    *ngIf="i > 0"
                    class="custom-add-button-design btn btn-primary col-1"
                    (click)="removeEmail(i)"
                  >
                    <i class="bi bi-dash"></i>
                  </button>
                  <button
                    type="button"
                    *ngIf="i == 0"
                    class="custom-add-button-design btn btn-primary col-1"
                    (click)="addEmail()"
                  >
                    <i class="bi bi-plus"></i>
                  </button>
                </div>
              </div>
              <div class="col-6 form-col" formArrayName="mobile">
                <label>Mobile</label>
                <div
                  class="form-field-email"
                  *ngFor="let mobile of mobile?.controls; let i = index"
                >
                  <div
                    class="col-11"
                    style="margin-bottom: 10px;"
                    [formGroupName]="i"
                  >
                    <input
                      type="tel"
                      class="form-control"
                      placeholder="Mobile Number"
                      formControlName="mobile"
                    />
                    <div
                      *ngIf="mobile.get('mobile')?.errors && (mobile.get('mobile')?.dirty || mobile.get('mobile')?.touched)"
                    >
                      <small *ngIf="mobile.get('mobile')?.errors?.maxlength">
                        Mobile number cannot be more than 15 digits.
                      </small>
                    </div>
                  </div>
                  <button
                    type="button"
                    *ngIf="i > 0"
                    class="custom-add-button-design btn btn-primary col-1"
                    (click)="removeMobile(i)"
                  >
                    <i class="bi bi-dash"></i>
                  </button>
                  <button
                    type="button"
                    *ngIf="i == 0"
                    class="custom-add-button-design btn btn-primary col-1"
                    (click)="addMobile()"
                  >
                    <i class="bi bi-plus"></i>
                  </button>
                </div>
              </div>
              <div class="col-12 form-col">
                <label>Description </label>
                <textarea
                  pInputText
                  rows="4"
                  cols="50"
                  type="text"
                  class="form-control"
                  formControlName="description"
                  placeholder="Description"
                ></textarea>
                <div
                  *ngIf="
                    formAltaControls.description?.invalid &&
                    (formAltaControls.description?.dirty ||
                      formAltaControls.description?.touched)
                  "
                  class="text-invalid"
                  role="alert"
                >
                  <small
                    *ngIf="formAltaControls.description?.errors?.maxlength"
                  >
                    Description cannot be more than 500 characters long
                  </small>
                </div>
                <div class="form-required" *ngIf="isInvalid">
                  {{ validationErrors["description"] }}
                </div>
              </div>
            </div>
          </p-accordionTab>
          <p-accordionTab header="More Information">
            <div class="row">
              <div class="col-6 form-col">
                <div>
                  <label>Status</label>
                </div>
                <div>
                  <p-dropdown
                    styleClass="width-100-perc"
                    [options]="allStatusData"
                    formControlName="status"
                    optionLabel="key"
                    optionValue="value"
                    [showClear]="true"
                    placeholder="--- Select Status ---"
                  ></p-dropdown>
                </div>
              </div>
              <div class="col-6 form-col">
                <label>Lead Source </label>
                <p-dropdown
                  styleClass="width-100-perc"
                  [options]="allLeadSourceData"
                  formControlName="leadSource"
                  optionLabel="key"
                  optionValue="value"
                  [showClear]="true"
                  placeholder="--- Select Lead Source ---"
                ></p-dropdown>
              </div>
              <div class="col-6 form-col">
                <label>Status Desciption </label>
                <input
                  pInputText
                  type="text"
                  class="form-control"
                  placeholder="Status Description"
                  formControlName="statusDescription"
                />
                <div
                  *ngIf="
                    formAltaControls.statusDescription?.invalid &&
                    (formAltaControls.statusDescription?.dirty ||
                      formAltaControls.statusDescription?.touched)
                  "
                  class="text-invalid"
                  role="alert"
                >
                  <small
                    *ngIf="
                      formAltaControls.statusDescription?.errors?.maxlength
                    "
                  >
                    Status description cannot be more than 500 characters long
                  </small>
                </div>
                <div class="form-required" *ngIf="isInvalid">
                  {{ validationErrors["statusDescription"] }}
                </div>
              </div>
              <div class="col-6 form-col">
                <label>Lead Source Description </label>
                <input
                  pInputText
                  type="text"
                  class="form-control"
                  placeholder="Lead Source Description"
                  formControlName="leadSourceDescription"
                />
                <div
                  *ngIf="
                    formAltaControls.leadSourceDescription?.invalid &&
                    (formAltaControls.leadSourceDescription?.dirty ||
                      formAltaControls.leadSourceDescription?.touched)
                  "
                  class="text-invalid"
                  role="alert"
                >
                  <small
                    *ngIf="
                      formAltaControls.leadSourceDescription?.errors?.maxlength
                    "
                  >
                    Lead source cannot be more than 500 characters long
                  </small>
                </div>
                <div class="form-required" *ngIf="isInvalid">
                  {{ validationErrors["leadSourceDescription"] }}
                </div>
              </div>
              <div class="col-6 form-col">
                <label>Opportunity Amount </label>
                <input
                  pInputText
                  type="text"
                  class="form-control"
                  placeholder="Opportunity Amount"
                  formControlName="opportunityAmount"
                />
                <div
                  *ngIf="
                    formAltaControls.opportunityAmount?.invalid &&
                    (formAltaControls.opportunityAmount?.dirty ||
                      formAltaControls.opportunityAmount?.touched)
                  "
                  class="text-invalid"
                  role="alert"
                >
                  <small
                    *ngIf="
                      formAltaControls.opportunityAmount?.errors?.maxlength
                    "
                  >
                    Opportunity amount cannot be more than 200 characters long
                  </small>
                </div>
                <div class="form-required" *ngIf="isInvalid">
                  {{ validationErrors["opportunityAmount"] }}
                </div>
              </div>
              <div class="col-6 form-col">
                <label>Referred By </label>
                <input
                  pInputText
                  type="text"
                  class="form-control"
                  placeholder="Referred By"
                  formControlName="referredBy"
                />
                <div
                  *ngIf="
                    formAltaControls.referredBy?.invalid &&
                    (formAltaControls.referredBy?.dirty ||
                      formAltaControls.referredBy?.touched)
                  "
                  class="text-invalid"
                  role="alert"
                >
                  <small *ngIf="formAltaControls.referredBy?.errors?.maxlength">
                    Reffered by cannot be more than 200 characters long
                  </small>
                </div>
                <div class="form-required" *ngIf="isInvalid">
                  {{ validationErrors["referredBy"] }}
                </div>
              </div>
              <div class="col-6 form-col">
                <label>Campaign </label>
                <input
                  pInputText
                  type="text"
                  class="form-control"
                  placeholder="Campaign"
                  formControlName="campaign"
                />
                <div
                  *ngIf="
                    formAltaControls.campaign?.invalid &&
                    (formAltaControls.campaign?.dirty ||
                      formAltaControls.campaign?.touched)
                  "
                  class="text-invalid"
                  role="alert"
                >
                  <small *ngIf="formAltaControls.campaign?.errors?.maxlength">
                    Campaign cannot be more than 200 characters long
                  </small>
                </div>
                <div class="form-required" *ngIf="isInvalid">
                  {{ validationErrors["campaign"] }}
                </div>
              </div>
            </div>
          </p-accordionTab>
          <p-accordionTab header="Other">
            <div class="col-6 form-col custom-select">
              <label>Assigned To </label>
              <div>
                <p-dropdown
                  styleClass="width-100-perc"
                  [options]="userDetailOptions"
                  formControlName="assignedTo"
                  optionLabel="name"
                  optionValue="id"
                  [showClear]="true"
                  placeholder="--- Select User ---"
                ></p-dropdown>
              </div>
            </div>
          </p-accordionTab>
        </p-accordion>
        <!-- submit form-->
        <div class="d-flex mt-4">
          <div class="col-12 text-end">
            <button type="button" class="btn btn-cancel" (click)="onCancel()">
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-submit"
              style="margin-left: 20px !important;"
              (click)="onSubmit($event)"
            >
              {{ !this?.leadId ? "Submit" : "Update" }}
            </button>
          </div>
        </div>
      </form>
    </p-card>
  </div>
  <p-dialog
    [draggable]="false"
    [(visible)]="addAccountPopup"
    showEffect="fade"
    [modal]="true"
    header="Add Account"
    [style]="{ width: '75vw' }"
  >
    <app-account-create
      *ngIf="addAccountPopup"
      #child
      (submitAccount)="handleAccountSubmit($event)"
      [leadAddAccount]="leadAddAccount"
      (loadingOrg)="setLoadingValue($event)"
    >
    </app-account-create>
  </p-dialog>
</div>

<div class="card flex justify-content-center gap-2">
  <p-toast></p-toast>
  <p-confirmDialog></p-confirmDialog>
</div>

<div class="container-fluid h-100 padding-30px">
  <div class="col-12">
    <p-card
      header="{{
        !this?.opportunityId ? 'Create Opportunity' : 'Edit opportunity'
      }}"
    >
      <form [formGroup]="form" class="custom-form-container">
        <p-accordion [multiple]="true" [activeIndex]="[0, 1, 2]">
          <p-accordionTab header="Basic">
            <div class="row">
              <div class="col-6 form-col">
                <label
                  >Opportunity Name<span class="invaild-text"> *</span></label
                >
                <input
                  pInputText
                  type="text"
                  class="form-control"
                  placeholder="Opportunity name"
                  formControlName="opportunityName"
                />
                <div
                  *ngIf="
                    f.opportunityName?.invalid &&
                    (f.opportunityName?.dirty || f.opportunityName?.touched)
                  "
                  class="text-invalid"
                  role="alert"
                >
                  <small *ngIf="f.opportunityName?.errors?.required">
                    Opportunity Name is required.
                  </small>
                  <small *ngIf="f.opportunityName?.errors?.maxlength">
                    Opportunity Name cannot be more than 200 characters long.
                  </small>
                </div>
                <div class="form-required" *ngIf="isInvalid">
                  {{ validationErrors["opportunityName"] }}
                </div>
              </div>
              <div class="col-6 form-col mb-2">
                <label>Account Name <span class="text-invalid"> *</span></label>
                <!-- <input type="text" class="form-control" placeholder="Account Name" formControlName="accountName" /> -->
                <div>
                  <p-dropdown
                    styleClass="width-100-perc"
                    [options]="accountOptions"
                    formControlName="accountName"
                    optionLabel="name"
                    optionValue="id"
                    [showClear]="true"
                    placeholder="--- Select Account name ---"
                  ></p-dropdown>
                </div>
                <div
                  *ngIf="
                    f.accountName.invalid &&
                    (f.accountName.dirty || f.accountName.touched)
                  "
                  class="text-invalid"
                  role="alert"
                >
                  <small *ngIf="f.accountName.errors?.required">
                    Account name is required
                  </small>
                </div>
                <div class="form-required" *ngIf="isInvalid">
                  {{ validationErrors["accountName"] }}
                </div>
              </div>
              <div class="col-6 form-col">
                <label>Currency</label>
                <p-dropdown
                  styleClass="width-100-perc"
                  [options]="allCurrencyData"
                  formControlName="currency"
                  optionLabel="key"
                  optionValue="value"
                  [showClear]="true"
                  placeholder="--- Select Currency ---"
                ></p-dropdown>
              </div>
              <div class="col-6 form-col">
                <label
                  >Expected Close Date<span class="invaild-text">
                    *</span
                  ></label
                >
                <!-- calender -->
                <div class="div">
                  <p-calendar
                    styleClass="width-100-perc"
                    formControlName="expectedCloseDate"
                    inputId="buttondisplay"
                    [showIcon]="true"
                    [showClear]="true"
                    [showButtonBar]="true"
                    inputId="buttondisplay"
                    placeholder="01-01-2024"
                    dateFormat="yy-mm-dd"
                    [required]="true"
                    [minDate]="today"
                    (ngModelChange)="
                      isFutureDate($event) ? (today = $event) : null
                    "
                  ></p-calendar>
                  <div
                    *ngIf="
                      f.expectedCloseDate?.invalid &&
                      (f.expectedCloseDate?.dirty ||
                        f.expectedCloseDate?.touched)
                    "
                    class="text-invalid"
                    role="alert"
                  >
                    <small *ngIf="f.expectedCloseDate?.errors?.required">
                      Select date is required
                    </small>
                  </div>
                  <div class="form-required" *ngIf="isInvalid">
                    {{ validationErrors["expectedCloseDate"] }}
                  </div>
                </div>
              </div>
              <div class="col-6 form-col">
                <label
                  >Opportunity Amount<span class="invaild-text"> *</span></label
                >
                <input
                  pInputText
                  type="number"
                  class="form-control"
                  placeholder="Opportunity Amount"
                  formControlName="opportunityAmmount"
                  required
                />
                <div
                  *ngIf="
                    f.opportunityAmmount?.invalid &&
                    (f.opportunityAmmount?.dirty ||
                      f.opportunityAmmount?.touched)
                  "
                  class="text-invalid"
                  role="alert"
                >
                  <small *ngIf="f.opportunityAmmount?.errors?.required">
                    Opportunity Amount is required.
                  </small>
                  <!-- <small *ngIf="f.opportunityAmmount?.errors?.max">
                                        Stage cannot be more than 12 digit long.
                                    </small> -->
                </div>
                <div class="form-required" *ngIf="isInvalid">
                  {{ validationErrors["opportunityAmmount"] }}
                </div>
              </div>
              <div class="col-6 form-col">
                <label>Type</label>
                <p-dropdown
                  styleClass="width-100-perc"
                  [options]="allOpportunityTypeData"
                  formControlName="opportunityType"
                  optionLabel="key"
                  optionValue="value"
                  [showClear]="true"
                  placeholder="--- Select Opportunity Type ---"
                ></p-dropdown>

                <div class="form-required" *ngIf="isInvalid">
                  {{ validationErrors["opportunityType"] }}
                </div>
              </div>
              <div class="col-6 form-col">
                <label>Sales Stages<span class="invaild-text"> *</span></label>
                <p-dropdown
                  styleClass="width-100-perc"
                  [options]="allsalesStageData"
                  formControlName="salesStage"
                  optionLabel="key"
                  optionValue="value"
                  [showClear]="true"
                  [required]="true"
                  placeholder="--- Select Sales stage ---"
                ></p-dropdown>
                <div
                  *ngIf="
                    f.salesStage?.invalid &&
                    (f.salesStage?.dirty || f.salesStage?.touched)
                  "
                  class="text-invalid"
                  role="alert"
                >
                  <small *ngIf="f.salesStage?.errors?.required">
                    Select stage is required.
                  </small>
                  <small *ngIf="f.salesStage?.errors?.maxlength">
                    Stage cannot be more than 200 characters long.
                  </small>
                </div>
                <div class="form-required" *ngIf="isInvalid">
                  {{ validationErrors["salesStage"] }}
                </div>
              </div>
              <div class="col-6 form-col">
                <label>Lead Source<span class="invaild-text"> *</span></label>
                <p-dropdown
                  styleClass="width-100-perc"
                  [options]="allLeadSourceData"
                  formControlName="leadSource"
                  optionLabel="key"
                  optionValue="value"
                  [showClear]="true"
                  [required]="true"
                  placeholder="--- Select Lead Source ---"
                ></p-dropdown>
                <div
                  *ngIf="
                    f.leadSource?.invalid &&
                    (f.leadSource?.dirty || f.leadSource?.touched)
                  "
                  class="text-invalid"
                  role="alert"
                >
                  <small *ngIf="f.leadSource?.errors?.required">
                    Select lead source is required.
                  </small>
                  <small *ngIf="f.leadSource?.errors?.maxlength">
                    Lead source cannot be more than 200 characters long.
                  </small>
                </div>
                <div class="form-required" *ngIf="isInvalid">
                  {{ validationErrors["leadSource"] }}
                </div>
              </div>
              <div class="col-6 form-col">
                <label>Probability(%)</label>
                <input
                  pInputText
                  type="number"
                  class="form-control"
                  placeholder="Probability Percentage"
                  formControlName="probabilityPercentage"
                />
                <div
                  *ngIf="
                    f.probabilityPercentage?.invalid &&
                    (f.probabilityPercentage?.dirty ||
                      f.probabilityPercentage?.touched)
                  "
                  class="text-invalid"
                  role="alert"
                >
                  <small
                    *ngIf="
                      form
                        .get('probabilityPercentage')
                        .hasError('invalidPercentage')
                    "
                  >
                    Percentage cannot be more than 3 digit long.
                  </small>
                </div>
                <div class="form-required" *ngIf="isInvalid">
                  {{ validationErrors["probabilityPercentage"] }}
                </div>
              </div>

              <div class="col-6 form-col">
                <label>Campaign</label>
                <input
                  pInputText
                  type="text"
                  class="form-control"
                  placeholder="Campaign"
                  formControlName="campaign"
                />
              </div>
              <div class="col-6 form-col">
                <label>Next Step</label>
                <input
                  pInputText
                  type="text"
                  class="form-control"
                  placeholder="Next Step"
                  formControlName="nextStep"
                />
              </div>
              <div class="col-6 form-col"></div>
              <div class="col-12 form-col">
                <label>Description </label>
                <textarea
                  pInputText
                  formControlName="description"
                  rows="4"
                  cols="50"
                  type="text"
                  class="form-control"
                ></textarea>
                <div class="form-required" *ngIf="isInvalid">
                  {{ validationErrors["description"] }}
                </div>
              </div>
            </div>
          </p-accordionTab>
          <p-accordionTab header="Other">
            <div class="col-6 form-col custom-select">
              <label>Assigned To </label>
              <div>
                <p-dropdown
                  styleClass="width-100-perc"
                  [options]="masterData"
                  formControlName="assignedTo"
                  optionLabel="name"
                  optionValue="id"
                  [showClear]="true"
                  placeholder="--- Select User ---"
                ></p-dropdown>
              </div>
            </div>
          </p-accordionTab>
        </p-accordion>

        <div class="row d-flex mt-4">
          <div class="col-12 text-end">
            <button type="submit" class="btn btn-cancel" (click)="onCancel()">
              Cancel
            </button>
            <button type="submit" class="btn btn-submit" (click)="onSubmit()">
              {{ !opportunityId ? "Submit" : "Update" }}
            </button>
          </div>
        </div>
      </form>
    </p-card>
  </div>
</div>

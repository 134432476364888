<div class="modal-header">
  <h5 class="modal-title model-lable">{{ header }}</h5>
  <button
    type="button"
    class="btn-close"
    (click)="closeModel()"
    aria-label="Close"
  ></button>
</div>
<div class="modal-body">
  <div class="container-fluid h-100">
    <div class="col-12">
      <div class="custom-view-account">
        <div class="row">
          <div class="d-flex col-6 mb-3">
            <div class="col-3">
              <label>Subject: </label>
            </div>
            <div class="col-9">
              <span>{{ callLogData?.subject }}</span>
            </div>
          </div>
          <div class="d-flex col-6 mb-3" *ngIf="callLogData?.type == 'TASK'">
            <div class="col-3">
              <label>Contact: </label>
            </div>
            <div class="col-9">
              <span>{{ callLogData?.contactName }}</span>
            </div>
          </div>
          <div class="d-flex col-6 mb-3">
            <div class="col-3">
              <label>Status: </label>
            </div>
            <div class="col-9">
              <span>
                {{
                  callLogData.statusPrimaryStr && callLogData.statusAlternateStr
                    ? callLogData.statusPrimaryStr +
                      " " +
                      callLogData.statusAlternateStr
                    : callLogData.statusPrimaryStr
                      ? callLogData.statusPrimaryStr
                      : callLogData.statusAlternateStr
                        ? callLogData.statusAlternateStr
                        : ""
                }}
              </span>
            </div>
          </div>
          <div class="d-flex col-6 mb-3">
            <div class="col-3">
              <label>Start Date & Time: </label>
            </div>
            <div class="col-9">
              <span>{{ callLogData?.startDateTime }}</span>
            </div>
          </div>
          <div
            class="d-flex col-6 mb-3"
            *ngIf="callLogData?.type != 'CALL_LOG'"
          >
            <div class="col-3">
              <label
                >{{
                  callLogData?.type !== "TASK" ? "End Date & Time:" : "Due Date"
                }}
              </label>
            </div>
            <div class="col-9">
              <span>{{ callLogData?.endDateTime }}</span>
            </div>
          </div>
          <div class="d-flex col-6 mb-3">
            <div class="col-3">
              <label>Related To: </label>
            </div>
            <div class="col-9">
              <span>{{ callLogData?.relatedToName }}</span>
            </div>
          </div>
          <div class="d-flex col-6 mb-3" *ngIf="callLogData?.type == 'TASK'">
            <div class="col-3">
              <label>Priority: </label>
            </div>
            <div class="col-9">
              <span>{{ callLogData?.taskPriorityStr }}</span>
            </div>
          </div>
          <div class="d-flex col-6 mb-3" *ngIf="callLogData?.type !== 'TASK'">
            <div class="col-3">
              <label>Duration: </label>
            </div>
            <div class="col-9">
              <span>{{ callLogData?.duration }}</span>
            </div>
          </div>
          <div class="d-flex col-6 mb-3">
            <div class="col-3">
              <label>Assigned To: </label>
            </div>
            <div class="col-9">
              <span>{{ callLogData?.assignedName }}</span>
            </div>
          </div>
          <div class="d-flex col-6 mb-3">
            <div class="col-3">
              <label>Description: </label>
            </div>
            <div class="col-9">
              <span>{{ callLogData?.description }}</span>
            </div>
          </div>
          <div
            class="d-flex col-6 mb-3"
            *ngIf="callLogData?.type == 'SCHEDULE_TYPE'"
          >
            <div class="col-3">
              <label>Location: </label>
            </div>
            <div class="col-9">
              <span>{{ callLogData?.location }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-cancel" (click)="closeModel()">
    Close
  </button>
  <button type="button" class="btn btn-submit" (click)="edit(id)">Edit</button>
</div>

import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastService } from "./toast.service";
import { jwtDecode } from "jwt-decode";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  refreshTokenTimeOut: NodeJS.Timeout | undefined;
  // url = environment.apiURL;
  token!: string;
  errorMsg: any;
  success = false;
  isInvalid = false;
  /*API GATEWAY*/
  // url="http://192.168.29.245:7220/crmInternal/"
  // url = "https://devcrminternalapims.56-north.com/crmInternal/";
  url = environment.apiURL;
  refresh_token: any = "";
  get_refresh_token = localStorage.getItem("refresh-token");
  reloadData: BehaviorSubject<any> = new BehaviorSubject(0);
  reloadData1: BehaviorSubject<any> = new BehaviorSubject(0);
  reloadData2: BehaviorSubject<any> = new BehaviorSubject(0);

  // For activity history both calling
  public data = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient, private toast: ToastService) {
    // this.startRefreshTokenTimer();
  }

  public get(route: string) {
    return this.http.get(`${this.url}crmInternal/${route}`);
  }

  public post(route: string, data: any) {
    return this.http.post(`${this.url}crmInternal/${route}`, data);
  }

  callDownloadFile(route: string): Observable<any> {
    return this.http.get(`${this.url}crmInternal/${route}`, {
      responseType: "blob",
    });
  }

  isAuthorized() {
    return Boolean(this.token);
  }

  public patch(route: string, data: any) {
    return this.http.patch(`${this.url}crmInternal/${route}`, data);
  }

  public delete(route: string) {
    return this.http.delete(`${this.url}crmInternal/${route}`);
  }

  public put(route: string, data: any) {
    return this.http.put(`${this.url}crmInternal/${route}`, data);
  }
  /* refresh token */

  public refreshToken() {
    return this.http.get(
      `${this.url}crmInternal/` + "refresh/" + this.get_refresh_token
    );
  }

  decodeToken(token: string) {
    return jwtDecode(token);
  }

  get authToken(): string {
    return localStorage.getItem("access-token") || "";
  }

  getExpireTime(expiresIn: number): Date {
    return new Date(expiresIn * 1000);
  }

  startRefreshTokenTimer(): void {
    this.stopRefreshTokenTimer();
    if (this.authToken) {
      const jwtToken: any = this.decodeToken(this.authToken);
      const jwtExpiresIn = this.getExpireTime(jwtToken?.exp);
      const timeout = jwtExpiresIn.getTime() - Date.now() - 120 * 1000;
      this.refreshTokenTimeOut = setTimeout(
        () =>
          this.refreshToken().subscribe({
            next: (response: any) => {
              const token = response?.data?.jwt;
              localStorage.setItem("access-token", token || "");
              this.startRefreshTokenTimer();
            },
            error: (error: HttpErrorResponse) => {
              this.toast.setErrorPopupMessage(error?.error?.message);
            },
          }),
        timeout
      );
    }
  }

  stopRefreshTokenTimer(): void {
    if (this.refreshTokenTimeOut) {
      clearTimeout(this.refreshTokenTimeOut);
    }
  }

  logout() {
    this.stopRefreshTokenTimer();
    return this.http.get(`${this.url}logout`);
  }

  checkForgotPasswordToken(token: string): Observable<any> {
    return this.http.get<any>(`${this.url}v1/user/valForgetPwdToken/${token}`);
  }

  restPassword(
    forgotPwdToken: string,
    newPwd: string,
    confirmNewPwd: string
  ): Observable<any> {
    return this.http.post(`${this.url}v1/user/resetPwd`, {
      forgotPwdToken,
      newPwd,
      confirmNewPwd,
    });
  }

  changePwdByOldPwd(
    oldPwd: string,
    newPwd: string,
    confirmNewPwd: string
  ): Observable<any> {
    return this.http.post(`${this.url}v1/user/changePwdByOldPwd`, {
      oldPwd,
      newPwd,
      confirmNewPwd,
    });
  }
}

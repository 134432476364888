import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { MenuItem } from "primeng/api";
import { API_POINTS } from "src/app/helpers/model/endpoint.model";
import { ApiService } from "src/app/helpers/services/api.service";
import { ToastService } from "src/app/helpers/services/toast.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  userData: any = {};
  endPoint = API_POINTS;
  items: MenuItem[] = [];
  currentRoute: string = "";
  private isLoggingOut = false;

  @ViewChild("menubar", { static: false }) menubar: any;

  constructor(
    private _router: Router,
    private toster: ToastService,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.loadUserData();
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.urlAfterRedirects;
      }
    });
    this.setInitialRoute();
  }

  loadUserData() {
    this.userData = JSON.parse(localStorage.getItem("userData") || "{}");
    this.updateMenuItems();
  }

  updateMenuItems() {
    this.items = [
      {
        label: "Home",
        routerLink: "dashboard",
      },
      {
        label: "Lead",
        routerLink: "lead",
      },
      {
        label: "Opportunity",
        routerLink: "opportunity",
      },
      {
        label: "Contact",
        routerLink: "contact",
      },
      {
        label: "Account",
        routerLink: "account",
      },
      {
        label: "Linkedin Scrapping",
        routerLink: "linkdin",
      },
      {
        label: `${this.userData.firstName} ${this.userData.lastName} `,
        items: [
          {
            label: "Change Password",
            routerLink: "change-password",
          },
          {
            label: "Log out",
            command: () => this.onLogout(),
          },
        ],
        icon: "pi pi-chevron-down",
      },
    ];
  }

  onLogout() {
    if (this.isLoggingOut) return;

    this.isLoggingOut = true;

    this.api.logout().subscribe(
      (res: any) => {
        localStorage.removeItem("access-token");
        localStorage.clear();
        this._router.navigate(["/"]);
        this.toster.setSuccessPopupMessage("Log-out Successfully");
      },
      (error: any) => {
        this.toster.setErrorPopupMessage(error?.error?.message);
      },
      () => {
        this.isLoggingOut = false;
      }
    );
  }

  onDropdownItemClick(item: string) {
    this._router.navigate([item]);
  }

  isActive(route: string): boolean {
    return this.currentRoute.includes(route);
  }

  private setInitialRoute() {
    if (!this.currentRoute) {
      this.currentRoute = "lead";
    }
  }
}

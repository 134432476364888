import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HistoryFormComponent } from "../history-form/history-form.component";
import { ToastService } from "src/app/helpers/services/toast.service";
import { ApiService } from "src/app/helpers/services/api.service";
import { API_POINTS } from "src/app/helpers/model/endpoint.model";

@Component({
  selector: "app-history-note-view",
  templateUrl: "./history-note-view.component.html",
  styleUrls: ["./history-note-view.component.css"],
})
export class HistoryNoteViewComponent implements OnInit {
  @Input() historyData: any;
  @Input() id: any;
  @Input() header: any;
  @Input() leadId: any;
  masterData: any;
  endpoint = API_POINTS;
  @Output() loadPaperLazyEvent = new EventEmitter<void>();

  editData: any;
  historyForm = false;
  constructor(
    private activeModal: NgbActiveModal,
    private modal: NgbModal,
    private toast: ToastService,
    public api: ApiService,
  ) {}

  ngOnInit() {
    this.getMasterData(this.leadId);
  }
  getMasterData(leadID: any) {
    this.api.get(this.endpoint.getHistoryMasterData + leadID).subscribe(
      (result: any) => {
        if (result.status == "SUCCESS") {
          this.masterData = result?.data;
        } else {
          this.toast.setErrorPopupMessage(result.message);
        }
      },
      (error) => {
        this.toast.setErrorPopupMessage(error?.error?.message);
      },
    );
  }
  edit(user: any) {
    this.api
      .get(this.endpoint.viewHistoryBYyID + user.id)
      .subscribe((response: any) => {
        const modalRef = this.modal.open(HistoryFormComponent, {
          size: "lg",
          centered: true,
        });
        modalRef.componentInstance.editListData = response?.data;
        modalRef.componentInstance.leadId = this.leadId;
        modalRef.componentInstance.userMasterData = this.masterData;
        modalRef.result.then(
          (result) => {},
          (reason: any) => {
            this.loadPaperLazyEvent.emit();
            this.closeModel(reason);
          },
        );
      });
  }
  closeModel(reason?: any) {
    this.activeModal.dismiss(reason);
  }
}

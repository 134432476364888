import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./authentication/login/login.component";
import { PagenotfoundComponent } from "./authentication/pagenotfound/pagenotfound.component";
import { AccountCreateComponent } from "./components/account/account-create/account-create.component";
import { AccountListComponent } from "./components/account/account-list/account-list.component";
import { ContactsCreateComponent } from "./components/contacts/contacts-create/contacts-create.component";
import { ContactsListComponent } from "./components/contacts/contacts-list/contacts-list.component";
import { LeadsCreateComponent } from "./components/leads/leads-create/leads-create.component";
import { LeadsListComponent } from "./components/leads/leads-list/leads-list.component";
import { AuthGuard } from "./helpers/guard/auth.guard";
import { OpportunityCreateComponent } from "./components/opportunity/opportunity-create/opportunity-create.component";
import { OpportunityListComponent } from "./components/opportunity/opportunity-list/opportunity-list.component";
import { MyCallsListComponent } from "././components/dashboard_list/my-calls-list/my-calls-list.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { ViewSummeryHistoryComponent } from "./components/history/view-summery-history/view-summery-history.component";
import { ActivityFormsComponent } from "./components/activity/activity-forms/activity-forms.component";
import { LinkdinScrappingListComponent } from "./components/linkdin-Scrapping/linkdin-scrapping-list/linkdin-scrapping-list.component";
import { LinkdinScrappingFormComponent } from "./components/linkdin-Scrapping/linkdin-scrapping-form/linkdin-scrapping-form.component";
import { ForgotPasswordComponent } from "./authentication/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./authentication/reset-password/reset-password.component";
import { InvalidTokenComponent } from "./authentication/invalid-token/invalid-token.component";
import { TokenExpiredComponent } from "./authentication/token-expired/token-expired.component";
import { ChangePasswordComponent } from "./authentication/change-password/change-password.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full",
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
  },
  {
    path: "reset-password/:token",
    component: ResetPasswordComponent,
  },
  {
    path: "invalid-token",
    component: InvalidTokenComponent,
  },
  {
    path: "token-expired",
    component: TokenExpiredComponent,
  },
  {
    path: "change-password",
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "contact",
    component: ContactsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "contact/add",
    component: ContactsCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "contact/:id/edit",
    component: ContactsCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "lead",
    component: LeadsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "lead/add",
    component: LeadsCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "lead/:id/edit",
    component: LeadsCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "account",
    component: AccountListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "linkdin/:id",
    component: LinkdinScrappingListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "linkdin",
    component: LinkdinScrappingListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "linkdin-Scrapping/add",
    component: LinkdinScrappingFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "404",
    component: PagenotfoundComponent,
  },
  {
    path: "account/add",
    component: AccountCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "account/:id/edit",
    component: AccountCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "opportunity",
    component: OpportunityListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "opportunity/add",
    component: OpportunityCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "opportunity/:id/edit",
    component: OpportunityCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "my-calls",
    component: MyCallsListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "view-summery-history",
    component: ViewSummeryHistoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "log-call/add",
    component: ActivityFormsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "log-call/:id/edit",
    component: ActivityFormsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "history-note/add",
    component: ActivityFormsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "history-note/:id/edit",
    component: ActivityFormsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "other/:id",
    component: ContactsCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "**",
    redirectTo: "404",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-oppertunity-view",
  templateUrl: "./oppertunity-view.component.html",
  styleUrls: ["./oppertunity-view.component.css"],
})
export class OppertunityViewComponent {
  @Input() oppertunityData: any;
  @Input() id: any;
  constructor(
    private router: Router,
    private activeModal: NgbActiveModal,
  ) {}
  closeModel() {
    this.activeModal.dismiss();
  }
  edit(user: any) {
    this.closeModel();
    this.router.navigateByUrl(`opportunity/${user.opportunityId}/edit`);
  }
}

<div class="container-fluid p-0">
  <div class="d-flex h-100">
    <div class="col-5 container-parent">
      <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
        <div class="text-center">
          <h2>Change Password</h2>
        </div>
        <div class="form-col">
          <div class="password-container">
            <label>Old Password<span class="text-invalid"> *</span></label>
            <input
              formControlName="oldPassword"
              [type]="showOldPassword ? 'text' : 'password'"
              class="form-control"
              placeholder="Old Password"
            />
            <div class="view-password">
              <i
                alt="show"
                [class]="showOldPassword ? 'bi bi-eye' : 'bi bi-eye-slash'"
                (click)="toggleShowOldPassword()"
              ></i>
            </div>
          </div>
          <div
            *ngIf="f.oldPassword.invalid && (f.oldPassword.dirty || f.oldPassword.touched)"
            class="text-invalid"
          >
            <small *ngIf="f.oldPassword.errors?.required">
              Old Password is required.
            </small>
          </div>
          <div class="password-container mt-2">
            <label>New Password<span class="text-invalid"> *</span></label>
            <input
              formControlName="newPassword"
              [type]="showNewPassword ? 'text' : 'password'"
              class="form-control"
              placeholder="New Password"
            />
            <div class="view-password">
              <i
                alt="show"
                [class]="showNewPassword ? 'bi bi-eye' : 'bi bi-eye-slash'"
                (click)="toggleShowNewPassword()"
              ></i>
            </div>
          </div>
          <div
            *ngIf="f.newPassword.invalid && (f.newPassword.dirty || f.newPassword.touched)"
            class="text-invalid"
          >
            <small *ngIf="f.newPassword.errors?.required">
              New Password is required.
            </small>
            <small *ngIf="f.newPassword.errors?.minlength">
              At least 6 characters long.
            </small>
          </div>
          <div class="password-container mt-2">
            <label
              >Confirm New Password<span class="text-invalid"> *</span></label
            >
            <input
              formControlName="confirmNewPassword"
              [type]="showConfirmNewPassword ? 'text' : 'password'"
              class="form-control"
              placeholder="Confirm New Password"
            />
            <div class="view-password">
              <i
                alt="show"
                [class]="showConfirmNewPassword ? 'bi bi-eye' : 'bi bi-eye-slash'"
                (click)="toggleShowConfirmNewPassword()"
              ></i>
            </div>
          </div>
          <div
            *ngIf="f.confirmNewPassword.invalid && (f.confirmNewPassword.dirty || f.confirmNewPassword.touched)"
            class="text-invalid"
          >
            <small *ngIf="f.confirmNewPassword.errors?.required">
              Confirm New Password is required.
            </small>
            <small *ngIf="f.confirmNewPassword.errors?.minlength">
              At least 6 characters long.
            </small>
          </div>
          <div *ngIf="passwordsMismatch" class="text-invalid">
            <small>Passwords do not match.</small>
          </div>
        </div>
        <div class="row d-flex mt-4">
          <div class="col-12 text-end">
            <button type="submit" class="btn btn-lg-login text-white btn-lg">
              Change Password
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

import { ChangeDetectorRef, Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationService } from "primeng/api";
import { API_POINTS } from "src/app/helpers/model/endpoint.model";
import { ApiService } from "src/app/helpers/services/api.service";
import { ToastService } from "src/app/helpers/services/toast.service";
import { OppertunityViewComponent } from "../oppertunity-view/oppertunity-view.component";

@Component({
  selector: "app-opportunity-list",
  templateUrl: "./opportunity-list.component.html",
  styleUrls: ["./opportunity-list.component.css"],
})
export class OpportunityListComponent {
  users: any = [];
  loading = false;
  event: any;
  globalFilter: any;
  resdata: any;
  totalRecords: any = 0;
  datatableRowsVal = 10;
  rowPerPage: any = [10, 25, 50];
  cols: any;
  emptyMessage: string | undefined;
  modelTitle: string | undefined;
  modelMsg: string | undefined;
  endpoint: any = API_POINTS;
  data: any;
  userData: any = {};
  userId: any;
  id: any;
  deleteId: any;
  type: any;
  constructor(
    public api: ApiService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private toast: ToastService,
    private cdref: ChangeDetectorRef,
    private modalService: NgbModal,
  ) {}

  ngAfterViewChecked() {
    this.cdref.detectChanges();
  }

  ngOnInit() {
    setTimeout(() => {
      this.emptyMessage = "No Records Found";
      this.cols = [
        {
          colName: "Sr.No",
          isSort: true,
          colWidth: "8%",
          sortField: "srNo",
        },
        {
          isSort: true,
          colName: "Name",
          colWidth: "2%",
          sortField: "opportunityName",
        },
        {
          isSort: true,
          colName: "Account Name",
          colWidth: "13%",
          sortField: "accountName",
        },
        {
          isSort: false,
          colName: "Expected Close Date",
          colWidth: "15%",
        },
        {
          isSort: false,
          colName: "User",
          colWidth: "10%",
        },
        {
          isSort: true,
          colName: "Opportunity Amount",
          colWidth: "16%",
          sortField: "opportunityAmmount",
        },
        {
          isSort: true,
          colName: "Sales stage",
          colWidth: "12%",
          sortField: "salesStage",
        },
        {
          isSort: true,
          colName: "Lead Source",
          colWidth: "12%",
          sortField: "leadSource",
        },
        {
          colName: "Actions",
          isSort: false,
          colWidth: "20%",
        },
      ];
    });
  }

  getDefaultDtSort(event: any) {
    if (event) {
      if (!event?.sortField || event?.sortField == "") {
        event.sortField = "srNo";
        event.sortOrder = "-1";
      }
    }
    return event;
  }

  onDeleteOpportunity(id: any) {
    this.confirmationService.confirm({
      message: this.modelMsg,
      acceptLabel: "Yes",
      rejectLabel: "No",
      icon: "fa fa-question-circle",
      accept: () => {
        this.loading = true;
        this.api.delete(this.endpoint.deleteOpportunityByID + id).subscribe(
          (result: any) => {
            this.resdata = result;
            if (this.resdata.status == "SUCCESS") {
              this.toast.setSuccessPopupMessage(result.message);
              this.loadPaperLazy();
            } else {
              this.toast.setErrorPopupMessage(this.resdata.message);
            }
            this.loading = false;
          },
          (error: any) => {
            this.loading = false;
            this.toast.setErrorPopupMessage(error?.error?.message);
          },
        );
      },
    });
  }

  activeDeactiveOpportunity(opportunity: any) {
    this.confirmationService.confirm({
      message: this.modelMsg,
      acceptLabel: "Yes",
      rejectLabel: "No",
      icon: "fa fa-question-circle",
      accept: () => {
        let status = opportunity.active ? "DEACTIVATE" : "ACTIVATE";
        const changeUserStatusEndPoint: any =
          this.endpoint.opportunityStatusChangeByID +
          opportunity.opportunityId +
          "/status/" +
          status;
        this.loading = true;
        this.api.post(changeUserStatusEndPoint, "").subscribe(
          (result: any) => {
            this.data = result;
            if (this.data.status == "SUCCESS") {
              this.toast.setSuccessPopupMessage(result.message);
              this.loadPaperLazy();
            } else {
              this.toast.setErrorPopupMessage(result.message);
            }
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            this.toast.setErrorPopupMessage(error?.error?.message);
          },
        );
      },
    });
  }

  loadPaperLazy(event: any = null) {
    this.loading = true;
    if (event !== null) {
      this.event = event;
    }
    this.event.customFilters = {};
    this.event = this.getDefaultDtSort(this.event);
    const body = {
      event: this.event,
    };
    this.globalFilter =
      event && event.globalFilter
        ? event.globalFilter
        : this.event.globalFilter;
    this.api.post(this.endpoint.getOpportunity, body).subscribe(
      (result: any) => {
        this.users = [];
        this.resdata = result;
        if (this.resdata.status == "SUCCESS") {
          this.users = this.resdata.data.records;
          this.totalRecords = this.resdata.data.recordsTotal;
          this.loading = false;
        } else {
          this.loading = false;
        }
      },
      (error: any) => {
        this.users = [];
        this.loading = false;
        this.toast.setErrorPopupMessage(error?.error?.message);
      },
    );
  }

  onCreateOpportunity() {
    this.router.navigate(["opportunity/add"]);
  }

  edit(user: any) {
    this.router.navigateByUrl(`opportunity/${user.opportunityId}/edit`);
  }
  open(user: any) {
    this.api
      .get(this.endpoint.viewOpportunity + user.opportunityId)
      .subscribe((response: any) => {
        this.userData = response.data;
        this.id = user;
        const modalRef = this.modalService.open(OppertunityViewComponent, {
          size: "lg",
          centered: true,
        });
        modalRef.componentInstance.oppertunityData = response.data;
        modalRef.componentInstance.id = user;
      });
  }

  showOppertunityModalDialog(type: any, id: any, content: any) {
    if (type === "delete") {
      this.type = type;
      this.modelTitle = "Delete Oppertunity";
      (this.modelMsg = "Are you sure you want to delete this oppertunity"),
        {
          status: "Delete".toLowerCase(),
        };
      this.loading = false;
    } else {
      this.type = type;
      this.modelTitle =
        type === "deactivate"
          ? "Activate Oppertunity"
          : "Deactivate Oppertunity";
      this.modelMsg =
        type === "deactivate"
          ? "Are you sure you want to activate this oppertunity?"
          : "Are you sure you want to deactivate this oppertunity?";
      this.loading = false;
    }
    setTimeout(() => {
      this.modalService.open(content, {
        centered: true,
        size: "s",
        backdrop: "static",
        keyboard: false,
      });
    }, 500);
    this.deleteId = id;
  }
  deleteOppertunityById() {
    this.loading = true;
    this.api
      .delete(
        this.endpoint.deleteOpportunityByID + this.deleteId?.opportunityId,
      )
      .subscribe(
        (result: any) => {
          this.resdata = result;
          if (this.resdata.status == "SUCCESS") {
            this.toast.setSuccessPopupMessage(result.message);
            this.loadPaperLazy();
          } else {
            this.toast.setErrorPopupMessage(this.resdata.message);
          }
          this.loading = false;
        },
        (error: any) => {
          this.loading = false;
          this.toast.setErrorPopupMessage(error?.error?.message);
        },
      );
  }
  activeDeactiveOppertunity() {
    this.loading = true;
    let status = this.deleteId?.active ? "DEACTIVATE" : "ACTIVATE";
    const changeUserStatusEndPoint: any =
      this.endpoint.opportunityStatusChangeByID +
      this.deleteId.opportunityId +
      "/status/" +
      status;
    this.api.post(changeUserStatusEndPoint, "").subscribe(
      (result: any) => {
        this.resdata = result;
        if (this.resdata.status == "SUCCESS") {
          this.toast.setSuccessPopupMessage(result.message);
          this.loadPaperLazy();
        } else {
          this.toast.setErrorPopupMessage(this.resdata.message);
        }
        this.loading = false;
      },
      (error: any) => {
        this.loading = false;
        this.toast.setErrorPopupMessage(error?.error?.message);
      },
    );
  }
  onActions() {
    if (this.type == "delete") {
      this.deleteOppertunityById();
    } else {
      this.activeDeactiveOppertunity();
    }
  }
}

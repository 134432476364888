import { HttpErrorResponse } from "@angular/common/http";
import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { API_POINTS } from "src/app/helpers/model/endpoint.model";
import { ApiService } from "src/app/helpers/services/api.service";
import { ToastService } from "src/app/helpers/services/toast.service";

@Component({
  selector: "app-linkdin-scrapping-form",
  templateUrl: "./linkdin-scrapping-form.component.html",
  styleUrls: ["./linkdin-scrapping-form.component.css"],
})
export class LinkdinScrappingFormComponent {
  form!: FormGroup;
  endpoint: any = API_POINTS;
  loading = false;
  showPassword: boolean = false;
  id: any;
  constructor(
    public _fb: FormBuilder,
    private toast: ToastService,
    public api: ApiService,
    private router: Router
  ) {}
  ngOnInit() {
    this.form = this._fb.group({
      email: ["",[Validators.required, Validators.email]],
      password: ["",[Validators.required]],
      search: ["",[Validators.required]],
      fetchRecords: ["",[Validators.required]],
    });
  }
  get formControl(): any {
    return this.form["controls"];
  }
  onSubmit() {
    Object.values(this.form?.controls).forEach((control: any) =>
      control?.markAsTouched(),
    );
    let obj = {
      email: this.form.value.email,
      password: this.form.value.password,
      keywords: this.form.value.search,
      totalProfilesToFetch: this.form.value.fetchRecords,
    };

    if (this.form.valid) {
      this.api.post(this.endpoint.initLinkdinScrapping, obj).subscribe(
        (res: any) => {
          if (res.status === "SUCCESS") {
            this.toast.setSuccessPopupMessage(res?.message);
            this.id = res?.data;
            // this.router.navigate(["linkdin"]);
            this.router.navigateByUrl(`linkdin/${this.id}`);

          }
        },
        (error: HttpErrorResponse) => {
          this.toast.setErrorPopupMessage(error?.error?.message);
        }
      );
    }
  }
  onCancel() {
    return window.history.back();
  }
}

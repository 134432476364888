<p-menubar [model]="items" styleClass="sticky-menubar" [autoDisplay]="false">
  <ng-template pTemplate="item" let-item>
    <li
      [routerLink]="item.routerLink"
      [ngClass]="{'active': isActive(item.routerLink)}"
      (click)="item.command && item.command()"
    >
      {{ item.label }}
      <i *ngIf="item.icon" [class]="item.icon"></i>
    </li>
    <p-menuitem *ngIf="item.items" [model]="item.items"></p-menuitem>
  </ng-template>
</p-menubar>

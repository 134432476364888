import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { API_POINTS } from "src/app/helpers/model/endpoint.model";
import { ApiService } from "src/app/helpers/services/api.service";
import { ToastService } from "src/app/helpers/services/toast.service";
@Component({
  selector: "app-linkdin-scrapping-list",
  templateUrl: "./linkdin-scrapping-list.component.html",
  styleUrls: ["./linkdin-scrapping-list.component.css"],
})
export class LinkdinScrappingListComponent implements OnInit {
  users: any = [];
  loading = false;
  resdata: any;
  cols: any;
  emptyMessage: string | undefined;
  endpoint: any = API_POINTS;
  data: any;
  userData: any = {};
  id: any;
  type: any;
  linkdinid: any;
  interval:any
  constructor(
    public api: ApiService,
    private router: Router,
    private toast: ToastService,
    private cdref: ChangeDetectorRef,
    private activate: ActivatedRoute,
    private http: HttpClient,
  ) {}

  ngAfterViewChecked() {
    this.cdref.detectChanges();
  }

  ngOnInit() {
    this.activate.params.subscribe((param: any) => {
      this.linkdinid = param["id"];
    });
    setTimeout(() => {
      this.emptyMessage = "No Records Found";
      this.cols = [
        // {
        //   colName: "Sr.No",
        //   isSort: true,
        //   colWidth: "9%",
        //   sortField: "srNo",
        // },
        {
          isSort: true,
          colName: "Name",
          colWidth: "12%",
          sortField: "name",
        },
        {
          isSort: true,
          colName: "Position",
          colWidth: "14%",
          sortField: "position",
        },
        {
          isSort: false,
          colName: "Experience",
          colWidth: "15%",
        },
        {
          isSort: true,
          colName: "Email",
          colWidth: "10%",
          sortField: "email",
        },
        {
          isSort: true,
          colName: "Link",
          colWidth: "12%",
          sortField: "link",
        },
        {
          isSort: false,
          colName: "Phone",
          colWidth: "12%",
        },
        {
          isSort: false,
          colName: "Location",
          colWidth: "12%",
        },
        {
          isSort: false,
          colName: "Website",
          colWidth: "12%",
        },
        {
          colName: "Actions",
          isSort: false,
          colWidth: "20%",
        },
      ];
    });
    this.interval=setInterval(() => {
      this.loadPaperLazy();
    }, 10000);
  }

  onLinkdinScrappingForm() {
    this.router.navigate(["linkdin-Scrapping/add"]);
  }

  getDefaultDtSort(event: any) {
    if (event) {
      if (!event?.sortField || event?.sortField == "") {
        event.sortField = "srNo";
        event.sortOrder = "-1";
      }
    }
    return event;
  }

  loadPaperLazy(event: any = null) {
    if (this.linkdinid) {
      this.api
        .get(this.endpoint.getLinkdinScrappingList + this.linkdinid)
        .subscribe(
          (result: any) => {
            this.users = [];
            this.resdata = result;

            if (this.resdata.data.status == "COMPLETED") {
              this.users.push(JSON.parse(this.resdata.data.result));              
     
            } 
          },
          (error: any) => {
            if(error?.status===401){
              clearInterval(this.interval)
            }
            this.users = [];
            this.toast.setErrorPopupMessage(error?.error?.message);
          }
        );
    }
  }
}

<div class="container">
  <img src="../../../assets/images/image.jpg" alt="" />
  <div class="card" style="width: 18rem;">
    <div class="card-body text-center">
      <h3 class="card-title">Invalid Token</h3>
      <p class="card-text">
        Forgot password link is invalid. Please use forgot password to generate
        a new link.
      </p>
      <button routerLink="/forgot-password" class="btn btn-danger">
        Forgot Password
      </button>
    </div>
  </div>
</div>

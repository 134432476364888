import { Component, ChangeDetectorRef } from "@angular/core";
import { EventEmitter, Output } from "@angular/core";
import { API_POINTS } from "src/app/helpers/model/endpoint.model";
import { ApiService } from "src/app/helpers/services/api.service";
import { Router } from "@angular/router";
import { ToastService } from "src/app/helpers/services/toast.service";
import { DatePipe } from "@angular/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ViewSingleDataComponent } from "src/app/components/leads/view-single-data/view-single-data.component";

@Component({
  selector: "app-leads-list",
  templateUrl: "./leads-list.component.html",
  styleUrls: ["./leads-list.component.css"],
  providers: [DatePipe],
})
export class LeadsListComponent {
  users: any = [];
  loading = false;
  event: any;
  globalFilter: any;
  resdata: any;
  totalRecords: any = 0;
  datatableRowsVal = 10;
  rowPerPage: any = [10, 25, 50];
  cols: any;
  emptyMessage: string | undefined;
  modelTitle: string | undefined;
  modelMsg: string | undefined;
  endpoint: any = API_POINTS;
  data: any;
  userData: any = {};
  userId: any;
  id: any;
  addImportLeadPopup = false;
  visible: boolean = false;
  index: number = 0;
  deleteId: any;
  type: any;
  @Output() messageEvent = new EventEmitter<boolean>();

  constructor(
    public api: ApiService,
    private router: Router,
    private toast: ToastService,
    private cdref: ChangeDetectorRef,
    private modalService: NgbModal
  ) {}

  ngAfterViewChecked() {
    this.cdref.detectChanges();
  }
  showDialog() {
    this.visible = true;
  }
  ngOnInit() {
    setTimeout(() => {
      this.emptyMessage = "No Records Found";
      this.cols = [
        {
          colName: "Sr.No",
          isSort: true,
          colWidth: "9%",
          sortField: "srNo",
        },
        {
          isSort: false,
          colName: "Name",
          colWidth: "14%",
        },
        {
          isSort: true,
          colName: "Title",
          colWidth: "14%",
          sortField: "title",
        },
        {
          isSort: true,
          colName: "Status",
          colWidth: "12%",
          sortField: "status",
        },
        {
          isSort: false,
          colName: "Account Name",
          colWidth: "15%",
        },
        {
          isSort: true,
          colName: "Email",
          colWidth: "10%",
          sortField: "email",
        },
        {
          isSort: true,
          colName: "Office Phone",
          colWidth: "13%",
          sortField: "mobile",
        },
        {
          colName: "Actions",
          isSort: false,
          colWidth: "20%",
        },
      ];
    });
  }

  getDefaultDtSort(event: any) {
    if (event) {
      if (!event?.sortField || event?.sortField == "") {
        event.sortField = "srNo";
        event.sortOrder = "-1";
      }
    }
    return event;
  }

  loadPaperLazy(event: any = null) {
    this.loading = true;
    if (event !== null) {
      this.event = event;
    }
    this.event.customFilters = {};
    this.event = this.getDefaultDtSort(this.event);
    const body = {
      event: this.event,
    };
    this.globalFilter =
      event && event.globalFilter
        ? event.globalFilter
        : this.event.globalFilter;
    this.api.post(this.endpoint.getLead, body).subscribe(
      (result: any) => {
        this.users = [];
        this.resdata = result;
        if (this.resdata.status == "SUCCESS") {
          this.users = this.resdata.data.records;
          this.totalRecords = this.resdata.data.recordsTotal;
          this.loading = false;
        } else {
          this.loading = false;
        }
      },
      (error: any) => {
        this.users = [];
        this.loading = false;
        this.toast.setErrorPopupMessage(error?.error?.message);
      }
    );
  }
  setLoadingValue(event: any) {
    if (event && event == "SUCCESS") {
      this.addImportLeadPopup = false;
      this.loadPaperLazy();
    }
    this.cdref.detectChanges();
  }
  onCreateLead() {
    this.router.navigate(["lead/add"]);
  }

  edit(user: any) {
    this.router.navigateByUrl(`lead/${user.leadId}/edit`);
  }

  open(user: any) {
    this.api
      .get(this.endpoint.viewLead + user.leadId)
      .subscribe((response: any) => {
        this.userData = response.data;
        this.id = user;
        const modalRef = this.modalService.open(ViewSingleDataComponent, {
          size: "xl",
          centered: true,
        });
        modalRef.componentInstance.userData = response.data;
        modalRef.componentInstance.leadUsers = this.users;
      });
  }
  onImportLead() {
    this.addImportLeadPopup = true;
  }
  showLeadModalDialog(type: any, id: any, content: any) {
    if (type === "delete") {
      this.type = type;
      this.modelTitle = "Delete Lead";
      (this.modelMsg = "Are you sure you want to delete this lead"),
        {
          status: "Delete".toLowerCase(),
        };
      this.loading = false;
    } else {
      this.type = type;
      this.modelTitle =
        type === "deactivate" ? "Activate Lead" : "Deactivate Lead";
      this.modelMsg =
        type === "deactivate"
          ? "Are you sure you want to activate this lead?"
          : "Are you sure you want to deactivate this lead?";
      this.loading = false;
    }
    setTimeout(() => {
      this.modalService.open(content, {
        centered: true,
        size: "s",
        backdrop: "static",
        keyboard: false,
      });
    }, 500);
    this.deleteId = id;
  }
  deleteLeadById() {
    this.loading = true;
    this.api
      .delete(this.endpoint.deleteLeadByID + this.deleteId?.leadId)
      .subscribe(
        (result: any) => {
          this.resdata = result;
          if (this.resdata.status == "SUCCESS") {
            this.toast.setSuccessPopupMessage(result.message);
            this.loadPaperLazy();
          } else {
            this.toast.setErrorPopupMessage(this.resdata.message);
          }
          this.loading = false;
        },
        (error: any) => {
          this.loading = false;
          this.toast.setErrorPopupMessage(error?.error?.message);
        }
      );
  }
  activeDeactiveLead() {
    this.loading = true;
    let status = this.deleteId?.active ? "DEACTIVATE" : "ACTIVATE";
    const changeUserStatusEndPoint: any =
      this.endpoint.leadStatusChangeByID +
      this.deleteId.leadId +
      "/status/" +
      status;
    this.api.post(changeUserStatusEndPoint, "").subscribe(
      (result: any) => {
        this.resdata = result;
        if (this.resdata.status == "SUCCESS") {
          this.toast.setSuccessPopupMessage(result.message);
          this.loadPaperLazy();
        } else {
          this.toast.setErrorPopupMessage(this.resdata.message);
        }
        this.loading = false;
      },
      (error: any) => {
        this.loading = false;
        this.toast.setErrorPopupMessage(error?.error?.message);
      }
    );
  }
  onActions() {
    if (this.type == "delete") {
      this.deleteLeadById();
    } else {
      this.activeDeactiveLead();
    }
  }
}

<div class="container-fluid p-0">
  <div class="d-flex row h-100">
    <div class="col-7 p-0">
      <img
        src="../../../assets/images/image.jpg"
        alt="forgot password"
        class="float-start"
        width="100%"
        height="100%"
      />
    </div>
    <div class="col-5 container-parent">
      <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
        <div class="text-center">
          <h2>Forgot Password</h2>
        </div>
        <p class="email-text">
          Enter your email and we'll send you a link to reset your password.
        </p>
        <div class="form-col mb-2">
          <label>Email <span class="text-invalid"> *</span></label>
          <input
            type="email"
            class="form-control"
            placeholder="Email"
            formControlName="email"
            [(ngModel)]="email"
          />
          <div
            *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)"
            class="text-invalid"
            role="alert"
          >
            <small *ngIf="f.email.errors?.required"> Email is required. </small>
            <small *ngIf="f.email.errors?.email">
              Please enter valid email address.
            </small>
          </div>
        </div>
        <div class="row d-flex mt-4">
          <div class="col-12 text-end">
            <button type="submit" class="btn btn-lg-login text-white btn-lg">
              Submit
            </button>
          </div>
        </div>
        <div class="row d-flex mt-4">
          <div class="col-12 text-center">
            <a class="login-btn" routerLink="/login">
              <span><i class="bi bi-caret-left-fill"></i></span> Back to Login
            </a>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

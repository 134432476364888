<div class="container-fluid h-100 padding-30px">
    <div class="row margin-right-0px">
      <div class="progress-spinner" *ngIf="loading">
        <p-progressSpinner></p-progressSpinner>
      </div>
      <div class="col-lg-12">
        <p-card>
          <!-- table -->
          <div class="table-responsive">
            <p-table
              #paperDt
              [value]="users"
              [lazy]="true"
              (onLazyLoad)="loadPaperLazy($event)"
              styleClass="p-datatable-customers p-datatable-gridlines"
              dataKey="id"
            >
              <ng-template pTemplate="caption">
                <div class="list-card-design">
                  <h3 class="p-card-title">Linkdin Scrapping List</h3>
                  <div style="display: flex">
                    <div>
                      <button
                        class="btn btn-primary custom-button-design btn-default"
                        style="margin-left: 10px"
                        (click)="onLinkdinScrappingForm()"
                      >
                        Linkdlin Scrapping Form
                      </button>
                    </div>
                  </div>
                </div>
              </ng-template>
  
              <!-- table header -->
              <ng-template pTemplate="header">
                <tr>
                  <th
                    class="text-align-center"
                    *ngFor="let tableHeading of cols"
                    [style.width]="tableHeading.colWidth"
                  >
                    {{ tableHeading.colName }}
                    <!-- <p-sortIcon
                      *ngIf="tableHeading.isSort"
                      [field]="tableHeading?.sortField"
                    ></p-sortIcon> -->
                  </th>
                </tr>
              </ng-template>
              <!-- table body -->
              <ng-template pTemplate="body" let-user>
                <tr>
                  <!-- <td>{{ user.srNo }}</td> -->
                  <td>{{ user.name || "-" }}</td>
                  <td>{{ user.position || "-" }}</td>
                  <td class="table-data-height-fix" title={{user.experience}}>
                    {{ user.experience || "-" }}</td>
                  <td>{{ user.email || "-" }}</td>
                  <td>{{ user.link || "-" }}</td>
                  <td>{{ user.phone || "-" }}</td>
                  <td>{{ user.location || "-" }}</td>
                  <td class="table-data-height-fix" title={{user.website}}>{{ user.website || "-" }}</td>
                  <td class="actionBar">
                    <div class="actionBarContainer">
                      <a title="Add Lead List">
                        <!-- <i class="bi bi-eye-fill"></i> -->
                        <p style="font-size: 12px; color: #ae0040; font-weight: 700;">Add Lead List</p>
                      </a>
                    </div>
                  </td>
                </tr>
              </ng-template>
              <!-- Empty Message -->
              <ng-template pTemplate="emptymessage" let-user>
                <tr>
                  <td class="text-align-center" [attr.colspan]="cols?.length">
                    {{ emptyMessage }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-card>
      </div>
    </div>
  </div>
  
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { API_POINTS } from "src/app/helpers/model/endpoint.model";
import { ApiService } from "src/app/helpers/services/api.service";
import { ToastService } from "src/app/helpers/services/toast.service";

@Component({
  selector: "app-view-summery-history",
  templateUrl: "./view-summery-history.component.html",
  styleUrls: ["./view-summery-history.component.css"],
})
export class ViewSummeryHistoryComponent implements OnInit {
  activeTabIndex: number = 0;
  @Input() leadData: any;
  @Input() id: any;
  @Input() header: any;
  @Input() userMasterData: any;
  @Input() leadId: any;
  @Output() callAPI: EventEmitter<any> = new EventEmitter();
  loading = false;
  event: any;
  overviewUsers: any;
  globalFilter: any;
  totalRecords: any = 0;
  datatableRowsVal = 10;
  rowPerPage: any = [10, 25, 50];
  endpoint = API_POINTS;
  emptyMessage: any;
  cols: any;
  taskData: any;
  meetingData: any;
  callData: any;
  notesData: any;
  constructor(
    public api: ApiService,
    private toast: ToastService,
    private activeModal: NgbActiveModal,
  ) {}
  ngOnInit() {
    setTimeout(() => {
      this.emptyMessage = "No Records Found";
      this.cols = [
        {
          colName: "Sr.No",
          isSort: false,
          colWidth: "2%",
        },
        {
          isSort: true,
          colName: "Subject",
          colWidth: "15%",
          sortField: "subject",
        },
        {
          isSort: false,
          colName: "Status",
          colWidth: "10%",
        },
        {
          isSort: false,
          colName: "Contact",
          colWidth: "10%",
        },
        {
          isSort: false,
          colName: "Date Created",
          colWidth: "12%",
        },
      ];
    });
  }
  getDefaultDtSort(event: any) {
    if (event) {
      if (!event?.sortField || event?.sortField == "") {
        event.sortField = "";
        event.sortOrder = "-1";
      }
    }
    return event;
  }

  loadPaperLazy(event: any = null) {
    this.loading = true;
    if (event !== null) {
      this.event = event;
    }
    (this.event.customFilters = {
      leadId: this?.leadId,
      primaryStatus: [
        "NOT_STARTED",
        "COMPLETED",
        "PENDING_INPUT",
        "DEFERRED",
        "INBOUND",
        "OUTBOUND",
      ],
      alternateStatus: ["NOT_HELD", "HELD"],
    }),
      (this.event = this.getDefaultDtSort(this.event));
    const body = {
      event: this.event,
    };
    this.globalFilter =
      event && event.globalFilter
        ? event.globalFilter
        : this.event.globalFilter;
    this.api.post(this.endpoint.getHistory, body).subscribe(
      (result: any) => {
        this.overviewUsers = result?.records;
        this.totalRecords = result?.recordsTotal;
        this.loading = false;
      },
      (error: any) => {
        this.overviewUsers = [];
        this.loading = false;
        this.toast.setErrorPopupMessage(error?.error?.message);
      },
    );
  }
  taskLoadPaperLazy(event: any = null) {
    this.loading = true;
    if (event !== null) {
      this.event = event;
    }
    this.event.customFilters = { leadId: this?.leadId, type: ["TASK"] };
    this.event = this.getDefaultDtSort(this.event);
    const body = {
      event: this.event,
    };
    this.globalFilter =
      event && event.globalFilter
        ? event.globalFilter
        : this.event.globalFilter;
    this.api.post(this.endpoint.getlogcall, body).subscribe(
      (result: any) => {
        this.taskData = [];
        if (result.status == "SUCCESS") {
          this.taskData = result.data.records;
          this.totalRecords = result.data.recordsTotal;
          this.loading = false;
        } else {
          this.loading = false;
        }
      },
      (error: any) => {
        this.taskData = [];
        this.loading = false;
        this.toast.setErrorPopupMessage(error?.error?.message);
      },
    );
  }
  meetLoadPaperLazy(event: any = null) {
    this.loading = true;
    if (event !== null) {
      this.event = event;
    }
    this.event.customFilters = {
      leadId: this?.leadId,
      type: ["SCHEDULE_TYPE"],
    };
    this.event = this.getDefaultDtSort(this.event);
    const body = {
      event: this.event,
    };
    this.globalFilter =
      event && event.globalFilter
        ? event.globalFilter
        : this.event.globalFilter;
    this.api.post(this.endpoint.getlogcall, body).subscribe(
      (result: any) => {
        this.meetingData = [];
        if (result.status == "SUCCESS") {
          this.meetingData = result.data.records;
          this.totalRecords = result.data.recordsTotal;
          this.loading = false;
        } else {
          this.loading = false;
        }
      },
      (error: any) => {
        this.meetingData = [];
        this.loading = false;
        this.toast.setErrorPopupMessage(error?.error?.message);
      },
    );
  }
  callLoadPaperLazy(event: any = null) {
    this.loading = true;
    if (event !== null) {
      this.event = event;
    }
    this.event.customFilters = { leadId: this?.leadId, type: ["CALL_LOG"] };
    this.event = this.getDefaultDtSort(this.event);
    const body = {
      event: this.event,
    };
    this.globalFilter =
      event && event.globalFilter
        ? event.globalFilter
        : this.event.globalFilter;
    this.api.post(this.endpoint.getlogcall, body).subscribe(
      (result: any) => {
        this.callData = [];
        if (result.status == "SUCCESS") {
          this.callData = result.data.records;
          this.totalRecords = result.data.recordsTotal;
          this.loading = false;
        } else {
          this.loading = false;
        }
      },
      (error: any) => {
        this.callData = [];
        this.loading = false;
        this.toast.setErrorPopupMessage(error?.error?.message);
      },
    );
  }
  notesLoadPaperLazy(event: any = null) {
    this.loading = true;
    if (event !== null) {
      this.event = event;
    }
    this.event.customFilters = { leadId: this?.leadId };
    this.event = this.getDefaultDtSort(this.event);
    const body = {
      event: this.event,
    };
    this.globalFilter =
      event && event.globalFilter
        ? event.globalFilter
        : this.event.globalFilter;
    this.api.post(this.endpoint.getHistoryList, body).subscribe(
      (result: any) => {
        this.notesData = [];
        if (result.status == "SUCCESS") {
          this.notesData = result.data.records;
          this.totalRecords = result.data.recordsTotal;
          this.loading = false;
        } else {
          this.loading = false;
        }
      },
      (error: any) => {
        this.notesData = [];
        this.loading = false;
        this.toast.setErrorPopupMessage(error?.error?.message);
      },
    );
  }

  closeModel() {
    this.activeModal.dismiss();
  }
}

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { API_POINTS } from "src/app/helpers/model/endpoint.model";
import { ApiService } from "src/app/helpers/services/api.service";
import { ToastService } from "src/app/helpers/services/toast.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivityViewComponent } from "src/app/components/activity/activity-view/activity-view.component";
import { ActivityFormsComponent } from "src/app/components/activity/activity-forms/activity-forms.component";
@Component({
  selector: "app-my-calls-list",
  templateUrl: "./my-calls-list.component.html",
  styleUrls: ["./my-calls-list.component.css"],
})
export class MyCallsListComponent implements OnInit {
  @Output() callAPI: EventEmitter<any> = new EventEmitter();
  @Input() myCallsFlag = false;
  users: any = [];
  loading = false;
  event: any;
  globalFilter: any;
  resdata: any;
  totalRecords: any = 0;
  datatableRowsVal = 10;
  rowPerPage: any = [10, 25, 50];
  cols: any;
  emptyMessage: string | undefined;
  modelTitle: string | undefined;
  modelMsg: string | undefined;
  endpoint: any = API_POINTS;
  data: any;
  userData: any = {};
  id: any;
  masterData: any;
  deleteId: any;
  type: any;
  constructor(
    public api: ApiService,
    private toast: ToastService,
    private modalService: NgbModal,
    private cdref: ChangeDetectorRef,
  ) {
    this.api.reloadData.subscribe((data) => {
      if (data) {
        this.loadPaperLogCallLazy();
      }
    });
  }

  ngAfterViewChecked() {
    this.cdref.detectChanges();
  }

  ngOnInit() {
    setTimeout(() => {
      this.emptyMessage = "No Records Found";
      this.cols = [
        {
          colName: "Sr.No",
          isSort: true,
          colWidth: "7%",
          sortField: "srNo",
        },
        {
          isSort: true,
          colName: "Subject",
          colWidth: "10%",
          sortField: "subject",
        },
        {
          isSort: false,
          colName: "Related to",
          colWidth: "14%",
        },
        {
          isSort: false,
          colName: "Start Date",
          colWidth: "15%",
        },
        {
          isSort: false,
          colName: "Status",
          colWidth: "10%",
        },
        {
          colName: "Accept",
          colWidth: "10%",
        },
        {
          colName: "Actions",
          isSort: false,
          colWidth: "10%",
        },
      ];
    });
  }

  getDefaultDtSort(event: any) {
    if (event) {
      if (!event?.sortField || event?.sortField == "") {
        event.sortField = "srNo";
        event.sortOrder = "-1";
      }
    }
    return event;
  }

  viewLogCallById(user: any) {
    this.api
      .get(this.endpoint.viewlogCall + user.callAndMeetingId)
      .subscribe((response: any) => {
        this.userData = response.data;
        this.id = user;
        const modalRef = this.modalService.open(ActivityViewComponent, {
          size: "lg",
          centered: true,
        });
        modalRef.componentInstance.callLogData = response.data;
        modalRef.componentInstance.header = "View My Calls";
        modalRef.componentInstance.id = user;
        modalRef.componentInstance.leadId = user.leadId;
        modalRef.result.then(
          (result: any) => {},
          (reason: any) => {
            if (reason == "success") {
              this.loadPaperLogCallLazy();
            }
          },
        );
      });
  }

  loadPaperLogCallLazy(event: any = null) {
    this.loading = true;
    if (event !== null) {
      this.event = event;
    }
    this.event.customFilters = {
      leadId: null,
      type: ["CALL_LOG"],
      alternateStatus: ["PLANNED"],
    };
    this.event = this.getDefaultDtSort(this.event);
    const body = {
      event: this.event,
    };
    this.globalFilter =
      event && event.globalFilter
        ? event.globalFilter
        : this.event.globalFilter;
    this.api.post(this.endpoint.getlogcall, body).subscribe(
      (result: any) => {
        this.users = [];
        this.resdata = result;
        if (this.resdata.status == "SUCCESS") {
          this.users = this.resdata.data.records;
          this.totalRecords = this.resdata.data.recordsTotal;
          this.loading = false;
        } else {
          this.loading = false;
        }
      },
      (error: any) => {
        this.users = [];
        this.loading = false;
        this.toast.setErrorPopupMessage(error?.error?.message);
      },
    );
  }
  showLogCallModalDialog(type: any, id: any, content: any) {
    if (type === "delete") {
      this.type = type;
      this.modelTitle = "Delete Log Call";
      (this.modelMsg = "Are you sure you want to delete this log call"),
        {
          status: "Delete".toLowerCase(),
        };
      this.loading = false;
    } else {
      this.type = type;
      this.modelTitle =
        type === "deactivate" ? "Activate Log Call" : "Deactivate Log Call";
      this.modelMsg =
        type === "deactivate"
          ? "Are you sure you want to activate this log call?"
          : "Are you sure you want to deactivate this log call?";
      this.loading = false;
    }
    setTimeout(() => {
      this.modalService.open(content, {
        centered: true,
        size: "s",
        backdrop: "static",
        keyboard: false,
      });
    }, 500);
    this.deleteId = id;
  }
  deleteCallById() {
    this.loading = true;
    this.api
      .delete(this.endpoint.deletelogCallByID + this.deleteId?.callAndMeetingId)
      .subscribe(
        (result: any) => {
          this.resdata = result;
          if (this.resdata.status == "SUCCESS") {
            this.toast.setSuccessPopupMessage(result.message);
            this.loadPaperLogCallLazy();
          } else {
            this.toast.setErrorPopupMessage(this.resdata.message);
          }
          this.loading = false;
        },
        (error: any) => {
          this.loading = false;
          this.toast.setErrorPopupMessage(error?.error?.message);
        },
      );
  }
  activeDeactiveLogCall() {
    this.loading = true;
    let status = this.deleteId?.active ? "DEACTIVATE" : "ACTIVATE";
    const changeUserStatusEndPoint: any =
      this.endpoint.logcallStatusChangeByID +
      this.deleteId?.callAndMeetingId +
      "/status/" +
      status;
    this.api.post(changeUserStatusEndPoint, "").subscribe(
      (result: any) => {
        this.resdata = result;
        if (this.resdata.status == "SUCCESS") {
          this.toast.setSuccessPopupMessage(result.message);
          this.loadPaperLogCallLazy();
        } else {
          this.toast.setErrorPopupMessage(this.resdata.message);
        }
        this.loading = false;
      },
      (error: any) => {
        this.loading = false;
        this.toast.setErrorPopupMessage(error?.error?.message);
      },
    );
  }
  onActions() {
    if (this.type == "delete") {
      this.deleteCallById();
    } else {
      this.activeDeactiveLogCall();
    }
  }
  editLogCallById(user: any) {
    this.api.get(this.endpoint.getlogcallMasterData + user?.leadId).subscribe(
      (result: any) => {
        if (result.status == "SUCCESS") {
          this.masterData = result?.data?.assignedToList;
        } else {
          this.toast.setErrorPopupMessage(result.message);
        }
        const modalRef = this.modalService.open(ActivityFormsComponent, {
          size: "lg",
          centered: true,
        });
        modalRef.componentInstance.editListData = user;
        modalRef.componentInstance.userMasterData = this.masterData;
        modalRef.result.then(
          (result: any) => {},
          (reason: any) => {
            if (reason == "success") {
              this.loadPaperLogCallLazy();
            }
          },
        );
      },
      (error) => {
        this.toast.setErrorPopupMessage(error?.error?.message);
      },
    );
  }
}

import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-account-view",
  templateUrl: "./account-view.component.html",
  styleUrls: ["./account-view.component.css"],
})
export class AccountViewComponent {
  @Input() userData: any;
  @Input() id: any;

  constructor(
    private router: Router,
    private activeModal: NgbActiveModal,
  ) {}

  closeModel() {
    this.activeModal.dismiss();
  }

  edit(user: any) {
    this.closeModel();
    this.router.navigateByUrl(`account/${user.accountId}/edit`);
  }
}

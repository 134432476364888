<div class="container-fluid h-100 padding-30px">
  <div class="row margin-right-0px">
    <div class="progress-spinner" *ngIf="loading">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="col-12">
      <p-card header="{{ !accountId ? 'Create Account' : 'Edit Account' }}">
        <form [formGroup]="form" class="custom-form-container">
          <p-accordion [multiple]="true" [activeIndex]="[0, 1, 2]">
            <!-- OVERVIEW -->
            <p-accordionTab header="Overview">
              <div class="row">
                <div class="col-6 form-col">
                  <label>Name<span> *</span></label>
                  <input
                    pInputText
                    type="text"
                    class="form-control"
                    placeholder="Name"
                    formControlName="name"
                  />
                  <div
                    *ngIf="
                      f.name?.invalid && (f.name?.dirty || f.name?.touched)
                    "
                  >
                    <small *ngIf="f.name?.errors?.maxlength">
                      Name cannot be more than 200 characters long.
                    </small>
                    <small *ngIf="f.name?.errors?.required">
                      Name is required.
                    </small>
                  </div>
                  <div *ngIf="isInvalid">
                    <small> {{ validationErrors["name"] }}</small>
                  </div>
                </div>
                <div class="col-6 form-col">
                  <label>Phone</label>
                  <input
                    pInputText
                    type="test"
                    class="form-control"
                    placeholder="Phone"
                    formControlName="officePhone"
                    name="officePhone"
                  />
                  <div
                    *ngIf="
                      f.officePhone?.invalid &&
                      (f.officePhone?.dirty || f.officePhone?.touched)
                    "
                  >
                    <small *ngIf="f.officePhone?.errors?.maxlength">
                      Office Phone cannot be more than 200 characters long.
                    </small>
                  </div>
                </div>
                <div class="col-6 form-col">
                  <label>Website</label>
                  <input
                    pInputText
                    type="text"
                    class="form-control"
                    placeholder="Website"
                    formControlName="website"
                  />
                  <div
                    *ngIf="
                      f.website?.invalid &&
                      (f.website?.dirty || f.website?.touched)
                    "
                  >
                    <small *ngIf="f.website?.errors?.maxlength">
                      Website cannot be more than 500 characters long.
                    </small>
                  </div>
                </div>
                <div class="col-6 form-col">
                  <label>Fax</label>
                  <input
                    pInputText
                    type="text"
                    class="form-control"
                    placeholder="Fax"
                    formControlName="fax"
                  />
                  <div
                    *ngIf="f.fax?.invalid && (f.fax?.dirty || f.fax?.touched)"
                  >
                    <small *ngIf="f.fax?.errors?.maxlength">
                      Fax cannot be more than 200 characters long.
                    </small>
                  </div>
                </div>
                <div class="col-6 form-col" formArrayName="emailaddress">
                  <label>Email</label>
                  <div
                    class="form-field-email"
                    *ngFor="let email of emailaddress?.controls; let i = index"
                  >
                    <div
                      class="col-11"
                      style="margin-bottom: 10px;"
                      [formGroupName]="i"
                    >
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Email"
                        formControlName="email"
                      />
                      <div
                        *ngIf="
                          emailaddress.controls[i].get('email')?.errors &&
                          (emailaddress.controls[i].get('email')?.dirty ||
                            emailaddress.controls[i].get('email')?.touched)
                        "
                      >
                        <small
                          *ngIf="
                            emailaddress.controls[i].get('email')?.errors
                              ?.maxlength
                          "
                        >
                          Email address cannot be more than 200 characters long.
                        </small>
                        <small
                          *ngIf="
                            emailaddress.controls[i].get('email')?.errors
                              ?.pattern
                          "
                        >
                          Please enter valid email address
                        </small>
                      </div>
                    </div>
                    <button
                      type="button"
                      *ngIf="i > 0"
                      class="custom-add-button-design btn btn-primary col-1"
                      (click)="removeEmail(i)"
                    >
                      <i class="bi bi-dash"></i>
                    </button>
                    <button
                      type="button"
                      *ngIf="i == 0"
                      class="custom-add-button-design btn btn-primary col-1"
                      (click)="addEmail()"
                    >
                      <i class="bi bi-plus"></i>
                    </button>
                  </div>
                </div>
                <div class="col-6 form-col"></div>
                <div class="col-6 form-col mt-4">
                  <div [formGroup]="billingAddress">
                    <h4 class="design-color">Billing Address</h4>
                    <div class="col-12 form-col">
                      <label>Street </label>
                      <input
                        pInputText
                        type="text"
                        class="form-control"
                        placeholder="Street"
                        formControlName="street"
                      />
                      <div
                        *ngIf="
                          form
                            .get('billingAddress.street')
                            ?.hasError('maxlength') &&
                          (form.get('billingAddress.street')?.touched ||
                            form?.get('billingAddress.street')?.dirty)
                        "
                      >
                        <small>
                          Street cannot be more than 200 characters long.</small
                        >
                      </div>
                    </div>
                    <!-- <div class="col-12 form-col">
                      <label>Address </label>
                      <input pInputText type="text" class="form-control" placeholder="Address"
                        formControlName="address" />
                      <div *ngIf="form.get('billingAddress.address')?.hasError('maxlength') 
                      && (form.get('billingAddress.address')?.touched ||
                      form?.get('billingAddress.address')?.dirty)">
                        <small> Address cannot be more than 500 characters long.</small>
                      </div>
                    </div> -->
                    <div class="col-12 form-col">
                      <label>State </label>
                      <input
                        pInputText
                        type="text"
                        class="form-control"
                        placeholder="State"
                        formControlName="state"
                      />
                      <div
                        *ngIf="
                          form
                            .get('billingAddress.state')
                            ?.hasError('maxlength') &&
                          (form.get('billingAddress.state')?.touched ||
                            form?.get('billingAddress.state')?.dirty)
                        "
                      >
                        <small>
                          State cannot be more than 200 characters long.</small
                        >
                      </div>
                    </div>
                    <div class="col-12 form-col">
                      <label>City </label>
                      <input
                        pInputText
                        type="text"
                        class="form-control"
                        placeholder="City"
                        formControlName="city"
                      />
                      <div
                        *ngIf="
                          form
                            .get('billingAddress.city')
                            ?.hasError('maxlength') &&
                          (form.get('billingAddress.city')?.touched ||
                            form?.get('billingAddress.city')?.dirty)
                        "
                      >
                        <small>
                          City cannot be more than 200 characters long.</small
                        >
                      </div>
                    </div>
                    <div class="col-12 form-col">
                      <label>Postal code </label>
                      <input
                        pInputText
                        type="text"
                        class="form-control"
                        placeholder="Postal Code"
                        formControlName="postalCode"
                      />
                      <div
                        *ngIf="
                          form
                            .get('billingAddress.postalCode')
                            ?.hasError('maxlength') &&
                          (form.get('billingAddress.postalCode')?.touched ||
                            form?.get('billingAddress.postalCode')?.dirty)
                        "
                      >
                        <small>
                          Postal code cannot be more than 200 characters
                          long.</small
                        >
                      </div>
                    </div>
                    <div class="col-12 form-col">
                      <label>Country </label>
                      <input
                        pInputText
                        type="text"
                        class="form-control"
                        placeholder="Country"
                        formControlName="country"
                      />
                      <div
                        *ngIf="
                          form
                            .get('billingAddress.country')
                            ?.hasError('maxlength') &&
                          (form.get('billingAddress.country')?.touched ||
                            form?.get('billingAddress.country')?.dirty)
                        "
                      >
                        <small>
                          Country cannot be more than 200 characters
                          long.</small
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 form-col mt-4">
                  <div [formGroup]="shippingAddress">
                    <h4 class="design-color">Shipping Address</h4>
                    <div class="col-12 form-col">
                      <label>Street </label>
                      <input
                        pInputText
                        type="text"
                        class="form-control"
                        placeholder="Street"
                        formControlName="street"
                      />
                      <div
                        *ngIf="
                          form
                            .get('shippingAddress.street')
                            ?.hasError('maxlength') &&
                          (form.get('shippingAddress.street')?.touched ||
                            form?.get('shippingAddress.street')?.dirty)
                        "
                      >
                        <small>
                          Street cannot be more than 200 characters long.</small
                        >
                      </div>
                    </div>

                    <div class="col-12 form-col">
                      <label>State </label>
                      <input
                        pInputText
                        type="text"
                        class="form-control"
                        placeholder="State"
                        formControlName="state"
                      />
                      <div
                        *ngIf="
                          form
                            .get('shippingAddress.state')
                            ?.hasError('maxlength') &&
                          (form.get('shippingAddress.state')?.touched ||
                            form?.get('shippingAddress.state')?.dirty)
                        "
                      >
                        <small>
                          State cannot be more than 200 characters long.</small
                        >
                      </div>
                    </div>
                    <div class="col-12 form-col">
                      <label>City </label>
                      <input
                        pInputText
                        type="text"
                        class="form-control"
                        placeholder="City"
                        formControlName="city"
                      />
                      <div
                        *ngIf="
                          form
                            .get('shippingAddress.city')
                            ?.hasError('maxlength') &&
                          (form.get('shippingAddress.city')?.touched ||
                            form?.get('shippingAddress.city')?.dirty)
                        "
                      >
                        <small>
                          City cannot be more than 200 characters long.</small
                        >
                      </div>
                    </div>
                    <div class="col-12 form-col">
                      <label>Postal code </label>
                      <input
                        pInputText
                        type="text"
                        class="form-control"
                        placeholder="Postal Code"
                        formControlName="postalCode"
                      />
                      <div
                        *ngIf="
                          form
                            .get('shippingAddress.postalCode')
                            ?.hasError('maxlength') &&
                          (form.get('shippingAddress.postalCode')?.touched ||
                            form?.get('shippingAddress.postalCode')?.dirty)
                        "
                      >
                        <small>
                          Postal code cannot be more than 200 characters
                          long.</small
                        >
                      </div>
                    </div>
                    <div class="col-12 form-col">
                      <label>Country </label>
                      <input
                        pInputText
                        type="text"
                        class="form-control"
                        placeholder="Country"
                        formControlName="country"
                      />
                      <div
                        *ngIf="
                          form
                            .get('shippingAddress.country')
                            ?.hasError('maxlength') &&
                          (form.get('shippingAddress.country')?.touched ||
                            form?.get('shippingAddress.country')?.dirty)
                        "
                      >
                        <small>
                          Country cannot be more than 200 characters
                          long.</small
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-6 form-col mb-2">
                    <input
                      class="form-check-input"
                      style="margin-top: 6px; margin-right: 10px;"
                      formControlName="copyAddress"
                      type="checkbox"
                      (change)="onCopyAddressChange()"
                    />
                    <label>Copy address from billing address</label>
                  </div>
                </div>
                <div class="col-12 form-col">
                  <label>Description </label>
                  <textarea
                    pInputText
                    rows="4"
                    cols="50"
                    type="text"
                    class="form-control"
                    placeholder="Description"
                    formControlName="description"
                  ></textarea>
                  <div
                    *ngIf="
                      f.description?.invalid &&
                      (f.description?.dirty || f.description?.touched)
                    "
                  >
                    <small *ngIf="f.description?.errors?.maxlength">
                      Description cannot be more than 500 characters long.
                    </small>
                  </div>
                </div>
                <div class="col-6 form-col custom-select">
                  <label>Assigned To </label>
                  <p-dropdown
                    placeholder="--- Select User ---"
                    styleClass="width-100-perc"
                    formControlName="assignedTo"
                    [options]="masterData"
                    optionLabel="name"
                    optionValue="id"
                    [showClear]="true"
                  ></p-dropdown>
                </div>
              </div>
            </p-accordionTab>
            <!-- MORE INFORMATION -->
            <p-accordionTab header="More Information">
              <div class="row">
                <div class="col-6 form-col">
                  <label>Type </label>
                  <p-dropdown
                    styleClass="width-100-perc"
                    [options]="allTypeData"
                    formControlName="type"
                    optionLabel="key"
                    optionValue="value"
                    [showClear]="true"
                    placeholder="--- Select Type ---"
                  ></p-dropdown>
                </div>
                <div class="col-6 form-col">
                  <label>Industry </label>
                  <p-dropdown
                    styleClass="width-100-perc"
                    [options]="allIndustryData"
                    formControlName="industry"
                    optionLabel="key"
                    optionValue="value"
                    [showClear]="true"
                    placeholder="--- Select Industry ---"
                  ></p-dropdown>
                </div>
                <div class="col-6 form-col">
                  <label>Annual Revenue </label>
                  <input
                    pInputText
                    type="text"
                    class="form-control"
                    placeholder="Annual Revenue"
                    formControlName="totalRevanu"
                  />
                  <div
                    *ngIf="
                      f.totalRevanu?.invalid &&
                      (f.totalRevanu?.dirty || f.totalRevanu?.touched)
                    "
                  >
                    <small *ngIf="f.totalRevanu?.errors?.maxlength">
                      Annual Revanu cannot be more than 500 characters long.
                    </small>
                  </div>
                </div>
                <div class="col-6 form-col">
                  <label>Employee </label>
                  <input
                    pInputText
                    type="text"
                    class="form-control"
                    placeholder="Employee"
                    formControlName="employee"
                  />
                  <div
                    *ngIf="
                      f.employee?.invalid &&
                      (f.employee?.dirty || f.employee?.touched)
                    "
                  >
                    <small *ngIf="f.employee?.errors?.maxlength">
                      Employee cannot be more than 200 characters long.
                    </small>
                  </div>
                </div>
                <div class="col-6 form-col">
                  <label>Member Of </label>
                  <input
                    pInputText
                    type="text"
                    class="form-control"
                    placeholder="Member Of"
                    formControlName="memberOf"
                  />
                  <div
                    *ngIf="
                      f.memberOf?.invalid &&
                      (f.memberOf?.dirty || f.memberOf?.touched)
                    "
                  >
                    <small *ngIf="f.memberOf?.errors?.maxlength">
                      Member Of cannot be more than 500 characters long.
                    </small>
                  </div>
                </div>
                <div class="col-6 form-col">
                  <label>Campaign </label>
                  <input
                    pInputText
                    type="text"
                    class="form-control"
                    placeholder="Campaign"
                    formControlName="campaign"
                  />
                  <div
                    *ngIf="
                      f.campaign?.invalid &&
                      (f.campaign?.dirty || f.campaign?.touched)
                    "
                  >
                    <small *ngIf="f.campaign?.errors?.maxlength">
                      Campaign cannot be more than 500 characters long.
                    </small>
                  </div>
                </div>
              </div>
            </p-accordionTab>
          </p-accordion>
          <!-- submit -->
          <div class="row d-flex mt-4">
            <div class="col-12 text-end">
              <button type="button" class="btn btn-cancel" (click)="onCancel()">
                Cancel
              </button>
              <button
                type="submit"
                class="btn btn-submit"
                *ngIf="!this?.accountId || leadAddAccount"
                (click)="onSubmit()"
              >
                Submit
              </button>
              <button
                type="submit"
                class="btn btn-submit"
                *ngIf="this?.accountId && !leadAddAccount"
                (click)="onSubmit()"
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </p-card>
    </div>
  </div>
</div>

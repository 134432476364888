import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LeadsListComponent } from "../components/leads/leads-list/leads-list.component";
import { LeadsCreateComponent } from "../components/leads/leads-create/leads-create.component";
import { LeadsImportComponent } from "../components/leads/leads-import/leads-import.component";
import { ContactsListComponent } from "../components/contacts/contacts-list/contacts-list.component";
import { ContactsCreateComponent } from "../components/contacts/contacts-create/contacts-create.component";
import { AccountCreateComponent } from "../components/account/account-create/account-create.component";
import { AccountListComponent } from "../components/account/account-list/account-list.component";
import { OpportunityCreateComponent } from "../components/opportunity/opportunity-create/opportunity-create.component";
import { OpportunityListComponent } from "../components/opportunity/opportunity-list/opportunity-list.component";
import { MyCallsListComponent } from "../components/dashboard_list/my-calls-list/my-calls-list.component";
import { MyMeetingsListComponent } from "../components/dashboard_list/my-meetings-list/my-meetings-list.component";
import { MyTopOppertunitiesListComponent } from "../components/dashboard_list/my-top-oppertunities-list/my-top-oppertunities-list.component";

import { ProgressSpinnerModule } from "primeng/progressspinner";
import { TableModule } from "primeng/table";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DropdownModule } from "primeng/dropdown";
import { PagenotfoundComponent } from "../authentication/pagenotfound/pagenotfound.component";
import { LoginComponent } from "../authentication/login/login.component";
import { TabMenuModule } from "primeng/tabmenu";
import { AccordionModule } from "primeng/accordion";
import { CardModule } from "primeng/card";
import { DialogModule } from "primeng/dialog";
import { CalendarModule } from "primeng/calendar";
import { ToastModule } from "primeng/toast";
import { FileUploadModule } from "primeng/fileupload";
import { DividerModule } from "primeng/divider";
import { MenubarModule } from "primeng/menubar";
import { MenuModule } from "primeng/menu";

import { PanelModule } from "primeng/panel";
import { InputTextModule } from "primeng/inputtext";
import { TabViewModule } from "primeng/tabview";
import { TagModule } from "primeng/tag";
import { ViewSingleDataComponent } from "../components/leads/view-single-data/view-single-data.component";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { SimplebarAngularModule } from "simplebar-angular";
import { HistoryFormComponent } from "../components/history/history-form/history-form.component";
import { DashboardComponent } from "../components/dashboard/dashboard.component";
import { OppertunityViewComponent } from "../components/opportunity/oppertunity-view/oppertunity-view.component";
import { ActivityViewComponent } from "../components/activity/activity-view/activity-view.component";
import { ViewSummeryHistoryComponent } from "../components/history/view-summery-history/view-summery-history.component";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { HistoryNoteViewComponent } from "../components/history/history-note-view/history-note-view.component";
import { ContactViewComponent } from "../components/contacts/contact-view/contact-view.component";
import { AccountViewComponent } from "../components/account/account-view/account-view.component";
import { ActivityComponent } from "../components/activity/activity_list/activity.component";
import { HistoryComponent } from "../components/history/history_list/history.component";
import { ActivityFormsComponent } from "../components/activity/activity-forms/activity-forms.component";
import { LinkdinScrappingListComponent } from "../components/linkdin-Scrapping/linkdin-scrapping-list/linkdin-scrapping-list.component";
import { LinkdinScrappingFormComponent } from "../components/linkdin-Scrapping/linkdin-scrapping-form/linkdin-scrapping-form.component";
import { ForgotPasswordComponent } from "../authentication/forgot-password/forgot-password.component";
import { InvalidTokenComponent } from "../authentication/invalid-token/invalid-token.component";
import { ResetPasswordComponent } from "../authentication/reset-password/reset-password.component";
import { TokenExpiredComponent } from "../authentication/token-expired/token-expired.component";
import { ChangePasswordComponent } from "../authentication/change-password/change-password.component";

@NgModule({
  declarations: [
    LeadsListComponent,
    LeadsCreateComponent,
    LeadsImportComponent,
    ContactsListComponent,
    ContactsCreateComponent,
    AccountCreateComponent,
    AccountListComponent,
    PagenotfoundComponent,
    LoginComponent,
    OpportunityListComponent,
    OpportunityCreateComponent,
    MyCallsListComponent,
    MyMeetingsListComponent,
    MyTopOppertunitiesListComponent,
    ViewSingleDataComponent,
    ActivityComponent,
    HistoryComponent,
    ActivityFormsComponent,
    HistoryFormComponent,
    DashboardComponent,
    OppertunityViewComponent,
    ActivityViewComponent,
    ViewSummeryHistoryComponent,
    HistoryNoteViewComponent,
    ContactViewComponent,
    AccountViewComponent,
    LinkdinScrappingListComponent,
    LinkdinScrappingFormComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    InvalidTokenComponent,
    TokenExpiredComponent,
    ChangePasswordComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    NgbModalModule,
    AccordionModule,
    TableModule,
    ConfirmDialogModule,
    CardModule,
    DialogModule,
    DropdownModule,
    TabMenuModule,
    DialogModule,
    CalendarModule,
    ToastModule,
    FileUploadModule,
    DividerModule,
    MenubarModule,
    MenuModule,
    PanelModule,
    InputTextModule,
    TabViewModule,
    TagModule,
    ScrollPanelModule,
    SimplebarAngularModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AccountCreateComponent,
    ContactsListComponent,
    DropdownModule,
    AccordionModule,
    AccountListComponent,
    NgbModalModule,
    ContactsCreateComponent,
    LeadsListComponent,
    LeadsCreateComponent,
    PagenotfoundComponent,
    CardModule,
    LoginComponent,
    OpportunityListComponent,
    OpportunityCreateComponent,
    MyCallsListComponent,
    MyMeetingsListComponent,
    MyTopOppertunitiesListComponent,
    ViewSingleDataComponent,
    ActivityComponent,
    HistoryComponent,
    DashboardComponent,
    ContactViewComponent,
    AccountViewComponent,
    LinkdinScrappingListComponent,
    LinkdinScrappingFormComponent,
  ],
})
export class SharedModule {}

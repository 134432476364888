import { Component } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { API_POINTS } from "src/app/helpers/model/endpoint.model";
import { ApiService } from "src/app/helpers/services/api.service";
import { ToastService } from "src/app/helpers/services/toast.service";
import {
  TYPE,
  LEAD_SOURCE,
  CURRENCY,
  OPPORTUNITY_TYPE,
  SALES_STAGE,
} from "src/app/helpers/constant-data/account.model";
import { getValidationError } from "src/app/shared/utlis/common-functions";
import { HttpErrorResponse } from "@angular/common/http";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-opportunity-create",
  templateUrl: "./opportunity-create.component.html",
  styleUrls: ["./opportunity-create.component.css"],
  providers: [DatePipe],
})
export class OpportunityCreateComponent {
  form!: FormGroup;
  endpoint: any = API_POINTS;
  allCurrencyData: any = CURRENCY;
  allOpportunityTypeData: any = OPPORTUNITY_TYPE;
  allsalesStageData: any = SALES_STAGE;
  allTypeData: any = TYPE;
  allLeadSourceData: any = LEAD_SOURCE;
  validationErrors: any = [];
  accountOptions: any[] = [];
  isInvalid: boolean = false;
  loading = false;
  today: Date = new Date();
  opportunityId: any;
  masterData: any;

  constructor(
    public _fb: FormBuilder,
    private _router: Router,
    private _activate_route: ActivatedRoute,
    private _datePipe: DatePipe,
    private toast: ToastService,
    public api: ApiService,
  ) {}

  ngOnInit() {
    this.form = this._fb.group({
      organizationId: [""],
      leadId: [""],
      opportunityName: ["", [Validators.required, Validators.maxLength(200)]],
      accountName: ["", [Validators.required, Validators.maxLength(200)]],
      currency: [""],
      expectedCloseDate: [null, Validators.required],
      opportunityAmmount: [0, [Validators.required]],
      opportunityType: [""],
      salesStage: ["", Validators.required],
      probabilityPercentage: ["", [this.percentageValidator()]],
      leadSource: ["", Validators.required],
      campaign: ["", Validators.maxLength(200)],
      nextStep: ["", Validators.maxLength(200)],
      description: ["", Validators.maxLength(200)],
      assignedTo: [""],
    });
    // for edit data(by id)
    this._activate_route.params.subscribe((param: any) => {
      this.opportunityId = param["id"];
    });
    if (this.opportunityId) {
      this.getAccountData();
    }
    this.getMasterData();
  }
  // validation for percentage
  percentageValidator() {
    return (control: FormControl) => {
      const value = control.value;
      if (value && value.toString().length > 3) {
        return { invalidPercentage: true };
      }
      return null;
    };
  }
  getMasterData() {
    this.loading = true;
    this.api.get(this.endpoint.getOpportunityMasterData).subscribe(
      (result: any) => {
        if (result.status == "SUCCESS") {
          this.accountOptions = result?.data?.accountList;
          this.masterData = result?.data?.userList;
        } else {
          this.toast.setErrorPopupMessage(result.message);
        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.toast.setErrorPopupMessage(error?.error?.message);
      },
    );
  }

  get f(): any {
    return this.form["controls"];
  }

  getAccountData() {
    this.api
      .get(this.endpoint.viewOpportunity + this.opportunityId)
      .subscribe((res: any) => {
        const data: any = res?.data;
        this.form.patchValue({
          organizationId: data.organizationId,
          leadId: data?.leadId,
          opportunityName: data?.opportunityName,
          opportunityId: data?.opportunityId,
          accountName: data?.accountId,
          accountId: data?.accountName,
          currency: data?.currency,
          opportunityAmmount: data?.opportunityAmmount,
          opportunityType: data?.opportunityType,
          salesStage: data?.salesStage,
          probabilityPercentage: data?.probabilityPercentage,
          leadSource: data?.leadSource,
          campaign: data?.campaign,
          nextStep: data?.nextStep,
          description: data?.description,
          assignedTo: data?.assignedTo,
        });
        const formattedDate = this._datePipe.transform(
          data?.expectedCloseDate,
          "yyyy-MM-dd",
        );
        this.form.patchValue({
          expectedCloseDate: formattedDate,
        });
      });
  }
  isFutureDate = (date: Date) => {
    // Disable past month and year dates
    return date >= this.form.value.expectedCloseDate;
  };

  onSubmit() {
    let obj = {
      organizationId: 1,
      leadId: 58,
      accountId: parseInt(this.form.value.accountName),
      opportunityName: this.form.value.opportunityName,
      opportunityId: this.form.value.accountName,
      accountName: this.form.value.accountName,
      currency: this.form.value.currency,
      expectedCloseDate: this._datePipe.transform(
        this.form.value.expectedCloseDate,
        "yyyy-MM-dd",
      ),
      opportunityAmmount: this.form.value.opportunityAmmount,
      opportunityType: this.form.value.opportunityType,
      salesStage: this.form.value.salesStage,
      probabilityPercentage: this.form.value.probabilityPercentage,
      leadSource: this.form.value.leadSource,
      campaign: this.form.value.campaign,
      nextStep: this.form.value.nextStep,
      description: this.form.value.description,
      assignedTo: this.form.value.assignedTo,
    };
    Object.values(this.form?.controls).forEach((control: any) =>
      control?.markAsTouched(),
    );
    if (this.form.valid) {
      if (!this?.opportunityId) {
        this.api.post(this.endpoint.createOpportunity, obj).subscribe(
          (res: any) => {
            if (res.status === "SUCCESS") {
              this.toast.setSuccessPopupMessage(res?.message);
              this.form.reset();
              this._router.navigate(["opportunity"]);
            }
          },
          (error: HttpErrorResponse) => {
            if (error.status != 400) {
              this.toast.setServerError(error.error.message);
            } else {
              this.validationErrors = [];
              this.isInvalid = true;
              this.validationErrors = getValidationError(error?.error);
            }
          },
        );
      } else {
        let editObj = {
          organizationId: 1,
          leadId: 58,
          accountId: parseInt(this.form.value.accountName),
          opportunityName: this.form.value.opportunityName,
          opportunityId: this.form.value.accountName,
          accountName: this.form.value.accountName,
          currency: this.form.value.currency,
          expectedCloseDate: this._datePipe.transform(
            this.form.value.expectedCloseDate,
            "yyyy-MM-dd",
          ),
          opportunityAmmount: this.form.value.opportunityAmmount,
          opportunityType: this.form.value.opportunityType,
          salesStage: this.form.value.salesStage,
          probabilityPercentage: parseInt(
            this.form.value.probabilityPercentage,
          ),
          leadSource: this.form.value.leadSource,
          campaign: this.form.value.campaign,
          nextStep: this.form.value.nextStep,
          description: this.form.value.description,
          assignedTo: this.form.value.assignedTo,
        };
        this.api
          .put(this.endpoint.editOpportunity + this?.opportunityId, editObj)
          .subscribe(
            (res: any) => {
              if (res.status === "SUCCESS") {
                this.toast.setSuccessPopupMessage(res?.message);
                this.form.reset();
                return window.history.back();
              }
            },
            (error: any) => {
              this.toast.setServerError(error.message);
            },
          );
      }
    }
  }
  onCancel() {
    this.form.reset();
    return window.history.back();
  }
}

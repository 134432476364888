import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "src/app/helpers/services/api.service";
@Component({
  selector: "app-view-single-data",
  templateUrl: "./view-single-data.component.html",
  styleUrls: ["./view-single-data.component.css"],
  providers: [DatePipe],
})
export class ViewSingleDataComponent implements OnInit {
  @Output() callAPI: EventEmitter<any> = new EventEmitter();
  @Input() userData: any;
  @Input() leadUsers: any;
  @Input() leadId: any;
  statuses!: any[];
  loadings: boolean = true;
  loading = false;
  activeTabIndex: number = 0;
  historyData: any[] | undefined;
  customers: any[] | undefined;
  constructor(
    public api: ApiService,
    private activeModal: NgbActiveModal,
  ) {}

  ngOnInit() {
    this.statuses = [
      { label: "Log Call", value: "Log Call", routerLink: "contact" },
      { label: "Schedule meeting", value: "Schedule meeting" },
      { label: "Follow-up email", value: "Follow-up email" },
    ];
  }
  updateFirstComponentData(historyData: any[]) {
    this.historyData = historyData;
  }

  updateSecondComponentData(customers: any[]) {
    this.customers = customers;
  }

  closeModel() {
    this.activeModal.dismiss();
  }
}

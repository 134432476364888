import { DatePipe } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  STATUS_PRIMARY,
  STATUS_SECONDARY,
  RELATED_TO,
  DURATION_HOURS,
  DURATION_MINUTES,
  TASK_STATUS,
  TASK_PRIORITY,
} from "src/app/helpers/constant-data/account.model";
import { API_POINTS } from "src/app/helpers/model/endpoint.model";
import { ApiService } from "src/app/helpers/services/api.service";
import { ToastService } from "src/app/helpers/services/toast.service";
import { getValidationError } from "../../../shared/utlis/common-functions";
import { HttpErrorResponse } from "@angular/common/http";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-activity-forms",
  templateUrl: "./activity-forms.component.html",
  styleUrls: ["./activity-forms.component.css"],
  providers: [DatePipe],
})
export class ActivityFormsComponent implements OnInit, AfterViewInit {
  @Input() meetingFormFlag = false;
  @Input() createTaskFlag = false;
  @Input() leadId: any;
  @Input() userMasterData: any;
  @Input() editListData: any;
  @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter();
  form!: FormGroup;
  endpoint: any = API_POINTS;
  allStatusPrimaryData: any = STATUS_PRIMARY;
  allStatusSecondaryData: any = STATUS_SECONDARY;
  allRetatedToData: any = RELATED_TO;
  hoursData: any = DURATION_HOURS;
  minutesData: any = DURATION_MINUTES;
  createTaskStatus: any = TASK_STATUS;
  createTaskPriority: any = TASK_PRIORITY;
  validationErrors: any = [];
  isInvalid: boolean = false;
  loading = false;
  today: Date = new Date();
  opportunityId: any;
  idRelatedToData: any;
  hourDuration: any;
  header: any;
  contactData: any;
  constructor(
    public _fb: FormBuilder,
    private _datePipe: DatePipe,
    private toast: ToastService,
    public api: ApiService,
    private cdr: ChangeDetectorRef,
    private activateModal: NgbActiveModal,
  ) {}
  ngOnInit() {
    this.getMasterData();
    if (
      this.editListData?.typeStr == "Task" ||
      this.editListData == "Task" ||
      this.editListData?.tabletype == "LOG_CALL_AND_SCHEDULE_MEETING" ||
      this.editListData?.specificType == "TASK"
    ) {
      this.getContactMasterData(this.leadId);
    }
    this.form = this._fb.group({
      subject: ["", [Validators.required, Validators.maxLength(200)]],
      statusPrimary: ["INBOUND", [Validators.required]],
      statusSecondary: ["PLANNED", [Validators.required]],
      status: ["PLANNED"],
      taskStatus: ["NOT_STARTED", [Validators.required]],
      priority: ["HIGH", [Validators.required]],
      contact: [],
      callDurationHours: ["", [Validators.required]],
      callDurationMins: ["15", [Validators.required]],
      meetingDurationMins: ["15"],
      relatedToPrimary: ["LEAD"],
      relatedToSecondary: [this?.leadId],
      startDate: [null],
      dueDate: [null],
      startDateTime: new FormControl(new Date(), Validators.required),
      endDateTime: [null, Validators.required],
      location: ["", Validators.maxLength(200)],
      description: ["", Validators.maxLength(500)],
      assignedTo: [null],
    });
  }
  getMasterData() {
    this.loading = true;
    this.api.get(this.endpoint.getRelatedToMasterData + "LEAD").subscribe(
      (result: any) => {
        if (result?.status == "SUCCESS") {
          this.idRelatedToData = result?.data;
        } else {
          this.toast.setErrorPopupMessage(result?.message);
        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.toast.setErrorPopupMessage(error?.error?.message);
      },
    );
  }
  getContactMasterData(leadId: any) {
    this.loading = true;
    this.api.get(this.endpoint.getHistoryMasterData + leadId).subscribe(
      (result: any) => {
        if (result.status == "SUCCESS") {
          this.contactData = result?.data?.contactResponsesList;
        } else {
          this.toast.setErrorPopupMessage(result.message);
        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.toast.setErrorPopupMessage(error?.error?.message);
      },
    );
  }
  handleTodayClick() {
    // Get current date and time
    const currentDate = new Date();
    // Format the date and time as desired
    const formattedDate = this.formatDate(currentDate);
    // Print the formatted date and time
    this.form.value.endDateTime = formattedDate;
    if (this.formControl.endDateTime) {
      this.form.patchValue({
        endDateTime: formattedDate,
      });
    } else if (this.formControl.startDateTime) {
      this.form.patchValue({
        startDateTime: formattedDate,
      });
    } else if (this.formControl.startDate) {
      this.form.patchValue({
        startDate: formattedDate,
      });
    } else if (this.formControl.dueDate) {
      this.form.patchValue({
        dueDate: formattedDate,
      });
    }
  }
  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    const hours = this.padZero(date.getHours());
    const minutes = this.padZero(date.getMinutes());
    const seconds = this.padZero(date.getSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  // Function to pad zero to single digits
  padZero(num: number): string {
    return num < 10 ? `0${num}` : num.toString();
  }

  ngAfterViewInit() {
    this.getMasterData();
    const newData = this.editListData;
    this.getRelatedtoData(newData?.relatedToPrimary);
    if (
      newData?.typeStr ||
      newData?.tabletype == "LOG_CALL_AND_SCHEDULE_MEETING" ||
      newData?.specificType
    ) {
      if (
        newData?.typeStr == "Schedule Type" ||
        newData?.specificType == "SCHEDULE_TYPE"
      ) {
        this.header = "Update Schedule Meeting";
        this.meetingFormFlag = true;
      } else if (
        newData?.typeStr == "Task" ||
        newData?.specificType == "TASK"
      ) {
        this.header = "Update Task";
        this.createTaskFlag = true;
      } else {
        this.header = "Update Call Log";
      }
      this.form.patchValue({
        subject: newData?.subject,
        statusPrimary: newData?.statusPrimary,
        statusSecondary: newData?.statusAlternate,
        status: newData?.statusAlternate,
        taskStatus: newData?.statusPrimary,
        priority: newData?.taskPriority,
        contact: newData?.contactId,
        callDurationHours: newData?.duration?.startsWith("0")
          ? newData?.duration?.slice(1, 2)
          : newData?.duration?.slice(0, 2),
        callDurationMins: newData?.duration?.slice(3, 5),
        meetingDurationMins: newData?.duration?.slice(3, 5),
        relatedToPrimary: newData?.relatedToPrimary,
        relatedToSecondary: newData?.relatedToId,
        startDate: newData?.startDateTime,
        dueDate: newData?.endDateTime,
        startDateTime: newData?.startDateTime,
        endDateTime: newData?.endDateTime,
        location: newData?.location,
        description: newData?.description,
        assignedTo: newData?.assignedTo,
      });
    } else {
      if (newData == "Schedule meeting") {
        this.header = "Create Schedule Meeting";
        this.meetingFormFlag = true;
      } else if (newData == "Task") {
        this.header = "Create Task";
        this.createTaskFlag = true;
      } else {
        this.header = "Create Call Log";
      }
    }
    this.cdr.detectChanges();
  }

  getRelatedtoData(data: any) {
    if (data) {
      this.loading = true;
      this.api.get(this.endpoint.getRelatedToMasterData + data).subscribe(
        (result: any) => {
          if (result?.status == "SUCCESS") {
            this.idRelatedToData = result?.data;
          } else {
            this.toast.setErrorPopupMessage(result?.message);
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.toast.setErrorPopupMessage(error?.error?.message);
        },
      );
    }
  }

  get formControl(): any {
    return this.form["controls"];
  }

  isFutureDate = (date: Date) => {
    // Disable past month and year dates
    return date >= this.form.value.expectedCloseDate;
  };
  formatDuration(hours: any, mins: any): any {
    if (!hours && !mins) {
      return "";
    }

    const formattedHours = hours
      ? parseInt(hours, 10) < 10
        ? `0${hours}`
        : `${hours}`
      : "00";
    const formattedMins = mins
      ? parseInt(mins, 10) < 10 && parseInt(mins, 10) !== 0
        ? `0${mins}`
        : `${mins}`
      : "00";

    return `${formattedHours}:${formattedMins}`;
  }

  onSubmit() {
    const callDuration = this.formatDuration(
      this?.form?.value?.callDurationHours,
      this?.form?.value?.callDurationMins,
    );
    const meetingDuration = this.formatDuration(
      null,
      this?.form?.value?.meetingDurationMins,
    );
    let obj = {
      leadId: this.editListData?.callAndMeetingId
        ? this.editListData?.leadId
        : this?.leadId,
      type:
        !this.meetingFormFlag && !this.createTaskFlag
          ? "CALL_LOG"
          : this.meetingFormFlag
            ? "SCHEDULE_TYPE"
            : "TASK",
      subject: this.form.value.subject,
      statusPrimary: !this.createTaskFlag
        ? this.form.value.statusPrimary
        : this.form.value.taskStatus,
      statusAlternate: this.meetingFormFlag
        ? this.form.value.status
        : !this.createTaskFlag
          ? this.form.value.statusSecondary
          : null,
      startDateTime: !this.createTaskFlag
        ? this._datePipe.transform(
            this.form.value.startDateTime,
            "yyyy-MM-dd hh:mm:ss",
          )
        : this._datePipe.transform(
            this.form.value.startDate,
            "yyyy-MM-dd hh:mm:ss",
          ),
      endDateTime: !this.createTaskFlag
        ? this._datePipe.transform(
            this.form.value.endDateTime,
            "yyyy-MM-dd hh:mm:ss",
          )
        : this._datePipe.transform(
            this.form.value.dueDate,
            "yyyy-MM-dd hh:mm:ss",
          ),
      contactId: this.form.value.contact,
      taskPriority: this.form.value.priority,
      relatedToPrimary: this.form.value.relatedToPrimary,
      relatedToId: this.form.value.relatedToSecondary,
      assignedTo: this.form.value.assignedTo,
      duration: !this.meetingFormFlag
        ? callDuration
        : !this.createTaskFlag
          ? meetingDuration
          : null,

      description: this.form.value.description,
      location: this.meetingFormFlag ? this.form.value.location : "",
    };
    Object.values(this.form?.controls).forEach((control: any) =>
      control?.markAsTouched(),
    );

    if (obj.type == "CALL_LOG") {
      this.form.removeControl("status");
      this.form.removeControl("taskStatus");
      this.form.removeControl("priority");
      this.form.removeControl("contact");
      this.form.removeControl("meetingDurationMins");
      this.form.removeControl("startDate");
      this.form.removeControl("endDateTime");
      this.form.removeControl("dueDate");
      this.form.removeControl("location");
    }
    if (obj.type == "SCHEDULE_TYPE") {
      this.form.removeControl("statusPrimary");
      this.form.removeControl("taskStatus");
      this.form.removeControl("priority");
      this.form.removeControl("contact");
      this.form.removeControl("statusSecondary");
      this.form.removeControl("startDate");
      this.form.removeControl("dueDate");
      this.form.removeControl("callDurationHours");
      this.form.removeControl("callDurationMins");
    }
    if (obj.type == "TASK") {
      this.form.removeControl("status");
      this.form.removeControl("meetingDurationMins");
      this.form.removeControl("startDateTime");
      this.form.removeControl("statusSecondary");
      this.form.removeControl("statusPrimary");
      this.form.removeControl("endDateTime");
      this.form.removeControl("location");
      this.form.removeControl("callDurationHours");
      this.form.removeControl("callDurationMins");
    }
    if (this.form.valid) {
      if (!this.editListData?.callAndMeetingId) {
        this.api.post(this.endpoint.createlogCall, obj).subscribe(
          (res: any) => {
            if (res.status === "SUCCESS") {
              this.toast.setSuccessPopupMessage(res?.message);
              this.api.data.next(true);
              this.onCancel();
            }
          },
          (error: HttpErrorResponse) => {
            if (error.status != 400) {
              this.toast.setServerError(error.error.message);
            } else {
              this.validationErrors = [];
              this.isInvalid = true;
              this.validationErrors = getValidationError(error?.error);
            }
          },
        );
      } else {
        this.api
          .put(
            this.endpoint.editlogCall + this?.editListData?.callAndMeetingId,
            obj,
          )
          .subscribe(
            (res: any) => {
              if (res.status === "SUCCESS") {
                this.toast.setSuccessPopupMessage(res?.message);
                this.api.data.next(true);
                this.onCancel();
                this.activateModal.dismiss("success");
              }
            },
            (error: any) => {
              if (error.status != 400) {
                this.toast.setServerError(error.error.message);
              } else {
                this.validationErrors = [];
                this.isInvalid = true;
                this.validationErrors = getValidationError(error?.error);
              }
            },
          );
      }
    }
  }

  onCancel() {
    this.form.reset({
      subject: "",
      relatedToPrimary: "LEAD",
      statusPrimary: "INBOUND",
      statusSecondary: "PLANNED",
      status: "PLANNED",
      taskStatus: "NOT_STARTED",
      priority: "HIGH",
      contact: null,
      callDurationHours: "",
      callDurationMins: "15",
      meetingDurationMins: "15",
      relatedToSecondary: this?.leadId,
      startDate: null,
      dueDate: null,
      startDateTime: new Date(),
      endDateTime: null,
      location: "",
      description: "",
      assignedTo: null,
    });
    this.closeDialog.emit();
    this.activateModal.dismiss("success");
  }
}

export const cleanArray = (arr: any) => {
  return arr.filter((element: any) => {
    if (element === null || element === undefined) {
      return false;
    }
    if (typeof element === "string" && element.trim() === "") {
      return false;
    }
    if (Array.isArray(element) && element.length === 0) {
      return false;
    }
    return true;
  });
};

export const getValidationError = (error: any) => {
  var validationErrors: any = [];
  if (error?.data) {
    for (var i = 0; i < error.data.length; i++) {
      validationErrors[error.data[i].feildName] = error.data[i].message;
    }
  }
  return validationErrors;
};

<div class="container-fluid p-0">
  <div class="d-flex row h-100">
    <div class="col-7 p-0">
      <img
        src="../../../assets/images/image.jpg"
        class="float-start"
        width="100%"
        height="100%"
      />
    </div>
    <div class="col-5 container-parent">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="text-center">
          <h2>Login</h2>
        </div>
        <!-- email -->
        <div class="form-col mb-2">
          <label>Email <span class="text-invalid"> *</span></label>
          <input
            type="email"
            class="form-control"
            placeholder="Email"
            formControlName="email"
          />
          <div
            *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)"
            class="text-invalid"
            role="alert"
          >
            <small *ngIf="f.email.errors?.required"> Email is required. </small>
            <small *ngIf="f.email.errors?.email">
              Please enter valid email address.
            </small>
          </div>
        </div>
        <!-- password -->
        <div class="form-col">
          <div class="password-container">
            <label>Password<span class="text-invalid"> *</span></label>
            <input
              formControlName="password"
              [type]="showPassword ? 'text' : 'password'"
              class="form-control"
              placeholder="Confirm Password"
            />
            <div class="view-password">
              <i
                alt="show"
                [class]="showPassword ? 'bi bi-eye' : 'bi bi-eye-slash'"
                (click)="showPassword = !showPassword"
              ></i>
            </div>
          </div>
          <div
            *ngIf="
              f.password.invalid && (f.password.dirty || f.password.touched)
            "
            class="text-invalid"
          >
            <small *ngIf="f.password.errors?.required">
              Password is required.
            </small>
            <small *ngIf="f.password.errors?.minlength">
              At least 6 characters long.
            </small>
          </div>
        </div>
        <!-- submit -->
        <div class="row d-flex mt-4">
          <div class="col-12 text-end">
            <button type="submit" class="btn btn-lg-login text-white btn-lg">
              Login
            </button>
          </div>
        </div>
        <div class="row d-flex mt-2">
          <div class="col-12 text-end">
            <a routerLink="/forgot-password" style="font-size: 12px !important;"
              >Forgot Password</a
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

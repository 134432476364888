export const API_POINTS = {
  /* login */
  // login: "http://192.168.29.245:7220/v1/user/login",
  
  login: "v1/user/login",
  /* lead */
  addLead: "v1/leads/add",
  getLead: "v1/leads/list",
  editLead: "v1/leads/edit/",
  viewLead: "v1/leads/get/",
  leadStatusChangeByID: "v1/leads/",
  deleteLeadByID: "v1/leads/delete/",
  /* account */
  addAccount: "v1/accounts/add",
  getAccount: "v1/accounts/list",
  editAccount: "v1/accounts/edit/",
  viewAccount: "v1/accounts/get/",
  accountStatusChangeByID: "v1/accounts/",
  getAccountsMasterData: "v1/accounts/getAccountsDetails",
  deleteAccountByID: "v1/accounts/delete/",
  /* contact */
  getContact: "v1/contact/list",
  createContact: "v1/contact/add",
  editContact: "v1/contact/edit/",
  getContactByID: "v1/contact/get/",
  deleteContactByID: "v1/contact/delete/",
  contactStatusChangeByID: "v1/contact/",
  getContactMasterData: "v1/contact/getContactDetails",
  /* Opportunity */
  createOpportunity: "v1/opportunity/add",
  editOpportunity: "v1/opportunity/edit/",
  viewOpportunity: "v1/opportunity/get/",
  deleteOpportunityByID: "v1/opportunity/delete/",
  opportunityStatusChangeByID: "v1/opportunity/",
  getOpportunity: "v1/opportunity/list",
  getOpportunityMasterData: "v1/opportunity/getOpportunityDetails",

  // Import File
  importFile: "v1/importData",
  downloadLeadTemplate: "v1/importData/downloadTemplate/",

  // Log call and schedule time
  createlogCall: "v1/logCall/add",
  editlogCall: "v1/logCall/edit/",
  viewlogCall: "v1/logCall/get/",
  deletelogCallByID: "v1/logCall/delete/",
  logcallStatusChangeByID: "v1/logCall/",
  getlogcall: "v1/logCall/list",
  getlogcallMasterData: "v1/logCall/getLogCalls/",
  getRelatedToMasterData: "v1/masterData/status/",

  // History
  createHistory: "v1/history/addHistory",
  editHistory: "v1/history/editHistory/",
  viewHistoryBYyID: "v1/history/get/",
  deleteHistoryByID: "v1/history/delete/",
  getHistoryList: "v1/history/list",
  getHistory: "v1/masterData/getLogcallAndLeadHistoryData",
  getHistoryMasterData: "v1/history/getHistoryDetails/leadId/",
  downloadAttachments: "v1/history/",

// Linkdin Scrapping
getLinkdinScrappingList:"v1/profileScraperRequest/",
initLinkdinScrapping:"v1/profileScraperRequest"
};

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import {  NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {  MenuItem } from "primeng/api";
import { API_POINTS } from "src/app/helpers/model/endpoint.model";
import { ApiService } from "src/app/helpers/services/api.service";
import { ToastService } from "src/app/helpers/services/toast.service";
import { ActivityViewComponent } from "../activity-view/activity-view.component";
import { ActivityFormsComponent } from "../activity-forms/activity-forms.component";
@Component({
  selector: "app-activity",
  templateUrl: "./activity.component.html",
  styleUrls: ["./activity.component.css"],
})
export class ActivityComponent implements OnInit {
  @Input() userData: any;
  @Input() leadIdParent: any;
  @Input() callAPI: any;
  @Output() dataUpdated = new EventEmitter<any[]>();

  viewLogData: any = {};
  customers!: any[];
  statuses!: any[];
  loading = false;
  event: any;
  endpoint = API_POINTS;
  totalRecords: any = 0;
  datatableRowsVal = 10;
  rowPerPage: any = [10, 25, 50];
  resdata: any;
  globalFilter: any;
  selectedStatus: any;
  logCallForm: boolean = false;
  scheduleMeetingForm: boolean = false;
  createTaskForm: boolean = false;
  cols: any;
  emptyMessage: any;
  masterData: any;
  accountOptions: any[] = [];
  id: any;
  modelTitle: string | undefined;
  modelMsg: string | undefined;
  editData: any;
  deleteId: any;
  items: MenuItem[] | undefined;
  isMenubarVisible: boolean = true;
  historyData: any;
  constructor(
    public api: ApiService,
    private toast: ToastService,
    public router: Router,
    private modalService: NgbModal,
  ) {
    this.api.data.subscribe(() => {
      if (this.event) {
        this.loadPaperLazy(this.event);
      }
    });
  }

  ngOnInit() {
    this.items = [
      {
        label: "Create Task",
        command: () => this.openTask("Task"),
        items: [
          {
            label: "Log Call",
            command: () => this.openTask("Log Call"),
          },
          {
            label: "Schedule meeting",
            command: () => this.openTask("Schedule meeting"),
          },
          {
            label: "Follow-up email",

            // command: () => this.openTask('New'),
          },
        ],
      },
    ];

    this.statuses = [
      { label: "Create Task", value: "Create Task" },
      { label: "Log Call", value: "Log Call" },
      { label: "Schedule meeting", value: "Schedule meeting" },
      { label: "Follow-up email", value: "Follow-up email" },
    ];
    this.selectedStatus = "Create Task";

    setTimeout(() => {
      this.emptyMessage = "No Records Found";
      this.cols = [
        {
          colName: "Sr.No",
          isSort: true,
          colWidth: "9%",
          sortField: "srNo",
        },
        {
          isSort: true,
          colName: "Subject",
          colWidth: "15%",
          sortField: "subject",
        },
        {
          isSort: true,
          colName: "Status",
          colWidth: "15%",
          sortField: "statusAlternate",
        },
        {
          isSort: false,
          colName: "Contact",
          colWidth: "20%",
        },
        {
          isSort: false,
          colName: "Due Date",
          colWidth: "16%",
        },
        {
          isSort: false,
          colName: "Assigned User",
          colWidth: "12%",
        },
        {
          colName: "Actions",
          isSort: false,
          colWidth: "16%",
        },
      ];
    });
    this.getMasterData(this.leadIdParent);
  }

  getMasterData(leadID: any) {
    this.api.get(this.endpoint.getlogcallMasterData + leadID).subscribe(
      (result: any) => {
        if (result.status == "SUCCESS") {
          this.accountOptions = result?.data?.accountList;
          this.masterData = result?.data?.assignedToList;
        } else {
          this.toast.setErrorPopupMessage(result.message);
        }
      },
      (error) => {
        this.toast.setErrorPopupMessage(error?.error?.message);
      },
    );
  }
  openDeleteConfirmationModal(id: any, content: any) {
    this.modalService.open(content, {
      centered: true,
      size: "s",
      backdrop: "static",
      keyboard: false,
    });
    this.deleteId = id;
  }
  deleteCallById() {
    this.loading = true;
    this.api
      .delete(this.endpoint.deletelogCallByID + this.deleteId?.callAndMeetingId)
      .subscribe(
        (result: any) => {
          this.resdata = result;
          if (this.resdata.status == "SUCCESS") {
            this.toast.setSuccessPopupMessage(result.message);
            this.loadPaperLazy();
          } else {
            this.toast.setErrorPopupMessage(this.resdata.message);
          }
          this.loading = false;
        },
        (error: any) => {
          this.loading = false;
          this.toast.setErrorPopupMessage(error?.error?.message);
        },
      );
  }
  viewLogCallById(user: any) {
    this.api
      .get(this.endpoint.viewlogCall + user.callAndMeetingId)
      .subscribe((response: any) => {
        this.viewLogData = response.data;
        this.id = user;
        const modalRef = this.modalService.open(ActivityViewComponent, {
          size: "lg",
          centered: true,
        });
        modalRef.componentInstance.callLogData = response.data;
        modalRef.componentInstance.leadId = this.leadIdParent;
        modalRef.componentInstance.userMasterData = this.masterData;
        modalRef.componentInstance.loadPaperLazyEvent.subscribe(() => {
          this.loadPaperLazy();
        });
        if (this.viewLogData?.type == "SCHEDULE_TYPE") {
          modalRef.componentInstance.header = "View Schedule Meeting";
        } else if (this.viewLogData?.type == "CALL_LOG") {
          modalRef.componentInstance.header = "View My Calls";
        } else {
          modalRef.componentInstance.header = "View Task";
        }
        modalRef.componentInstance.id = user;
      });
  }

  getDefaultDtSort(event: any) {
    if (event) {
      if (!event?.sortField || event?.sortField == "") {
        event.sortField = "srNo";
        event.sortOrder = "-1";
      }
    }
    return event;
  }
  loadPaperLazy(event: any = null) {
    this.loading = true;
    if (event !== null) {
      this.event = event;
    }
    this.event.customFilters = {
      leadId: this.leadIdParent,
      primaryStatus: ["IN_PROGRESS"],
      alternateStatus: ["PLANNED"],
    };
    this.event = this.getDefaultDtSort(this.event);
    const body = {
      event: this.event,
    };
    this.globalFilter =
      event && event.globalFilter
        ? event.globalFilter
        : this.event.globalFilter;
    this.api.post(this.endpoint.getlogcall, body).subscribe(
      (result: any) => {
        this.customers = [];
        this.resdata = result;
        if (this.resdata.status == "SUCCESS") {
          this.customers = this.resdata.data.records;
          this.totalRecords = this.resdata.data.recordsTotal;
          this.loading = false;
          this.dataUpdated.emit(this.customers);
        } else {
          this.loading = false;
        }
      },
      (error: any) => {
        this.customers = [];
        this.loading = false;
        this.toast.setErrorPopupMessage(error?.error?.message);
      },
    );
  }

  openTask(event: any) {
    if (event?.callAndMeetingId) {
      this.api
        .get(this.endpoint.viewlogCall + event.callAndMeetingId)
        .subscribe((response: any) => {
          this.viewLogData = response.data;
          this.id = event;
          const modalRef = this.modalService.open(ActivityFormsComponent, {
            size: "lg",
            centered: true,
          });
          modalRef.componentInstance.editListData = response?.data;
          modalRef.componentInstance.userMasterData = this.masterData;
          modalRef.componentInstance.leadId = this.leadIdParent;

          modalRef.result.then(
            (result: any) => {},
            (reason: any) => {
              if (reason == "success") {
                this.loadPaperLazy();
              }
            },
          );
        });
    } else {
      const modalRef = this.modalService.open(ActivityFormsComponent, {
        size: "lg",
        centered: true,
      });
      modalRef.componentInstance.editListData = event;
      modalRef.componentInstance.userMasterData = this.masterData;
      modalRef.componentInstance.leadId = this.leadIdParent;

      modalRef.result.then(
        (result: any) => {},
        (reason: any) => {
          if (reason == "success") {
            this.loadPaperLazy();
          }
        },
      );
    }
  }
}

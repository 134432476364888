import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-contact-view",
  templateUrl: "./contact-view.component.html",
  styleUrls: ["./contact-view.component.css"],
})
export class ContactViewComponent {
  @Input() userData: any;
  @Input() id: any;

  constructor(
    private router: Router,
    private activeModal: NgbActiveModal,
  ) {}

  closeModel() {
    this.activeModal.dismiss();
  }

  edit(user: any) {
    this.closeModel();
    this.router.navigateByUrl(`contact/${user.contactId}/edit`);
  }
}

<div class="container-fluid h-100 padding-30px">
  <div class="row margin-right-0px">
    <div class="progress-spinner" *ngIf="loading">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="col-lg-12">
      <p-card>
        <!-- table -->
        <div class="table-responsive">
          <p-table
            #paperDt
            [value]="users"
            [lazy]="true"
            (onLazyLoad)="loadPaperLazy($event)"
            [rows]="datatableRowsVal"
            [showCurrentPageReport]="true"
            [totalRecords]="totalRecords"
            [rowsPerPageOptions]="rowPerPage"
            styleClass="p-datatable-customers p-datatable-gridlines"
            [paginator]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            dataKey="id"
          >
            <!-- gloabal search -->
            <ng-template pTemplate="caption">
              <div class="list-card-design">
                <h3 class="p-card-title">Account List</h3>
                <div style="display: flex">
                  <div class="searchbox">
                    <span class="p-input-icon-left p-ml-auto">
                      <i class="pi pi-search"></i>
                      <input
                        pInputText
                        type="text"
                        (input)="paperDt.filterGlobal(globalFilter, 'contains')"
                        placeholder="Search"
                        [(ngModel)]="globalFilter"
                      />
                    </span>
                  </div>
                  <div>
                    <button
                      class="btn btn-pr-button-design btn-default custom-button-design"
                      (click)="onCreateAccount()"
                    >
                      Create Account
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
            <!-- table header -->
            <ng-template pTemplate="header">
              <tr>
                <th
                  class="text-align-center"
                  *ngFor="let tableHeading of cols"
                  [pSortableColumn]="tableHeading?.sortField"
                  [style.width]="tableHeading.colWidth"
                >
                  {{ tableHeading.colName }}
                  <p-sortIcon
                    *ngIf="tableHeading.isSort"
                    [field]="tableHeading?.sortField"
                  ></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <!-- table body -->
            <ng-template pTemplate="body" let-user>
              <tr>
                <td>{{ user.srNo }}</td>
                <td>{{ user.name || "-" }}</td>
                <!-- <td>{{ user.title || "-" }}</td> -->
                <!-- <td>{{ user.accountName || "-" }}</td> -->
                <td>{{ user.emailAddress || "-" }}</td>
                <td>{{ user.officePhone || "-" }}</td>
                <td>{{ user.assignedName || "-" }}</td>
                <td>{{ user.dateCreated || "-" }}</td>
                <td class="actionBar">
                  <div class="actionBarContainer">
                    <a title="View" class="view-account" (click)="open(user)">
                      <i class="bi bi-eye-fill"></i>
                    </a>
                    <a (click)="edit(user)" title="Edit">
                      <i class="bi bi-pencil-square"></i>
                    </a>
                    <a title="Delete">
                      <i
                        class="bi bi-trash-fill"
                        (click)="
                          showAccountModalDialog(
                            'delete',
                            user,
                            deleteConfirmationModal
                          )
                        "
                      ></i>
                    </a>
                    <a>
                      <i
                        class="bi bi-bookmark-fill deactive"
                        *ngIf="!user.active"
                        title="Activate"
                        (click)="
                          showAccountModalDialog(
                            'deactivate',
                            user,
                            deleteConfirmationModal
                          )
                        "
                      ></i>
                      <i
                        class="bi bi-bookmark-fill active"
                        *ngIf="user.active"
                        title="Deactivate"
                        (click)="
                          showAccountModalDialog(
                            'activate',
                            user,
                            deleteConfirmationModal
                          )
                        "
                      ></i>
                    </a>
                  </div>
                </td>
              </tr>
            </ng-template>
            <!-- Empty Message -->
            <ng-template pTemplate="emptymessage" let-user>
              <tr>
                <td class="text-align-center" [attr.colspan]="cols?.length">
                  {{ emptyMessage }}
                </td>
              </tr>
            </ng-template>
          </p-table>
          <ng-template #deleteConfirmationModal let-modal>
            <div class="modal-header">
              <h4 class="modal-title">{{ modelTitle }}</h4>
              <button
                type="button"
                class="btn-close"
                aria-describedby="modal-title"
                (click)="modal.dismiss('Cross click')"
              ></button>
            </div>
            <div class="modal-body">
              {{ modelMsg }}
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-cancel"
                (click)="modal.dismiss()"
              >
                {{ type == "delete" ? "Cancel" : "No" }}
              </button>
              <button
                type="button"
                class="btn btn-submit"
                style="margin-left: 10px !important"
                (click)="onActions(); modal.close()"
              >
                {{ type == "delete" ? "Delete" : "Yes" }}
              </button>
            </div>
          </ng-template>
        </div>
      </p-card>
    </div>
  </div>
</div>

import { Component } from '@angular/core';

@Component({
  selector: 'app-token-expired',
  templateUrl: './token-expired.component.html',
  styleUrls: ['./token-expired.component.css']
})
export class TokenExpiredComponent {

}

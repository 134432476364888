import { Component } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs";
import { ApiService } from "./helpers/services/api.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  notShowHeaderLinks: string[] = [
    "/",
    "/login",
    "/404",
    "forgot-password",
    "reset-password",
    "invalid-token",
    "token-expired",
  ];
  currentRoute: string = "";
  isShow: boolean = false;
  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private api: ApiService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentRoute = event["url"];
        if (!this.notShowHeaderLinks.includes(this.currentRoute)) {
          if (
            this.currentRoute === "/" ||
            this.currentRoute.includes("/login") ||
            this.currentRoute.includes("/404") ||
            this.currentRoute.includes("/forgot-password") ||
            this.currentRoute.includes("/reset-password") ||
            this.currentRoute.includes("/invalid-token") ||
            this.currentRoute.includes("/token-expired")
          ) {
            this.isShow = false;
          } else {
            this.isShow = true;
          }
        } else {
          this.isShow = false;
        }
      });
  }
  title = "CRM";

  ngOnInit() {
    this.api.startRefreshTokenTimer();
  }
}

<div class="modal-dialog-scrollable">
  <div class="modal-header">
    <h4 class="modal-title model-lable">{{ header }}</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="onCancel()"
    ></button>
  </div>
  <div class="modal-body custom-model-design">
    <div class="container-fluid h-100">
      <div class="progress-spinner" *ngIf="loading">
        <p-progressSpinner></p-progressSpinner>
      </div>
      <div class="col-12 padding-top-bottom-20">
        <form [formGroup]="form" class="custom-form-container">
          <div class="row">
            <div class="col-6 form-col">
              <label>Subject<span class="text-invalid"> *</span></label>
              <input
                pInputText
                type="text"
                class="form-control"
                placeholder="Subject"
                formControlName="subject"
              />
              <div
                *ngIf="
                  formControl.subject?.invalid &&
                  (formControl.subject?.dirty || formControl.subject?.touched)
                "
                class="text-invalid"
                role="alert"
              >
                <small *ngIf="formControl.subject?.errors?.required">
                  Subject is required.
                </small>
                <small *ngIf="formControl.subject?.errors?.maxlength">
                  Subject cannot be more than 200 characters long.
                </small>
              </div>
              <div class="text-invalid" *ngIf="isInvalid">
                {{ validationErrors["subject"] }}
              </div>
            </div>
            <div
              class="col-6 form-col mb-2"
              *ngIf="!meetingFormFlag && !createTaskFlag"
            >
              <label>Status<span class="text-invalid"> *</span></label>
              <div class="col-12 d-flex justify-content-space-between">
                <div class="col-6">
                  <p-dropdown
                    styleClass="width-100-perc"
                    [options]="allStatusPrimaryData"
                    formControlName="statusPrimary"
                    optionLabel="key"
                    optionValue="value"
                    [showClear]="true"
                    placeholder="Primary status"
                  ></p-dropdown>
                </div>

                <div class="col-6" style="padding-left: 10px">
                  <p-dropdown
                    styleClass="width-100-perc"
                    [options]="allStatusSecondaryData"
                    formControlName="statusSecondary"
                    optionLabel="key"
                    optionValue="value"
                    [showClear]="true"
                    placeholder="Alternate status"
                  ></p-dropdown>
                </div>
              </div>
              <div
                *ngIf="
                  formControl.statusPrimary?.invalid ||
                  formControl.statusSecondary?.invalid
                "
                class="text-invalid"
                role="alert"
              >
                <small
                  *ngIf="
                    (formControl.statusPrimary?.dirty ||
                      formControl.statusPrimary?.touched) &&
                    formControl.statusPrimary?.errors?.required
                  "
                >
                  Primary status is required.
                </small>
                <small
                  *ngIf="
                    (formControl.statusSecondary?.dirty ||
                      formControl.statusSecondary?.touched) &&
                    formControl.statusSecondary?.errors?.required
                  "
                >
                  Alternate status is required.
                </small>
              </div>
              <div class="text-invalid" *ngIf="isInvalid">
                {{
                  validationErrors["statusPrimary"]
                    ? validationErrors["statusPrimary"]
                    : validationErrors["statusSecondary"]
                }}
              </div>
            </div>

            <div class="col-6 form-col mb-2" *ngIf="meetingFormFlag">
              <label>Status</label>
              <p-dropdown
                styleClass="width-100-perc"
                [options]="allStatusSecondaryData"
                formControlName="status"
                optionLabel="key"
                optionValue="value"
                [showClear]="true"
                placeholder="Status"
              ></p-dropdown>
            </div>
            <div class="col-6 form-col mb-2" *ngIf="createTaskFlag">
              <label>Status<span class="text-invalid"> *</span></label>
              <p-dropdown
                styleClass="width-100-perc"
                [options]="createTaskStatus"
                formControlName="taskStatus"
                optionLabel="key"
                optionValue="value"
                [showClear]="true"
                placeholder="Status"
              ></p-dropdown>
              <div
                *ngIf="
                  formControl.taskStatus?.invalid &&
                  (formControl.taskStatus?.dirty ||
                    formControl.taskStatus?.touched)
                "
                class="text-invalid"
                role="alert"
              >
                <small *ngIf="formControl.taskStatus?.errors?.required">
                  Status is required.
                </small>
              </div>
              <div class="text-invalid" *ngIf="isInvalid">
                {{ validationErrors["taskStatus"] }}
              </div>
            </div>
            <div class="col-6 form-col" *ngIf="!createTaskFlag">
              <label
                >Start Date & Time<span class="text-invalid"> *</span></label
              >
              <div class="div">
                <p-calendar
                  styleClass="width-100-perc"
                  class="custom-calendarx"
                  formControlName="startDateTime"
                  inputId="buttondisplay"
                  [showIcon]="true"
                  [showClear]="true"
                  [showButtonBar]="true"
                  inputId="buttondisplay"
                  placeholder="01-01-2024 03:43:02"
                  dateFormat="yy-mm-dd"
                  [required]="true"
                  [minDate]="today"
                  [showTime]="true"
                  [showSeconds]="true"
                  (onTodayClick)="handleTodayClick()"
                ></p-calendar>
                <div
                  *ngIf="
                    formControl.startDateTime?.invalid &&
                    (formControl.startDateTime?.dirty ||
                      formControl.startDateTime?.touched)
                  "
                  class="text-invalid"
                  role="alert"
                >
                  <small *ngIf="formControl.startDateTime?.errors?.required">
                    Start date and time is required
                  </small>
                </div>
                <div class="text-invalid" *ngIf="isInvalid">
                  {{ validationErrors["startDateTime"] }}
                </div>
              </div>
            </div>
            <div class="col-6 form-col" *ngIf="createTaskFlag">
              <label>Start Date</label>
              <div class="div">
                <p-calendar
                  styleClass="width-100-perc"
                  class="custom-calendarx"
                  formControlName="startDate"
                  inputId="buttondisplay"
                  [showIcon]="true"
                  [showClear]="true"
                  [showButtonBar]="true"
                  inputId="buttondisplay"
                  placeholder="01-01-2024 03:43:02"
                  dateFormat="yy-mm-dd"
                  [minDate]="today"
                  [showTime]="true"
                  [showSeconds]="true"
                  (onTodayClick)="handleTodayClick()"
                ></p-calendar>
              </div>
            </div>
            <div class="col-6 form-col mb-2">
              <label>Related To</label>
              <div class="col-12">
                <div class="col-12" simplebar>
                  <p-dropdown
                    styleClass="width-100-perc"
                    [options]="allRetatedToData"
                    formControlName="relatedToPrimary"
                    optionLabel="key"
                    optionValue="value"
                    [showClear]="true"
                    placeholder="Related To"
                    (onChange)="
                      getRelatedtoData(formControl?.relatedToPrimary?.value)
                    "
                  ></p-dropdown>
                </div>
                <div class="col-12" style="padding-top: 10px">
                  <p-dropdown
                    styleClass="width-100-perc"
                    [options]="idRelatedToData"
                    formControlName="relatedToSecondary"
                    optionLabel="name"
                    optionValue="id"
                    [showClear]="true"
                    placeholder="Related To"
                  ></p-dropdown>
                </div>
              </div>
            </div>
            <div
              class="col-6 form-col"
              *ngIf="meetingFormFlag && !createTaskFlag"
            >
              <label>End Date & Time<span class="text-invalid"> *</span></label>
              <div class="div">
                <p-calendar
                  styleClass="width-100-perc"
                  class="custom-calendarx"
                  formControlName="endDateTime"
                  inputId="buttondisplay"
                  [showIcon]="true"
                  [showClear]="true"
                  [showButtonBar]="true"
                  inputId="buttondisplay"
                  placeholder="01-01-2024 03:43:02"
                  dateFormat="yy-mm-dd"
                  [required]="true"
                  [minDate]="today"
                  [showTime]="true"
                  [showSeconds]="true"
                  (onTodayClick)="handleTodayClick()"
                ></p-calendar>
                <div
                  *ngIf="
                    formControl.endDateTime?.invalid &&
                    (formControl.endDateTime?.dirty ||
                      formControl.endDateTime?.touched)
                  "
                  class="text-invalid"
                  role="alert"
                >
                  <small *ngIf="formControl.endDateTime?.errors?.required">
                    End date and time is required
                  </small>
                </div>
                <div class="text-invalid" *ngIf="isInvalid">
                  {{ validationErrors["endDateTime"] }}
                </div>
              </div>
            </div>
            <div class="col-6 form-col" *ngIf="createTaskFlag">
              <label>Due Date </label>
              <div class="div">
                <p-calendar
                  styleClass="width-100-perc"
                  class="custom-calendarx"
                  formControlName="dueDate"
                  inputId="buttondisplay"
                  [showIcon]="true"
                  [showClear]="true"
                  [showButtonBar]="true"
                  inputId="buttondisplay"
                  placeholder="01-01-2024 03:43:02"
                  dateFormat="yy-mm-dd"
                  [minDate]="today"
                  [showTime]="true"
                  [showSeconds]="true"
                  (onTodayClick)="handleTodayClick()"
                ></p-calendar>
              </div>
            </div>
            <div class="col-6 form-col" *ngIf="createTaskFlag">
              <label>Contact</label>
              <p-dropdown
                styleClass="width-100-perc"
                [options]="contactData"
                formControlName="contact"
                optionLabel="name"
                optionValue="id"
                [showClear]="true"
                placeholder="Contact"
              ></p-dropdown>
            </div>
            <div class="col-6 form-col mb-2" *ngIf="createTaskFlag">
              <label>Priority<span class="text-invalid"> *</span></label>
              <p-dropdown
                styleClass="width-100-perc"
                [options]="createTaskPriority"
                formControlName="priority"
                optionLabel="key"
                optionValue="value"
                [showClear]="true"
                placeholder="Priority"
              ></p-dropdown>
              <div
                *ngIf="
                  formControl.priority?.invalid &&
                  (formControl.priority?.dirty || formControl.priority?.touched)
                "
                class="text-invalid"
                role="alert"
              >
                <small *ngIf="formControl.priority?.errors?.required">
                  Priority is required.
                </small>
              </div>
              <div class="text-invalid" *ngIf="isInvalid">
                {{ validationErrors["priority"] }}
              </div>
            </div>
            <div class="col-6 form-col" *ngIf="meetingFormFlag">
              <label>Location</label>
              <input
                pInputText
                type="text"
                class="form-control"
                placeholder="Location"
                formControlName="location"
              />
              <div
                *ngIf="
                  formControl.location?.invalid &&
                  (formControl.location?.dirty || formControl.location?.touched)
                "
                class="text-invalid"
                role="alert"
              >
                <small *ngIf="formControl.location?.errors?.maxlength">
                  Location cannot be more than 200 characters long.
                </small>
              </div>
              <div class="text-invalid" *ngIf="isInvalid">
                {{ validationErrors["location"] }}
              </div>
            </div>
            <div
              class="col-6 form-col"
              *ngIf="!meetingFormFlag && !createTaskFlag"
            >
              <label>Duration<span class="text-invalid"> *</span></label>
              <div class="col-12 d-flex justify-content-space-between">
                <div class="col-6">
                  <p-dropdown
                    styleClass="width-100-perc"
                    [options]="hoursData"
                    formControlName="callDurationHours"
                    optionLabel="key"
                    optionValue="value"
                    [showClear]="true"
                    placeholder="Hours"
                  ></p-dropdown>
                </div>
                <div class="col-6" style="padding-left: 10px">
                  <p-dropdown
                    styleClass="width-100-perc"
                    [options]="minutesData"
                    formControlName="callDurationMins"
                    optionLabel="key"
                    optionValue="value"
                    [showClear]="true"
                    placeholder="Minutes"
                  ></p-dropdown>
                </div>
              </div>
              <div
                *ngIf="
                  formControl.callDurationHours?.invalid ||
                  formControl.callDurationMins?.invalid
                "
                class="text-invalid"
                role="alert"
              >
                <small
                  *ngIf="
                    (formControl.callDurationHours?.dirty ||
                      formControl.callDurationHours?.touched) &&
                    formControl.callDurationHours?.errors?.required
                  "
                >
                  Duration Hours is required.
                </small>
                <small
                  *ngIf="
                    (formControl.callDurationMins?.dirty ||
                      formControl.callDurationMins?.touched) &&
                    formControl.callDurationMins?.errors?.required
                  "
                >
                  Duration Minutes is required.
                </small>
              </div>
              <div class="text-invalid" *ngIf="isInvalid">
                {{
                  validationErrors["callDurationHours"]
                    ? validationErrors["callDurationHours"]
                    : validationErrors["callDurationMins"]
                }}
              </div>
            </div>

            <div class="col-6 form-col mb-2" *ngIf="meetingFormFlag">
              <label>Duration</label>
              <div class="col-12">
                <p-dropdown
                  styleClass="width-100-perc"
                  [options]="minutesData"
                  formControlName="meetingDurationMins"
                  optionLabel="key"
                  optionValue="value"
                  [showClear]="true"
                  placeholder="Minutes"
                ></p-dropdown>
              </div>
            </div>

            <div class="col-6 form-col mb-2" *ngIf="!createTaskFlag">
              <div class="col-12">
                <label>Reminders</label>
              </div>
              <div class="col-12">
                <button type="submit" class="btn p-button">
                  <i class="bi bi-plus"></i>
                  Add Reminder
                </button>
              </div>
            </div>
            <div class="col-6 form-col custom-select">
              <label>Assigned To</label>
              <div>
                <p-dropdown
                  styleClass="width-100-perc"
                  [options]="userMasterData"
                  formControlName="assignedTo"
                  optionLabel="name"
                  optionValue="id"
                  [showClear]="true"
                  placeholder="--- Select User ---"
                ></p-dropdown>
              </div>
            </div>
            <div class="col-12 form-col">
              <label>Description </label>
              <textarea
                pInputText
                formControlName="description"
                rows="4"
                cols="50"
                type="text"
                class="form-control"
              ></textarea>
              <div
                *ngIf="
                  formControl.description?.invalid &&
                  (formControl.description?.dirty ||
                    formControl.description?.touched)
                "
                class="text-invalid"
                role="alert"
              >
                <small *ngIf="formControl.description?.errors?.maxlength">
                  Description cannot be more than 500 characters long.
                </small>
              </div>
              <div class="text-invalid" *ngIf="isInvalid">
                {{ validationErrors["description"] }}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="col-12 text-end">
      <button type="submit" class="btn btn-cancel" (click)="onCancel()">
        Cancel
      </button>
      <button type="submit" class="btn btn-submit" (click)="onSubmit()">
        {{ !editListData?.callAndMeetingId ? "Submit" : "Update" }}
      </button>
    </div>
  </div>
</div>

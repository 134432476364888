<div class="card">
  <div class="progress-spinner" *ngIf="loading">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <p-scrollPanel [style]="{ width: '100%', height: 'auto' }">
    <p-card class="custom-card-design">
      <p-table
        #paperDt
        [scrollable]="true"
        [scrollHeight]="'300px'"
        [value]="customers"
        [lazy]="true"
        (onLazyLoad)="loadPaperLazy($event)"
        [rows]="datatableRowsVal"
        [showCurrentPageReport]="true"
        [totalRecords]="totalRecords"
        [rowsPerPageOptions]="rowPerPage"
        styleClass="p-datatable-customers p-datatable-gridlines"
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        dataKey="id"
      >
        <ng-template pTemplate="caption">
          <div class="list-card-design">
            <p-menubar [model]="items"> </p-menubar>
            <div style="display: flex; padding-top: 6px">
              <div class="searchbox">
                <span class="p-input-icon-left p-ml-auto">
                  <i class="pi pi-search"></i>
                  <input
                    pInputText
                    type="text"
                    (input)="paperDt.filterGlobal(globalFilter, 'contains')"
                    placeholder="Search"
                    [(ngModel)]="globalFilter"
                  />
                </span>
              </div>
            </div>
          </div>
        </ng-template>
        <!-- table header -->
        <ng-template pTemplate="header">
          <tr>
            <th
              class="text-align-center"
              *ngFor="let tableHeading of cols"
              [pSortableColumn]="tableHeading?.sortField"
              [style.width]="tableHeading.colWidth"
            >
              {{ tableHeading.colName }}
              <p-sortIcon
                *ngIf="tableHeading.isSort"
                [field]="tableHeading?.sortField"
              ></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-customer let-rowIndex="rowIndex">
          <tr>
            <td class="text-align-center">
              {{ customer.srNo }}
            </td>
            <td class="text-align-center">
              {{ customer.subject }}
            </td>
            <td class="text-align-center">
              {{
                customer.statusPrimaryStr && customer.statusAlternateStr
                  ? customer.statusPrimaryStr +
                    " " +
                    customer.statusAlternateStr
                  : customer.statusPrimaryStr
                    ? customer.statusPrimaryStr
                    : customer.statusAlternateStr
                      ? customer.statusAlternateStr
                      : ""
              }}
            </td>
            <td class="text-align-center">
              {{ customer.relatedToName }}
            </td>
            <td class="text-align-center">
              {{ customer.startDateTime }}
            </td>
            <td class="text-align-center">
              {{ customer.assignedName }}
            </td>
            <td class="actionBar">
              <div class="actionBarContainer">
                <a title="View" (click)="viewLogCallById(customer)">
                  <i class="bi bi-eye-fill"></i>
                </a>
                <a title="Edit" (click)="openTask(customer)">
                  <i class="bi bi-pencil-square"></i>
                </a>
                <a title="Delete">
                  <i
                    class="bi bi-trash-fill"
                    (click)="
                      openDeleteConfirmationModal(
                        customer,
                        deleteConfirmationModal
                      )
                    "
                  ></i>
                </a>
              </div>
            </td>
          </tr>
        </ng-template>
        <!-- Empty Message -->
        <ng-template pTemplate="emptymessage" let-user>
          <tr>
            <td class="text-align-center" [attr.colspan]="cols?.length">
              {{ emptyMessage }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-card>
  </p-scrollPanel>
  <ng-template #deleteConfirmationModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">
        {{
          deleteId?.typeStr == "Call Log"
            ? "Delete Call Log"
            : deleteId?.typeStr == "Task"
              ? "Delete Task"
              : "Delete Schedule Meeting"
        }}
      </h4>
      <button
        type="button"
        class="btn-close"
        aria-describedby="modal-title"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
    <div class="modal-body">
      {{
        deleteId?.typeStr == "Call Log"
          ? "Are you sure you want to delete this Call Log"
          : deleteId?.typeStr == "Task"
            ? "Are you sure you want to delete this Task"
            : "Are you sure you want to delete this Schedule Meeting"
      }}
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-cancel" (click)="modal.dismiss()">
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-submit"
        style="margin-left: 10px !important"
        (click)="deleteCallById(); modal.close()"
      >
        Delete
      </button>
    </div>
  </ng-template>
</div>

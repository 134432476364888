import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  STATUS_PRIMARY,
  STATUS_SECONDARY,
  RELATED_TO,
  DURATION_HOURS,
  DURATION_MINUTES,
} from "src/app/helpers/constant-data/account.model";
import { API_POINTS } from "src/app/helpers/model/endpoint.model";
import { ApiService } from "src/app/helpers/services/api.service";
import { ToastService } from "src/app/helpers/services/toast.service";
import { getValidationError } from "../../../shared/utlis/common-functions";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-history-form",
  templateUrl: "./history-form.component.html",
  styleUrls: ["./history-form.component.css"],
})
export class HistoryFormComponent implements OnInit, AfterViewInit {
  @Input() meetingFormFlag = false;
  @Input() leadId: any;
  @Input() userMasterData: any;
  @Input() editListData: any;
  @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();
  @Output() formSubmitted: EventEmitter<any> = new EventEmitter();
  form!: FormGroup;
  endpoint: any = API_POINTS;
  allStatusPrimaryData: any = STATUS_PRIMARY;
  allStatusSecondaryData: any = STATUS_SECONDARY;
  allRetatedToData: any = RELATED_TO;
  hoursData: any = DURATION_HOURS;
  minutesData: any = DURATION_MINUTES;
  validationErrors: any = [];
  isInvalid: boolean = false;
  loading = false;
  today: Date = new Date();
  opportunityId: any;
  idRelatedToData: any;
  header: any;
  files: any;
  constructor(
    private http: HttpClient,
    public _fb: FormBuilder,
    private toast: ToastService,
    public api: ApiService,
    private cdr: ChangeDetectorRef,
    private activateModal: NgbActiveModal,
  ) {}
  ngOnInit() {
    this.getMasterData();
    this.getRelatedtoData(this.editListData?.relatedToPrimaryStatus);
    this.form = this._fb.group({
      contact: [],
      subject: ["", [Validators.required, Validators.maxLength(200)]],
      relatedToPrimary: ["LEAD"],
      relatedToSecondary: [this?.leadId],
      note: ["", Validators.maxLength(500)],
      assignedTo: [],
    });
  }
  getMasterData() {
    this.loading = true;
    this.api.get(this.endpoint.getRelatedToMasterData + "LEAD").subscribe(
      (result: any) => {
        if (result?.status == "SUCCESS") {
          this.idRelatedToData = result?.data;
        } else {
          this.toast.setErrorPopupMessage(result?.message);
        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.toast.setErrorPopupMessage(error?.error?.message);
      },
    );
  }
  ngAfterViewInit() {
    this.getMasterData();
    const newData = this.editListData;

    this.getRelatedtoData(newData?.relatedToPrimaryStatus);
    this.form?.patchValue({
      contact: newData?.contactAccountId,
      subject: newData?.subject,
      relatedToPrimary: newData?.relatedToPrimaryStatus,
      relatedToSecondary: newData?.relatedToId,
      note: newData?.note,
      assignedTo: newData?.assignedToId,
      // attachments: newData?.attachment,
    });
    this.cdr.detectChanges();
  }
  onUpload(event: any) {
    this.files = event?.target?.files[0];
  }
  onDownloadAttachment() {
    const fileUrl = this.editListData?.attachment;
    let downloadexte = fileUrl?.lastIndexOf(".") + 1;
    let extension = fileUrl?.slice(downloadexte);
    this.http
      .get(fileUrl, { responseType: "blob" })
      .subscribe((response: Blob) => {
        const blobUrl = window.URL.createObjectURL(response);
        const a = document.createElement("a");
        a.href = blobUrl;
        a.download = `download.${extension}`;
        a.click();
        window.URL.revokeObjectURL(blobUrl);
      });
  }
  getRelatedtoData(data: any) {
    if (data) {
      this.loading = true;
      this.api.get(this.endpoint.getRelatedToMasterData + data).subscribe(
        (result: any) => {
          if (result?.status == "SUCCESS") {
            this.idRelatedToData = result?.data;
          } else {
            this.toast.setErrorPopupMessage(result?.message);
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.toast.setErrorPopupMessage(error?.error?.message);
        },
      );
    }
  }

  get formControl(): any {
    return this.form["controls"];
  }

  isFutureDate = (date: Date) => {
    // Disable past month and year dates
    return date >= this.form.value.expectedCloseDate;
  };

  formatDuration(duration: string): string {
    if (!duration) {
      return "";
    }

    let hours = parseInt(duration, 10);
    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    return formattedHours;
  }

  onSubmit() {
    const formData = new FormData();
    if (this.files) {
      formData.append("attachment", this.files);
    }
    if (this.form.value?.contact) {
      formData.append("contactAccountId", this.form.value.contact);
    }
    if (this.form.value?.assignedTo) {
      formData.append("assignedToId", this.form.value.assignedTo);
    }
    formData.append("leadId", this.leadId);
    formData.append("relatedToPrimary", this.form.value.relatedToPrimary);
    formData.append("relatedToId", this.form.value.relatedToSecondary);
    formData.append("subject", this.form.value.subject);
    formData.append("note", this.form.value.note);

    Object.values(this.form?.controls).forEach((control: any) =>
      control?.markAsTouched(),
    );
    if (this.form?.valid) {
      if (!this.editListData?.id) {
        this.api.post(this.endpoint.createHistory, formData).subscribe(
          (res: any) => {
            if (res.status === "SUCCESS") {
              this.toast.setSuccessPopupMessage(res?.message);
              this.onCancel();
              this.activateModal.dismiss("success");
            }
          },
          (error: HttpErrorResponse) => {
            if (error.status != 400) {
              this.toast.setServerError(error.error.message);
            } else {
              this.validationErrors = [];
              this.isInvalid = true;
              this.validationErrors = getValidationError(error?.error);
            }
          },
        );
      } else {
        this.api
          .post(this.endpoint.editHistory + this.editListData?.id, formData)
          .subscribe(
            (res: any) => {
              if (res.status === "SUCCESS") {
                this.toast.setSuccessPopupMessage(res?.message);
                this.api.data.next(true);
                this.onCancel();
                this.activateModal.dismiss("success");
              }
            },
            (error: HttpErrorResponse) => {
              if (error.status != 400) {
                this.toast.setServerError(error.error.message);
              } else {
                this.validationErrors = [];
                this.isInvalid = true;
                this.validationErrors = getValidationError(error?.error);
              }
            },
          );
      }
    }
  }

  onCancel() {
    this.form.patchValue({
      subject: "",
      relatedToPrimary: "LEAD",
      contact: null,
      relatedToSecondary: this?.leadId,
      assignedTo: null,
      note: "",
      attachments: "",
    });
    this.activateModal.dismiss("success");
  }
}

import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { API_POINTS } from "src/app/helpers/model/endpoint.model";
import { ApiService } from "src/app/helpers/services/api.service";
import { ToastService } from "src/app/helpers/services/toast.service";
import { HistoryNoteViewComponent } from "../history-note-view/history-note-view.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { ViewSummeryHistoryComponent } from "../view-summery-history/view-summery-history.component";
import { HistoryFormComponent } from "../history-form/history-form.component";
import { MenuItem } from "primeng/api";
import { ActivityViewComponent } from "../../activity/activity-view/activity-view.component";
import { ActivityFormsComponent } from "../../activity/activity-forms/activity-forms.component";

@Component({
  selector: "app-history",
  templateUrl: "./history.component.html",
  styleUrls: ["./history.component.css"],
  providers: [DatePipe],
})
export class HistoryComponent implements OnInit {
  @Input() userData: any;
  @Input() leadIdParent: any;
  @Input() callAPI: any;
  @Input() leadUserData: any;
  @Output() dataUpdated = new EventEmitter<any[]>();
  viewLogData: any = {};
  historyData!: any;
  statuses!: any[];
  loadings: boolean = true;
  loading = false;
  event: any;
  event1: any;
  endpoint = API_POINTS;
  totalRecords: any = 0;
  datatableRowsVal = 10;
  rowPerPage: any = [10, 25, 50];
  resdata: any;
  globalFilter: any;
  selectedStatus: any;
  historyForm: boolean = false;
  summeryHistoryForm: boolean = false;
  scheduleMeetingForm: boolean = false;
  cols: any;
  emptyMessage: any;
  masterData: any;
  contactId: any;
  accountOptions: any[] = [];
  activityData: any[] = [];
  editData: any;
  id: any;
  deleteId: any;
  modelTitle: any;
  modelBody: any;
  items: MenuItem | undefined;
  data: any;
  constructor(
    public api: ApiService,
    private toast: ToastService,
    private modalService: NgbModal,
    public router: Router,
  ) {
    this.api.data.subscribe(() => {
      if (this.event) {
        this.loadPaperLazy(this.event);
      }
    });
  }

  ngOnInit() {
    this.items = [
      {
        label: "Create Note",
        command: () => this.openTask("Create Note or Attachment"),
        items: [
          {
            label: "View summary page",
            command: () => this.openTask("View summary page"),
          },
        ],
      },
    ];
    this.statuses = [
      {
        label: "Create Note or Attachment ",
        value: "Create Note or Attachment",
      },
      { label: "View summary page", value: "View summary page" },
    ];
    setTimeout(() => {
      this.emptyMessage = "No Records Found";
      this.cols = [
        {
          colName: "Sr.No",
          isSort: false,
          colWidth: "1%",
        },
        {
          isSort: true,
          colName: "Subject",
          colWidth: "20%",
          sortField: "subject",
        },
        {
          isSort: false,
          colName: "Status",
          colWidth: "10%",
        },
        {
          isSort: false,
          colName: "Contact",
          colWidth: "10%",
        },
        {
          isSort: false,
          colName: "Date Created",
          colWidth: "12%",
        },
        {
          isSort: false,
          colName: "Date Modified",
          colWidth: "12%",
        },
        {
          isSort: false,
          colName: "Due Date",
          colWidth: "12%",
        },
        {
          isSort: false,
          colName: "Assigned User",
          colWidth: "12%",
        },
        {
          colName: "Actions",
          isSort: false,
          colWidth: "20%",
        },
      ];
    });
    this.getMasterData(this.leadIdParent);
  }

  getDefaultDtSort(event: any) {
    if (event) {
      if (!event?.sortField || event?.sortField == "") {
        event.sortField = "";
        event.sortOrder = "-1";
      }
    }
    return event;
  }
  getMasterData(leadID: any) {
    this.api.get(this.endpoint.getHistoryMasterData + leadID).subscribe(
      (result: any) => {
        if (result.status == "SUCCESS") {
          this.masterData = result?.data;
        } else {
          this.toast.setErrorPopupMessage(result.message);
        }
      },
      (error) => {
        this.toast.setErrorPopupMessage(error?.error?.message);
      },
    );
  }
  loadPaperLazy(event: any = null) {
    this.loading = true;
    if (event !== null) {
      this.event = event;
    }
    (this.event.customFilters = {
      leadId: this?.leadIdParent,
      primaryStatus: [
        "NOT_STARTED",
        "COMPLETED",
        "PENDING_INPUT",
        "DEFERRED",
        "INBOUND",
        "OUTBOUND",
      ],
      alternateStatus: ["NOT_HELD", "HELD"],
    }),
      (this.event = this.getDefaultDtSort(this.event));
    const body = {
      event: this.event,
    };
    this.globalFilter =
      event && event.globalFilter
        ? event.globalFilter
        : this.event.globalFilter;
    this.api.post(this.endpoint.getHistory, body).subscribe(
      (result: any) => {
        this.resdata = result;
        this.historyData = this.resdata?.records;
        this.totalRecords = this.resdata?.recordsTotal;
        this.loading = false;
        this.dataUpdated.emit(this.historyData);
      },
      (error: any) => {
        this.historyData = [];
        this.loading = false;
        this.toast.setErrorPopupMessage(error?.error?.message);
      },
    );
  }
  openDeleteConfirmationModal(id: any, content: any) {
    this.modalService.open(content, {
      centered: true,
      size: "s",
      backdrop: "static",
      keyboard: false,
    });
    this.deleteId = id;
    this.modelTitle =
      this.deleteId?.specificTypeStr == "Call Log"
        ? "Delete Call Log"
        : this.deleteId?.specificTypeStr == "Task"
          ? "Delete Task"
          : this.deleteId?.specificTypeStr == "Schedule Type"
            ? "Delete Schedule Meeting"
            : "Delete History Note";
    this.modelBody =
      this.deleteId?.specificTypeStr == "Call Log"
        ? "Are you sure you want to delete this call log"
        : this.deleteId?.specificTypeStr == "Task"
          ? "Are you sure you want to delete this task"
          : this.deleteId?.specificTypeStr == "Schedule Type"
            ? "Are you sure you want to delete this schedule meeting"
            : "Are you sure you want to delete this history note";
  }
  deleteHistoryById() {
    this.loading = true;
    if (
      this.deleteId.tabletype == "LEAD_HISTORY" &&
      !this.deleteId.specificTypeStr
    ) {
      this.api
        .delete(this.endpoint.deleteHistoryByID + this.deleteId?.id)
        .subscribe(
          (result: any) => {
            this.resdata = result;
            if (this.resdata.status == "SUCCESS") {
              this.toast.setSuccessPopupMessage(result.message);
              this.loadPaperLazy();
            } else {
              this.toast.setErrorPopupMessage(this.resdata.message);
            }
            this.loading = false;
          },
          (error: any) => {
            this.loading = false;
            this.toast.setErrorPopupMessage(error?.error?.message);
          },
        );
    } else if (this.deleteId.tabletype == "LOG_CALL_AND_SCHEDULE_MEETING") {
      this.api
        .delete(this.endpoint.deletelogCallByID + this.deleteId?.id)
        .subscribe(
          (result: any) => {
            this.resdata = result;
            if (this.resdata.status == "SUCCESS") {
              this.toast.setSuccessPopupMessage(result.message);
              this.loadPaperLazy();
            } else {
              this.toast.setErrorPopupMessage(this.resdata.message);
            }
            this.loading = false;
          },
          (error: any) => {
            this.loading = false;
            this.toast.setErrorPopupMessage(error?.error?.message);
          },
        );
    }
  }

  openTask(event: any) {
    if (event == "View summary page") {
      const modalRef = this.modalService.open(ViewSummeryHistoryComponent, {
        size: "lg",
        centered: true,
      });
      modalRef.componentInstance.userMasterData = this.masterData;
      modalRef.componentInstance.leadId = this.leadIdParent;
    } else if (event == "Create Note or Attachment") {
      const modalRef = this.modalService.open(HistoryFormComponent, {
        size: "lg",
        centered: true,
      });
      modalRef.componentInstance.editListData = event;
      modalRef.componentInstance.userMasterData = this.masterData;
      modalRef.componentInstance.leadId = this.leadIdParent;
      modalRef.result.then(
        (result: any) => {},
        (reason: any) => {
          if (reason == "success") {
            this.loadPaperLazy();
          }
        },
      );
    } else {
      if (event?.tabletype == "LOG_CALL_AND_SCHEDULE_MEETING") {
        this.api
          .get(this.endpoint.viewlogCall + event.id)
          .subscribe((response: any) => {
            this.viewLogData = response.data;
            const modalRef = this.modalService.open(ActivityFormsComponent, {
              size: "lg",
              centered: true,
            });
            modalRef.componentInstance.editListData = response?.data;
            modalRef.componentInstance.leadId = this.leadIdParent;
            modalRef.componentInstance.userMasterData =
              this.masterData?.userResponsesList;
            modalRef.result.then(
              (result: any) => {},
              (reason: any) => {
                if (reason == "success") {
                  this.loadPaperLazy();
                }
              },
            );
          });
      } else if (event?.tabletype == "LEAD_HISTORY") {
        this.api
          .get(this.endpoint.viewHistoryBYyID + event.id)
          .subscribe((response: any) => {
            const modalRef = this.modalService.open(HistoryFormComponent, {
              size: "lg",
              centered: true,
            });
            modalRef.componentInstance.editListData = response?.data;
            modalRef.componentInstance.leadId = this.leadIdParent;
            modalRef.componentInstance.userMasterData = this.masterData;
            modalRef.result.then(
              (result: any) => {},
              (reason: any) => {
                if (reason == "success") {
                  this.loadPaperLazy();
                }
              },
            );
          });
      }
    }
  }
  onCloseDialog(): void {
    this.historyForm = false;
  }
  viewHistoryNoteById(user: any) {
    if (user.tabletype == "LEAD_HISTORY") {
      this.api
        .get(this.endpoint.viewHistoryBYyID + user.id)
        .subscribe((response: any) => {
          const modalRef = this.modalService.open(HistoryNoteViewComponent, {
            size: "lg",
            centered: true,
          });
          modalRef.componentInstance.header = "View Note or Attachments";
          modalRef.componentInstance.id = user;
          modalRef.componentInstance.leadId = this.leadIdParent;
          modalRef.componentInstance.historyData = response.data;

          modalRef.componentInstance.loadPaperLazyEvent.subscribe(() => {
            this.loadPaperLazy();
          });
        });
    } else {
      this.api
        .get(this.endpoint.viewlogCall + user.id)
        .subscribe((response: any) => {
          this.viewLogData = response.data;
          const modalRef = this.modalService.open(ActivityViewComponent, {
            size: "lg",
            centered: true,
          });
          modalRef.componentInstance.callLogData = response.data;
          modalRef.componentInstance.leadId = this.leadIdParent;
          modalRef.componentInstance.userMasterData = this.masterData;
          if (this.viewLogData?.type == "SCHEDULE_TYPE") {
            modalRef.componentInstance.header = "View Schedule Meeting";
          } else if (this.viewLogData?.type == "CALL_LOG") {
            modalRef.componentInstance.header = "View My Calls";
          } else {
            modalRef.componentInstance.header = "View Task";
          }
          modalRef.componentInstance.id = response.data;
        });
    }
  }
}

export const INDUSTRY = [
  { key: "Apparel", value: "APPAREL" },
  { key: "Banking", value: "BANKING" },
  { key: "Blotechnology", value: "BLOTECHNOLOGY" },
  { key: "Chemicals", value: "CHEMICALS" },
  { key: "Communications", value: "COMMUNICATIONS" },
  { key: "Construction", value: "CONSTRUCTION" },
  { key: "Consulting", value: "CONSULTING" },
  { key: "Education", value: "EDUCATION" },
  { key: "Electronics", value: "ELECTRONICS" },
  { key: "Energy", value: "ENERGY" },
  { key: "Engineering", value: "ENGINEERING" },
  { key: "Environment", value: "ENTERTAINMENT" },
  { key: "Finance", value: "FINANCE" },
  { key: "Government", value: "GOVERNMENT" },
  { key: "Healthcare", value: "HEALTHCARE" },
  { key: "Hospitality", value: "HOSPITALITY" },
  { key: "Insurance", value: "INSURANCE" },
  { key: "Machinery", value: "MACHINERY" },
  { key: "Media", value: "MEDIA" },
  { key: "Not or Profit", value: "NOT_FOR_PROFIT" },
  { key: "Recreation", value: "RECREATION" },
  { key: "Retail", value: "RETAIL" },
  { key: "Shipping", value: "SHIPPING" },
  { key: "Technology", value: "TECHNOLOGY" },
  { key: "Telecommunications", value: "TELECOMMUNICATIONS" },
  { key: "Transportation", value: "TRANSPORTATION" },
  { key: "Utilities", value: "UTILITIES" },
  { key: "Other", value: "OTHER" },
];

export const TYPE = [
  { key: "Apparel", value: "ANALYST" },
  { key: "Banking", value: "COMPETITOR" },
  { key: "Blotechnology", value: "INTEGRATOR" },
  { key: "Chemicals", value: "INVESTOR" },
  { key: "Communications", value: "PARTNER" },
  { key: "Construction", value: "PRESS" },
  { key: "Consulting", value: "PROSPECT" },
  { key: "Education", value: "RESELLER" },
  { key: "Electronics", value: "OTHER" },
];

export const leadSource = [
  { key: "Cold Call", value: "COLD CALL" },
  { key: "Existing Customer", value: "EXISTING CUSTOMER" },
  { key: "Self Generated", value: "SELF GENERATED" },
  { key: "Employee", value: "EMPLOYEE" },
  { key: "Partner", value: "PARTNER" },
  { key: "Public Relations", value: "PUBLIC_RELATIONS" },
  { key: "Direct Mail", value: "DIRECT_MAIL" },
  { key: "Conference", value: "CONFERENCE" },
  { key: "Trade Show", value: "TRADE_SHOW" },
  { key: "Web Site", value: "WEB_SITE" },
  { key: "Word of mouth", value: "WORD_OF_MOUTH" },
  { key: "Compaign", value: "COMPAIGN" },
  { key: "Email", value: "EMAIL" },
  { key: "Other", value: "OTHER" },
];

export const STATUS = [
  { key: "New", value: "NEW" },
  { key: "Assigned", value: "ASSIGNED" },
  { key: "In Process", value: "IN_PROCESS" },
  { key: "Converted", value: "CONVERTED" },
  { key: "Recycle", value: "RECYCLED" },
  { key: "dEAD", value: "DEAD" },
];

export const LEAD_SOURCE = [
  { key: "Cold Call", value: "COLD_CALL" },
  { key: "Existing Customer", value: "EXISTING_CUSTOMER" },
  { key: "Self Generated", value: "SELF_GENERATED" },
  { key: "Employee", value: "EMPLOYEE" },
  { key: "Partner", value: "PARTNER" },
  { key: "Public Relations", value: "PUBLIC_RELATIONS" },
  { key: "Direct Mail", value: "DIRECT_MAIL" },
  { key: "Conference", value: "CONFERENCE" },
  { key: "Trade Show", value: "TRADE_SHOW" },
  { key: "Web Site", value: "WEB_SITE" },
  { key: "Word of mouth", value: "WORD_OF_MOUTH" },
  { key: "Compaign", value: "COMPAIGN" },
  { key: "Email", value: "EMAIL" },
  { key: "Other", value: "OTHER" },
];

export const CURRENCY = [
  { key: "US Dollars: $", value: "US_DOLLARS" },
  { key: "Danish Kronen: KR", value: "DANISH_KRONEN_KR" },
  { key: "Rupee: Rs", value: "RUPEE_RS" },
  { key: "Swedish Kronen kr", value: "SWEDISH_KRONEN_KR" },
  { key: "Prospecting", value: "PROSPECTING" },
];

export const OPPORTUNITY_TYPE = [
  { key: "Existing Business", value: "EXISTING_BUSINESS" },
  { key: "New Business", value: "NEW_BUSINESS" },
];

export const SALES_STAGE = [
  { key: "Prospecting", value: "PROSPECTING" },
  { key: "Qualification", value: "QUALIFICATION" },
  { key: "Needs Analysis", value: "NEEDS_ANALYSIS" },
  { key: "Value Proposition", value: "VALUE_PROPOSITION" },
  { key: "Identifying Decision Makers", value: "IDENTIFYING_DECISION_MARKERS" },
  { key: "Perception Analysis", value: "PERCEPTION_ANALYSIS" },
  { key: "Proposal/Price Quote", value: "PROPOSAL_PRICE_QUOTE" },
  { key: "Negotiation/Review", value: "NEGOTIATION_REVIEW" },
  { key: "Closed Won", value: "CLOSED_WON" },
  { key: "Closed Lost", value: "CLOSED_LOST" },
];

export const STATUS_PRIMARY = [
  { key: "Inbound", value: "INBOUND" },
  { key: "Outbound", value: "OUTBOUND" },
];

export const STATUS_SECONDARY = [
  { key: "Planned", value: "PLANNED" },
  { key: "Held", value: "HELD" },
  { key: "Not held", value: "NOT_HELD" },
];

export const TASK_STATUS = [
  { key: "Not Started", value: "NOT_STARTED" },
  { key: "In Progress", value: "IN_PROGRESS" },
  { key: "Completed", value: "COMPLETED" },
  { key: "Pending Input", value: "PENDING_INPUT" },
  { key: "Deferred", value: "DEFERRED" },
];

export const TASK_PRIORITY = [
  { key: "High", value: "HIGH" },
  { key: "Medium", value: "MEDIUM" },
  { key: "Low", value: "LOW" },
];

export const RELATED_TO = [
  { key: "Account", value: "ACCOUNT" },
  { key: "Contact", value: "CONTACT" },
  // { key: "Task", value: "TASK" },
  { key: "Opportunity", value: "OPPORTUNITY" },
  // { key: "Bug", value: "BUG" },
  // { key: "Case", value: "CASE" },
  { key: "Lead", value: "LEAD" },
  // { key: "Project", value: "PROJECT" },
  // { key: "Project task", value: "PROJECT_TASK" },
  // { key: "Target", value: "TARGET" },
];

export const DURATION_HOURS = [
  { key: "0", value: "0" },
  { key: "1", value: "1" },
  { key: "2", value: "2" },
  { key: "3", value: "3" },
  { key: "4", value: "4" },
  { key: "5", value: "5" },
  { key: "6", value: "6" },
  { key: "7", value: "7" },
  { key: "8", value: "8" },
  { key: "9", value: "9" },
  { key: "10", value: "10" },
  { key: "11", value: "11" },
  { key: "12", value: "12" },
  { key: "13", value: "13" },
  { key: "14", value: "14" },
  { key: "15", value: "15" },
  { key: "16", value: "16" },
  { key: "17", value: "17" },
  { key: "18", value: "18" },
  { key: "19", value: "19" },
  { key: "20", value: "20" },
  { key: "21", value: "21" },
  { key: "22", value: "22" },
  { key: "23", value: "23" },
  { key: "24", value: "24" },
];
export const DURATION_MINUTES = [
  { key: "00", value: "00" },
  { key: "15", value: "15" },
  { key: "30", value: "30" },
  { key: "45", value: "45" },
];

<div class="modal-dialog-scrollable">
  <div class="modal-body" style="height: 500px;">
    <!-- <p-scrollPanel [style]="{ width: '100%', height: 'auto' }"> -->
    <p-tabView styleClass="no-dropdown-tabs" [(activeIndex)]="activeTabIndex">
      <p-tabPanel header="Overview" [selected]="true">
        <div class="container-fluid h-100">
          <div class="col-12">
            <div class="custom-view-account">
              <div class="row mb-3">
                <div class="d-flex col-6 mb-3">
                  <div class="col-3">
                    <label>First Name: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData?.firstName }}</span>
                    <span>{{ userData?.opportunityName }}</span>
                  </div>
                </div>
                <div class="d-flex col-6 mb-3">
                  <div class="col-3">
                    <label>Last Name: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData?.lastName }}</span>
                  </div>
                </div>
                <div class="d-flex col-6 mb-3">
                  <div class="col-3">
                    <label>Office Phone: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData?.officePhone }}</span>
                  </div>
                </div>
                <div class="d-flex col-6 mb-3">
                  <div class="col-3">
                    <label>Title: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData?.title }}</span>
                  </div>
                </div>
                <div class="d-flex col-6 mb-3">
                  <div class="col-3">
                    <label>Mobile: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData?.mobile }}</span>
                  </div>
                </div>
                <div class="d-flex col-6 mb-3">
                  <div class="col-3">
                    <label>Department: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData?.department }}</span>
                  </div>
                </div>
                <div class="d-flex col-6 mb-3">
                  <div class="col-3">
                    <label>Fax: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData?.fax }}</span>
                  </div>
                </div>
                <div class="d-flex col-6 mb-3">
                  <div class="col-3">
                    <label>Account Name: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData?.accountName }}</span>
                  </div>
                </div>
                <div class="d-flex col-6 mb-3">
                  <div class="col-3">
                    <label>Website: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData?.website }}</span>
                  </div>
                </div>
                <div class="d-flex col-6 mb-3">
                  <div class="col-3">
                    <label>LinkedIn Url: </label>
                  </div>
                  <div class="col-9">
                    <a href="{{userData?.linkedInUrl}}" target="_blank"
                      >{{ userData?.linkedInUrl }}</a
                    >
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <div
                    class="col-12 mb-3"
                    style="justify-content: center !important;"
                  >
                    <h2 class="design-color">Primary Address</h2>
                  </div>
                  <div>
                    <div class="d-flex col-12 mb-3">
                      <div class="col-3">
                        <label>Address: </label>
                      </div>
                      <div class="col-9">
                        <span>{{ userData?.address?.[0]?.addressDetail }}</span>
                      </div>
                    </div>
                    <div class="d-flex col-12 mb-3">
                      <div class="col-3">
                        <label>City: </label>
                      </div>
                      <div class="col-9">
                        <span>{{ userData?.address?.[0]?.city }}</span>
                      </div>
                    </div>
                    <div class="d-flex col-12 mb-3">
                      <div class="col-3">
                        <label>State: </label>
                      </div>
                      <div class="col-9">
                        <span>{{ userData?.address?.[0]?.state }}</span>
                      </div>
                    </div>
                    <div class="d-flex col-12 mb-3">
                      <div class="col-3">
                        <label>Postal Code: </label>
                      </div>
                      <div class="col-9">
                        <span>{{ userData?.address?.[0]?.postalCode }}</span>
                      </div>
                    </div>
                    <div class="d-flex col-12 mb-3">
                      <div class="col-3">
                        <label>Country: </label>
                      </div>
                      <div class="col-9">
                        <span>{{ userData?.address?.[0]?.country }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <div
                    class="col-12 mb-3"
                    style="justify-content: center !important;"
                  >
                    <h2 class="design-color">Other Address</h2>
                  </div>
                  <div>
                    <div class="d-flex col-12 mb-3">
                      <div class="col-3">
                        <label>Address: </label>
                      </div>
                      <div class="col-9">
                        <span>{{ userData?.address?.[1]?.addressDetail }}</span>
                      </div>
                    </div>
                    <div class="d-flex col-12 mb-3">
                      <div class="col-3">
                        <label>City: </label>
                      </div>
                      <div class="col-9">
                        <span>{{ userData?.address?.[1]?.city }}</span>
                      </div>
                    </div>
                    <div class="d-flex col-12 mb-3">
                      <div class="col-3">
                        <label>State: </label>
                      </div>
                      <div class="col-9">
                        <span>{{ userData?.address?.[1]?.state }}</span>
                      </div>
                    </div>
                    <div class="d-flex col-12 mb-3">
                      <div class="col-3">
                        <label>Postal Code: </label>
                      </div>
                      <div class="col-9">
                        <span>{{ userData?.address?.[1]?.postalCode }}</span>
                      </div>
                    </div>
                    <div class="d-flex col-12 mb-3">
                      <div class="col-3">
                        <label>Country: </label>
                      </div>
                      <div class="col-9">
                        <span>{{ userData?.address?.[1]?.country }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex col-6 mb-3">
                  <div class="col-3">
                    <label>Description: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData?.description }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="More Information">
        <div class="container-fluid h-100">
          <div class="col-12">
            <div class="custom-view-account">
              <div class="row mb-3">
                <div class="d-flex col-6 mb-3">
                  <div class="col-3">
                    <label>Status: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData?.statusStr }}</span>
                  </div>
                </div>
                <div class="d-flex col-6 mb-3">
                  <div class="col-3">
                    <label>Lead Source: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData?.leadSourceStr }}</span>
                  </div>
                </div>
                <div class="d-flex col-6 mb-3">
                  <div class="col-3">
                    <label>Status Desciption: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData?.statusDescription }}</span>
                  </div>
                </div>
                <div class="d-flex col-6 mb-3">
                  <div class="col-3">
                    <label>Lead Source Description: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData?.leadSourceDescription }}</span>
                  </div>
                </div>
                <div class="d-flex col-6 mb-3">
                  <div class="col-3">
                    <label>Opportunity Amount: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData?.opportunityAmount }}</span>
                  </div>
                </div>
                <div class="d-flex col-6 mb-3">
                  <div class="col-3">
                    <label>Referred By: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData?.referredBy }}</span>
                  </div>
                </div>
                <div class="d-flex col-6 mb-3">
                  <div class="col-3">
                    <label>Campaign </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData?.campaign }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <!-- For Activity and History Accordian -->
              <p-accordion
                [multiple]="true"
                [activeIndex]="[0, 1]"
                *ngIf="!userData.opportunityName && activeTabIndex == 1"
              >
                <p-accordionTab header="Activities">
                  <app-activity
                    [callAPI]="callAPI"
                    (dataUpdated)="updateFirstComponentData($event)"
                    [leadIdParent]="userData?.leadId"
                  ></app-activity>
                </p-accordionTab>
                <p-accordionTab header="History">
                  <app-history
                    [leadUserData]="leadUsers"
                    (dataUpdated)="updateSecondComponentData($event)"
                    [callAPI]="callAPI"
                    [leadIdParent]="userData?.leadId"
                  ></app-history>
                </p-accordionTab>
              </p-accordion>
            </div>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Other">
        <div class="container-fluid h-100">
          <div class="col-12">
            <div class="custom-view-account">
              <div class="row mb-3">
                <div class="d-flex col-6 mb-3">
                  <div class="col-3">
                    <label>Assigned To: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData?.assignedName }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
    <!-- </p-scrollPanel> -->
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-cancel" (click)="closeModel()">
      Close
    </button>
  </div>
</div>
<!-- </p-scrollPanel> -->

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { API_POINTS } from "src/app/helpers/model/endpoint.model";
import { ApiService } from "src/app/helpers/services/api.service";
import { ToastService } from "src/app/helpers/services/toast.service";
import { INDUSTRY, TYPE } from "src/app/helpers/constant-data/account.model";
import {
  cleanArray,
  getValidationError,
} from "src/app/shared/utlis/common-functions";
import { HttpErrorResponse } from "@angular/common/http";

import { Location } from "@angular/common";

@Component({
  selector: "app-account-create",
  templateUrl: "./account-create.component.html",
  styleUrls: ["./account-create.component.css"],
})
export class AccountCreateComponent {
  @Input() leadAddAccount: boolean | undefined;
  @Output() loadingOrg = new EventEmitter<any>();
  @Output() submitAccount = new EventEmitter<any>();
  /* variables */
  form!: FormGroup;
  allTypeData: any = TYPE;
  endpoint: any = API_POINTS;
  allIndustryData: any = INDUSTRY;
  masterData: any[] = [];
  validationErrors: any = [];
  isInvalid: boolean = false;
  loading: boolean = false;
  accountId: any;

  constructor(
    public _fb: FormBuilder,
    public api: ApiService,
    private router: Router,
    private toast: ToastService,
    private activate: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit() {
    this.form = this._fb.group({
      organizationId: [1],
      name: ["", [Validators.required, Validators.maxLength(200)]],
      website: ["", [Validators.maxLength(500)]],
      fax: ["", [Validators.maxLength(200)]],
      officePhone: ["", [Validators.maxLength(15), Validators.min(10)]],
      emailaddress: this._fb.array([this.createEmail()]),
      description: ["", [Validators.maxLength(500)]],
      assignedTo: [""],
      title: ["", [Validators.maxLength(200)]],
      industry: [""],
      totalRevanu: ["", [Validators.maxLength(500)]],
      employee: ["", [Validators.maxLength(200)]],
      memberOf: ["", [Validators.maxLength(500)]],
      campaign: ["", [Validators.maxLength(500)]],
      type: ["", [Validators.maxLength(200)]],
      billingAddress: this.createBillingAddress(),
      shippingAddress: this.createShippingAddress(),
      copyAddress: false,
    });

    this.form.get("billingAddress")!.valueChanges.subscribe(() => {
      this.updateCopyCheckbox();
    });

    this.form.get("shippingAddress")!.valueChanges.subscribe(() => {
      this.updateCopyCheckbox();
    });

    this.form.get("copyAddress")!.valueChanges.subscribe((value) => {
      if (value) {
        this.form
          .get("shippingAddress")!
          .setValue(this.form.get("billingAddress")!.value);
      } else {
        this.form.get("shippingAddress")!.reset();
      }
    });
    this.activate.params.subscribe((param: any) => {
      this.accountId = param["id"];
    });
    if (this.accountId && !this.leadAddAccount) {
      this.getAccountData();
    }
    this.getMasterDataAssignTo();
  }

  getMasterDataAssignTo() {
    return this.api
      .get(this.endpoint.getAccountsMasterData)
      .subscribe((res: any) => {
        this.masterData = res?.data?.userList;
      });
  }

  get f(): any {
    return this.form["controls"];
  }

  getAccountData() {
    this.api
      .get(this.endpoint.viewAccount + this.accountId)
      .subscribe((res: any) => {
        const data: any = res?.data;
        // For basic details
        this.form.patchValue({
          name: data.name,
          website: data?.website,
          fax: data?.fax,
          description: data?.description,
          assignedTo: data?.assignedTo,
          officePhone: data?.officePhone,
          industry: data?.industry,
          type: data?.type,
          totalRevanu: data?.totalRevanu,
          employee: data?.employee,
          memberOf: data?.memberOf,
          campaign: data?.campaign,
          copyAddress: data?.copyAddress,
        });

        const formAaddress = this.form.get("emailaddress") as FormArray;
        const newEmails: any = [data?.emailAddress];
        const newEmailsArray = newEmails[0]
          .split(",")
          .map((email: any) => email.trim());
        newEmailsArray.forEach((email: any) => {
          formAaddress.push(
            this._fb.group({
              email: [
                email,
                [
                  Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$"),
                  Validators.maxLength(200),
                ],
              ],
            })
          );
        });
        this.emailaddress.removeAt(0);

        const newAdress = data?.accountAddress;
        const formbiliing: any = this.form.get("billingAddress") as FormArray;
        formbiliing.patchValue({
          city: newAdress[0].city,
          street: newAdress[0].street,
          state: newAdress[0].state,
          country: newAdress[0].country,
          postalCode: newAdress[0].postalCode,
        });

        const newAdressBilling = data?.accountAddress;
        const formsipping: any = this.form.get("shippingAddress") as FormArray;
        formsipping.patchValue({
          city: newAdressBilling[1].city,
          street: newAdressBilling[1].street,
          state: newAdressBilling[1].state,
          country: newAdressBilling[1].country,
          postalCode: newAdressBilling[1].postalCode,
        });
      });
  }

  get billingAddress(): FormArray {
    return this.form.get("billingAddress") as FormArray;
  }

  get shippingAddress(): FormArray {
    return this.form.get("shippingAddress") as FormArray;
  }

  get copyAddress() {
    return this.form.get("copyAddress");
  }

  get emailaddress(): FormArray {
    return this.form.get("emailaddress") as FormArray;
  }

  createEmail() {
    return this._fb.group({
      email: [
        null,
        [
          Validators.maxLength(200),
          Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$"),
        ],
      ],
    });
  }
  addEmail() {
    this.emailaddress.push(this.createEmail());
  }
  removeEmail(index: number) {
    this.emailaddress.removeAt(index);
  }

  createBillingAddress() {
    return this._fb.group({
      street: ["", [Validators.maxLength(200)]],
      state: ["", [Validators.maxLength(200)]],
      city: ["", [Validators.maxLength(200)]],
      postalCode: ["", [Validators.maxLength(200)]],
      country: ["", [Validators.maxLength(200)]],
      accountAddressType: ["BILLING_ADDRESS"],
    });
  }

  createShippingAddress() {
    return this._fb.group({
      street: ["", [Validators.maxLength(200)]],
      state: ["", [Validators.maxLength(200)]],
      city: ["", [Validators.maxLength(200)]],
      postalCode: ["", [Validators.maxLength(200)]],
      country: ["", [Validators.maxLength(200)]],
      accountAddressType: ["SHIPPING_ADDRESS"],
    });
  }

  get accountAddress(): FormArray {
    return this.form.get("accountAddress") as FormArray;
  }

  onCancel() {
    this.form.reset();
    return window.history.back();
  }
  setLoading(val: any): void {
    this.loadingOrg.emit(val);
  }
  onSubmit() {
    let obj = {
      organizationId: 1,
      name: this.form.value.name,
      website: this.form.value.website,
      fax: this.form.value.fax,
      officePhone: this.form.value.officePhone,
      emailAddress: cleanArray(
        this.emailaddress.value.map((obj: any) => obj.email)
      ),
      description: this.form.value.description,
      assignedTo: this.form.value.assignedTo,
      title: this.form.value.title,
      industry: this.form.value.industry,
      totalRevanu: this.form.value.totalRevanu,
      employee: this.form.value.employee,
      memberOf: this.form.value.memberOf,
      campaign: this.form.value.campaign,
      type: this.form.value.type,
      accountAddress: [
        this.form.value.billingAddress,
        this.form.value.shippingAddress,
      ],
      copyAddress: this.form.value.copyAddress,
    };
    Object.values(this.form?.controls).forEach((control: any) =>
      control?.markAsTouched()
    );
    if (this.form.valid) {
      if (this?.accountId && !this.leadAddAccount) {
        let editobj = {
          organizationId: 1,
          name: this.form.value.name,
          website: this.form.value.website,
          fax: this.form.value.fax,
          officePhone: this.form.value.officePhone,
          emailAddress: cleanArray(
            this.emailaddress.value.map((obj: any) => obj.email)
          ),
          description: this.form.value.description,
          assignedTo: this.form.value.assignedTo,
          title: this.form.value.title,
          industry: this.form.value.industry,
          totalRevanu: this.form.value.totalRevanu,
          employee: this.form.value.employee,
          memberOf: this.form.value.memberOf,
          campaign: this.form.value.campaign,
          type: this.form.value.type,
          accountAddress: [
            this.form.value.billingAddress,
            this.form.value.shippingAddress,
          ],
          copyAddress: this.form.value.copyAddress,
        };
        this.api
          .put(this.endpoint.editAccount + this?.accountId, editobj)
          .subscribe(
            (res: any) => {
              if (res.status === "SUCCESS") {
                this.toast.setSuccessPopupMessage(res?.message);
                this.form.reset();
                if (this.router.url === "/lead/add") {
                  this.router.navigate(["lead/add"]);
                } else {
                  this.router.navigate(["account"]);
                }
              }
            },
            (error: any) => {
              this.toast.setServerError(error.message);
            }
          );
      } else {
        this.api.post(this.endpoint.addAccount, obj).subscribe(
          (res: any) => {
            if (res.status === "SUCCESS") {
              if (!this.leadAddAccount) {
                this.toast.setSuccessPopupMessage(res?.message);
                this.form.reset();
                if (this.router.url === "/lead/add") {
                  this.router.navigate(["lead/add"]);
                } else {
                  this.router.navigate(["account"]);
                }
              } else {
                this.setLoading(res.data);
              }
            }
          },
          (error: HttpErrorResponse) => {
            if (error.status != 400) {
              this.toast.setServerError(error.error.message);
            } else {
              this.validationErrors = [];
              this.isInvalid = true;
              this.validationErrors = getValidationError(error?.error);
            }
          }
        );
      }
    }
    const name = this.form.get("name")?.value;
    this.submitAccount.emit(name);
  }

  // copy address
  updateCopyCheckbox() {
    const primaryAddress = this.form.get("billingAddress")!.value;
    const otherAddress = this.form.get("shippingAddress")!.value;
    const copyAddressControl = this.form.get("copyAddress");

    if (
      primaryAddress.street === otherAddress.street &&
      primaryAddress.state === otherAddress.state &&
      primaryAddress.city === otherAddress.city &&
      primaryAddress.postalCode === otherAddress.postalCode &&
      primaryAddress.country === otherAddress.country
    ) {
      copyAddressControl!.setValue(true, { emitEvent: false });
    } else {
      copyAddressControl!.setValue(false, { emitEvent: false });
    }
  }

  copyAllAddress() {
    const primaryAddress = this.form.get("billingAddress")!.value;
    this.form.get("shippingAddress")!.patchValue(primaryAddress);
  }

  onCopyAddressChange() {
    if (this.form.get("copyAddress")!.value) {
      this.copyAllAddress();
    }
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { API_POINTS } from "src/app/helpers/model/endpoint.model";
import { ToastService } from "src/app/helpers/services/toast.service";
import { ApiService } from "src/app/helpers/services/api.service";
import { ActivityFormsComponent } from "../activity-forms/activity-forms.component";

@Component({
  selector: "app-activity-view",
  templateUrl: "./activity-view.component.html",
  styleUrls: ["./activity-view.component.css"],
})
export class ActivityViewComponent implements OnInit {
  @Input() callLogData: any;
  @Input() leadId: any;
  @Input() id: any;
  @Input() header: any;
  @Input() closeDialogEvent: (() => void) | undefined;
  @Input() formSubmittedEvent: (() => void) | undefined;
  @Output() loadPaperLazyEvent = new EventEmitter<void>();
  endpoint = API_POINTS;
  masterData: any;
  constructor(
    private activeModal: NgbActiveModal,
    private modal: NgbModal,
    private toast: ToastService,
    public api: ApiService
  ) {}
  ngOnInit() {
    this.getMasterData(this.leadId);
  }
  getMasterData(leadID: any) {
    this.api.get(this.endpoint.getlogcallMasterData + leadID).subscribe(
      (result: any) => {
        if (result.status == "SUCCESS") {
          this.masterData = result?.data?.assignedToList;
        } else {
          this.toast.setErrorPopupMessage(result.message);
        }
      },
      (error) => {
        this.toast.setErrorPopupMessage(error?.error?.message);
      }
    );
  }
  edit(user: any) {
    this.api
      .get(this.endpoint.viewlogCall + user.callAndMeetingId)
      .subscribe((response: any) => {
        const modalRef = this.modal.open(ActivityFormsComponent, {
          size: "lg",
          centered: true,
        });
        modalRef.componentInstance.editListData = response?.data;
        modalRef.componentInstance.leadId = this.leadId;
        modalRef.componentInstance.userMasterData = this.masterData;
        modalRef.result.then(
          (result) => {},
          (reason: any) => {
            this.loadPaperLazyEvent.emit();
            this.closeModel(reason);
          }
        );
      });
  }
  closeModel(reason?: any) {
    this.activeModal.dismiss(reason);
  }
}

import { Component } from "@angular/core";
import { ApiService } from "src/app/helpers/services/api.service";

@Component({
  selector: "app-invalid-token",
  templateUrl: "./invalid-token.component.html",
  styleUrls: ["./invalid-token.component.css"],
})
export class InvalidTokenComponent {
  token: string | null = localStorage.getItem("access-token");

  constructor(public api_service: ApiService) {}
}

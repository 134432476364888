import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { API_POINTS } from "src/app/helpers/model/endpoint.model";
import { ApiService } from "src/app/helpers/services/api.service";
import { ToastService } from "src/app/helpers/services/toast.service";
import { AccountCreateComponent } from "../../account/account-create/account-create.component";
import {
  cleanArray,
  getValidationError,
} from "src/app/shared/utlis/common-functions";
import {
  LEAD_SOURCE,
  STATUS,
} from "src/app/helpers/constant-data/account.model";
import { HttpErrorResponse } from "@angular/common/http";
import { ConfirmationService, MessageService } from "primeng/api";

@Component({
  selector: "app-leads-create",
  templateUrl: "./leads-create.component.html",
  styleUrls: ["./leads-create.component.css"],
})
export class LeadsCreateComponent {
  @ViewChild("child") childComponent!: AccountCreateComponent;

  form!: FormGroup;
  leadId: any;
  endpoint: any = API_POINTS;
  allStatusData: any = STATUS;
  allLeadSourceData: any = LEAD_SOURCE;
  loading = false;
  accountOptions: any[] = [];
  validationErrors: any = [];
  userDetailOptions: any[] = [];
  isInvalid: boolean = false;
  addAccountPopup = false;
  addFromPopup = true;
  visible: boolean = false;
  selectedAccount: any;
  userForm: FormGroup;

  constructor(
    public _fb: FormBuilder,
    public api: ApiService,
    private router: Router,
    private toast: ToastService,
    private cdref: ChangeDetectorRef,
    private activate: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
    this.userForm = this._fb.group({
      user: [],
    });
  }

  ngOnInit() {
    this.form = this._fb.group({
      organizationId: [1],
      firstName: ["", [Validators.required, Validators.maxLength(200)]],
      lastName: ["", [Validators.maxLength(200)]],
      title: ["", [Validators.maxLength(200)]],
      department: ["", [Validators.maxLength(200)]],
      accountName: ["", [Validators.required, Validators.maxLength(200)]],
      officePhone: ["", [Validators.maxLength(200)]],
      fax: ["", [Validators.maxLength(200)]],
      website: ["", [Validators.maxLength(200)]],
      linkedInUrl: ["", [Validators.maxLength(200)]],
      mobile: this._fb.array([this.createMobile()]),
      emailaddress: this._fb.array([this.createEmail()]),
      description: ["", [Validators.maxLength(500)]],
      status: ["", [Validators.maxLength(200)]],
      statusDescription: ["", [Validators.maxLength(500)]],
      opportunityAmount: ["", [Validators.maxLength(200)]],
      leadSource: ["", [Validators.maxLength(200)]],
      leadSourceDescription: ["", [Validators.maxLength(500)]],
      referredBy: ["", [Validators.maxLength(200)]],
      assignedTo: ["", [Validators.maxLength(200)]],
      campaign: ["", [Validators.maxLength(200)]],
      primaryAddress: this.createPrimaryAddress(),
      otherAddress: this.createOtherAddress(),
      copyAddress: false,
    });
    this.form.get("primaryAddress")!.valueChanges.subscribe(() => {
      this.updateCopyCheckbox();
    });

    this.form.get("otherAddress")!.valueChanges.subscribe(() => {
      this.updateCopyCheckbox();
    });

    this.form.get("copyAddress")!.valueChanges.subscribe((value) => {
      if (value) {
        const {
          city,
          state,
          country,
          postalCode,
          addressDetail,
        } = this.form.get("primaryAddress")!.value;
        const formOtherAddress: any = this.form.get(
          "otherAddress"
        ) as FormArray;
        formOtherAddress.patchValue({
          city: city,
          state: state,
          country: country,
          postalCode: postalCode,
          addressDetail: addressDetail,
        });
      } else {
        this.form.get("otherAddress")!.reset();
        const formOtherAddress: any = this.form.get(
          "otherAddress"
        ) as FormArray;
        formOtherAddress.patchValue({
          addressType: "OTHER_ADDRESS",
        });
      }
    });
    this.activate.params.subscribe((param: any) => {
      this.leadId = param["id"];
    });
    if (this.leadId) {
      this.getLeadData();
    }
    this.getMasterData();
  }

  getLeadData() {
    this.api.get(this.endpoint.viewLead + this.leadId).subscribe((res: any) => {
      const data: any = res?.data;
      this.form.patchValue({
        firstName: data?.firstName,
        lastName: data?.lastName,
        title: data?.title,
        department: data?.department,
        accountName: data?.accountId,
        officePhone: data?.officePhone,
        fax: data?.fax,
        website: data?.website,
        linkedInUrl: data?.linkedInUrl,
        description: data?.description,
        status: data?.status,
        statusDescription: data?.statusDescription,
        opportunityAmount: data?.opportunityAmount,
        leadSource: data?.leadSource,
        leadSourceDescription: data?.leadSourceDescription,
        referredBy: data?.referredBy,
        assignedTo: data?.assignedTo,
        campaign: data?.campaign,
        copyAddress: data?.copyAddress,
      });

      const formAaddress = this.form.get("emailaddress") as FormArray;
      const newEmails: any = [data?.email];
      const newEmailsArray = newEmails[0]
        .split(",")
        .map((email: any) => email.trim());
      newEmailsArray.forEach((email: any) => {
        formAaddress.push(
          this._fb.group({
            email: [email, [Validators.email]],
          })
        );
      });
      this.emailaddress.removeAt(0);

      const formMobile = this.form.get("mobile") as FormArray;
      const newMobiles: any = [data?.mobile];
      const newMobilesArray = newMobiles[0]
        .split(",")
        .map((mobile: any) => mobile.trim());
      newMobilesArray.forEach((mobile: any) => {
        formMobile.push(
          this._fb.group({
            mobile: [mobile, [Validators.maxLength(15)]],
          })
        );
      });
      this.mobile.removeAt(0);

      const primaryAddress = data?.address;
      const formPrimaryAddress: any = this.form.get(
        "primaryAddress"
      ) as FormArray;
      formPrimaryAddress.patchValue({
        addressDetail: primaryAddress?.[0]?.addressDetail
          ? primaryAddress?.[0]?.addressDetail
          : null,
        city: primaryAddress?.[0]?.city ? primaryAddress?.[0]?.city : null,
        state: primaryAddress?.[0]?.state ? primaryAddress?.[0]?.state : null,
        country: primaryAddress?.[0]?.country
          ? primaryAddress?.[0]?.country
          : null,
        postalCode: primaryAddress?.[0]?.postalCode
          ? primaryAddress?.[0]?.postalCode
          : null,
      });

      const otherAddress = data?.address;
      const formOtherAddress: any = this.form.get("otherAddress") as FormArray;
      formOtherAddress.patchValue({
        addressDetail: otherAddress?.[1]?.addressDetail
          ? otherAddress?.[1]?.addressDetail
          : null,
        city: otherAddress?.[1]?.city ? otherAddress?.[1]?.city : null,
        state: otherAddress?.[1]?.state ? otherAddress?.[1]?.state : null,
        country: otherAddress?.[1]?.country ? otherAddress?.[1]?.country : null,
        postalCode: otherAddress?.[1]?.postalCode
          ? otherAddress?.[1]?.postalCode
          : null,
      });
    });
  }

  getMasterData() {
    this.loading = true;
    this.api.get(this.endpoint.getContactMasterData).subscribe(
      (result: any) => {
        if (result.status == "SUCCESS") {
          this.accountOptions = result?.data?.accountList;
          this.userDetailOptions = result?.data?.userList;
        } else {
          this.toast.setErrorPopupMessage(result.message);
        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.toast.setErrorPopupMessage(error?.error?.message);
      }
    );
  }
  get f() {
    return this.form.controls;
  }
  get formAltaControls(): any {
    return this.form["controls"];
  }
  get primaryAddress(): FormArray {
    return this.form.get("primaryAddress") as FormArray;
  }

  get otherAddress(): FormArray {
    return this.form.get("otherAddress") as FormArray;
  }

  get copyAddress() {
    return this.form.get("copyAddress");
  }

  get emailaddress(): FormArray {
    return this.form.get("emailaddress") as FormArray;
  }
  get mobile(): FormArray {
    return this.form.get("mobile") as FormArray;
  }

  createEmail() {
    return this._fb.group({
      email: [null, [Validators.email]],
    });
  }
  createMobile() {
    return this._fb.group({
      mobile: [null, [Validators.maxLength(15)]],
    });
  }

  addEmail() {
    this.emailaddress.push(this.createEmail());
  }

  removeEmail(index: number) {
    this.emailaddress.removeAt(index);
  }
  addMobile() {
    this.mobile.push(this.createMobile());
  }

  removeMobile(index: number) {
    this.mobile.removeAt(index);
  }

  createPrimaryAddress() {
    return this._fb.group({
      addressDetail: ["", [Validators.maxLength(500)]],
      state: ["", [Validators.maxLength(200)]],
      city: ["", [Validators.maxLength(200)]],
      postalCode: ["", [Validators.maxLength(200)]],
      country: ["", [Validators.maxLength(200)]],
      addressType: ["PRIMARY_ADDRESS"],
    });
  }

  createOtherAddress() {
    return this._fb.group({
      addressDetail: ["", [Validators.maxLength(500)]],
      state: ["", [Validators.maxLength(200)]],
      city: ["", [Validators.maxLength(200)]],
      postalCode: ["", [Validators.maxLength(200)]],
      country: ["", [Validators.maxLength(200)]],
      addressType: ["OTHER_ADDRESS"],
    });
  }

  get accountAddress(): FormArray {
    return this.form.get("accountAddress") as FormArray;
  }

  onSubmit(event: Event) {
    this.isInvalid = false;
    Object.values(this.form?.controls).forEach((control: any) =>
      control?.markAsTouched()
    );
    if (!this?.leadId && this.form.valid) {
      let obj = {
        accountId: parseInt(this.form.value.accountName),
        organizationId: 1,
        firstName: this.form.value.firstName,
        lastName: this.form.value.lastName,
        title: this.form.value.title,
        department: this.form.value.department,
        officePhone: this.form.value.officePhone,
        fax: this.form.value.fax,
        website: this.form.value.website,
        linkedInUrl: this.form.value.linkedInUrl,
        email: cleanArray(this.emailaddress.value.map((obj: any) => obj.email)),
        mobile: cleanArray(this.mobile.value.map((obj: any) => obj.mobile)),
        description: this.form.value.description,
        status: this.form.value.status,
        statusDescription: this.form.value.statusDescription,
        opportunityAmount: this.form.value.opportunityAmount,
        leadSource: this.form.value.leadSource,
        leadSourceDescription: this.form.value.leadSourceDescription,
        referredBy: this.form.value.referredBy,
        assignedTo: this.form.value.assignedTo,
        campaign: this.form.value.campaign,
        address: [this.form.value.primaryAddress, this.form.value.otherAddress],
        copyAddress: this.form.value.copyAddress,
      };

      if (obj.status === "CONVERTED") {
        this.confirmationService.confirm({
          target: event.target as EventTarget,
          message: "Do you want to convert a lead into an opportunity?",
          header: "Create Opportunity",
          icon: "pi pi-question-circle",
          acceptIcon: "none",
          rejectIcon: "none",
          rejectButtonStyleClass: "p-button-text",
          accept: () => {
            this.messageService.add({
              severity: "info",
              summary: "Confirmed",
              detail: "Converted",
            });
            this.api.post(this.endpoint.addLead, obj).subscribe(
              (res: any) => {
                if (res.status === "SUCCESS") {
                  this.toast.setSuccessPopupMessage(res?.message);
                  this.form.reset();
                  this.router.navigate(["opportunity/add"]);
                }
              },
              (error: HttpErrorResponse) => {
                if (error.status != 400) {
                  this.toast.setServerError(error.error.message);
                } else {
                  this.validationErrors = [];
                  this.isInvalid = true;
                  this.validationErrors = getValidationError(error?.error);
                }
              }
            );
          },
        });
      } else {
        this.api.post(this.endpoint.addLead, obj).subscribe(
          (res: any) => {
            if (obj.status === "CONVERTED") {
              this.router.navigate(["opportunity"]);
              this.form.reset();
            } else {
            }
            if (res.status === "SUCCESS") {
              this.toast.setSuccessPopupMessage(res?.message);
              this.form.reset();
              this.router.navigate(["lead"]);
            }
          },
          (error: HttpErrorResponse) => {
            if (error.status != 400) {
              this.toast.setServerError(error.error.message);
            } else {
              this.validationErrors = [];
              this.isInvalid = true;
              this.validationErrors = getValidationError(error?.error);
            }
          }
        );
      }
    } else if (this.form.valid) {
      let editobj = {
        accountId: parseInt(this.form.value.accountName),
        organizationId: 1,
        firstName: this.form.value.firstName,
        lastName: this.form.value.lastName,
        title: this.form.value.title,
        department: this.form.value.department,
        officePhone: this.form.value.officePhone,
        fax: this.form.value.fax,
        website: this.form.value.website,
        linkedInUrl: this.form.value.linkedInUrl,
        mobile: cleanArray(this.mobile.value.map((obj: any) => obj.mobile)),
        email: cleanArray(this.emailaddress.value.map((obj: any) => obj.email)),
        description: this.form.value.description,
        status: this.form.value.status,
        statusDescription: this.form.value.statusDescription,
        opportunityAmount: this.form.value.opportunityAmount,
        leadSource: this.form.value.leadSource,
        leadSourceDescription: this.form.value.leadSourceDescription,
        referredBy: this.form.value.referredBy,
        assignedTo: this.form.value.assignedTo,
        campaign: this.form.value.campaign,
        address: [this.form.value.primaryAddress, this.form.value.otherAddress],
        copyAddress: this.form.value.copyAddress,
      };
      if (editobj.status === "CONVERTED") {
        this.confirmationService.confirm({
          target: event.target as EventTarget,
          message: "Do you want to convert a lead into an opportunity?",
          header: "Create Opportunity",
          icon: "pi pi-question-circle",
          acceptIcon: "none",
          rejectIcon: "none",
          rejectButtonStyleClass: "p-button-text",
          accept: () => {
            this.messageService.add({
              severity: "info",
              summary: "Confirmed",
              detail: "Converted",
            });
            this.api
              .put(this.endpoint.editLead + this?.leadId, editobj)
              .subscribe(
                (res: any) => {
                  if (res.status === "SUCCESS") {
                    this.toast.setSuccessPopupMessage(res?.message);
                    this.form.reset();
                    this.router.navigate(["opportunity/add"]);
                  }
                },
                (error: HttpErrorResponse) => {
                  if (error.status != 400) {
                    this.toast.setServerError(error.error.message);
                  } else {
                    this.validationErrors = [];
                    this.isInvalid = true;
                    this.validationErrors = getValidationError(error?.error);
                  }
                }
              );
          },
        });
      } else {
        this.api.put(this.endpoint.editLead + this?.leadId, editobj).subscribe(
          (res: any) => {
            if (editobj.status === "CONVERTED") {
              this.router.navigate(["opportunity"]);
              this.form.reset();
            } else {
            }
            if (res.status === "SUCCESS") {
              this.toast.setSuccessPopupMessage(res?.message);
              this.form.reset();
              this.router.navigate(["lead"]);
            }
          },
          (error: HttpErrorResponse) => {
            if (error.status != 400) {
              this.toast.setServerError(error.error.message);
            } else {
              this.validationErrors = [];
              this.isInvalid = true;
              this.validationErrors = getValidationError(error?.error);
            }
          }
        );
      }
    }
  }
  ngAfterViewChecked() {
    this.cdref.detectChanges();
  }
  onCancel() {
    return window.history.back();
  }
  openAccountPopup() {
    this.addAccountPopup = true;
  }
  setLoadingValue(event: any) {
    if (event && event != "undefined") {
      this.addAccountPopup = false;
      this.getMasterData();
      this.selectedAccount = event;
      this.form.patchValue({
        accountName: event,
      });
    }
    this.cdref.detectChanges();
  }
  /* for user form */

  onUserSubmit() {
    if (this.userForm.valid) {
      this.userForm.reset();
      this.visible = false;
    }
  }

  onCancelForm() {
    this.visible = false;
    this.form.reset();
    this.userForm.reset();
  }
  showDialog() {
    this.visible = true;
  }

  confirm1(event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: "Do you want to convert a lead into an opportunity?",
      header: "Create Opportunity",
      icon: "pi pi-question-circle",
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Confirmed",
          detail: "Converted",
        });
        this.router.navigate(["opportunity"]);
      },
    });
  }

  // copy address
  updateCopyCheckbox() {
    const primaryAddress = this.form.get("primaryAddress")!.value;
    const otherAddress = this.form.get("otherAddress")!.value;
    const copyAddressControl = this.form.get("copyAddress");

    if (
      primaryAddress.city === otherAddress.city &&
      primaryAddress.state === otherAddress.state &&
      primaryAddress.country === otherAddress.country &&
      primaryAddress.postalCode === otherAddress.postalCode &&
      primaryAddress.addressDetail === otherAddress.addressDetail
    ) {
      copyAddressControl!.setValue(true, { emitEvent: false });
    } else {
      copyAddressControl!.setValue(false, { emitEvent: false });
    }
  }

  copyAllAddress() {
    const primaryAddress = this.form.get("primaryAddress")!.value;
    this.form
      .get("otherAddress")!
      .patchValue({ ...primaryAddress, addressType: "OTHER_ADDRESS" });
  }

  onCopyAddressChange() {
    if (this.form.get("copyAddress")!.value) {
      this.copyAllAddress();
    }
  }

  handleAccountSubmit(name: string) {
    const newId = this.accountOptions.length
      ? Math.max(...this.accountOptions.map((option) => option.id)) + 1
      : 1;
    this.accountOptions.push({ id: newId, name });
    this.addAccountPopup = false;
    this.form.patchValue({
      accountName: newId,
    });
  }
}

<div class="modal-dialog-scrollable">
  <div class="modal-header">
    <h5 class="modal-title model-lable">View Contact</h5>
    <button
      type="button"
      class="btn-close"
      (click)="closeModel()"
      aria-label="Close"
    ></button>
  </div>
  <div class="modal-body custom-model-design">
    <div class="container-fluid h-100">
      <div class="col-12">
        <div class="custom-view-account">
          <div class="row mb-3">
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>First Name: </label>
              </div>
              <div class="col-9">
                <span>{{ userData.firstName }}</span>
              </div>
            </div>
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Last Name: </label>
              </div>
              <div class="col-9">
                <span>{{ userData.lastName }}</span>
              </div>
            </div>
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Office Phone: </label>
              </div>
              <div class="col-9">
                <span>{{ userData.officePhone }}</span>
              </div>
            </div>
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Mobile: </label>
              </div>
              <div class="col-9">
                <span>{{ userData.mobile }}</span>
              </div>
            </div>
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Title: </label>
              </div>
              <div class="col-9">
                <span>{{ userData.title }}</span>
              </div>
            </div>
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Department: </label>
              </div>
              <div class="col-9">
                <span>{{ userData.department }}</span>
              </div>
            </div>
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Account Name: </label>
              </div>
              <div class="col-9">
                <span>{{ userData.accountName }}</span>
              </div>
            </div>
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Fax: </label>
              </div>
              <div class="col-9">
                <span>{{ userData.fax }}</span>
              </div>
            </div>
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Email Address: </label>
              </div>
              <div class="col-9">
                <span>{{ userData.emailAddress }}</span>
              </div>
            </div>
            <div class="d-flex col-6 mb-3"></div>

            <div class="col-6 mb-3">
              <div
                class="col-12 mb-3"
                style="justify-content: center !important"
              >
                <h2 class="design-color">Primary Address</h2>
              </div>
              <div>
                <div class="d-flex col-12 mb-3">
                  <div class="col-3">
                    <label>Address: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData.contactAddress?.[0]?.address }}</span>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="col-3">
                    <label>City: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData.contactAddress?.[0]?.city }}</span>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="col-3">
                    <label>State: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData.contactAddress?.[0]?.state }}</span>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="col-3">
                    <label>Postal Code: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData.contactAddress?.[0]?.postalCode }}</span>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="col-3">
                    <label>Country: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData.contactAddress?.[0]?.country }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 mb-3">
              <div
                class="col-12 mb-3"
                style="justify-content: center !important"
              >
                <h2 class="design-color">Other Address</h2>
              </div>
              <div>
                <div class="d-flex col-12 mb-3">
                  <div class="col-3">
                    <label>Address: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData.contactAddress?.[1]?.address }}</span>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="col-3">
                    <label>City: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData.contactAddress?.[1]?.city }}</span>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="col-3">
                    <label>State: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData.contactAddress?.[1]?.state }}</span>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="col-3">
                    <label>Postal Code: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData.contactAddress?.[1]?.postalCode }}</span>
                  </div>
                </div>
                <div class="d-flex col-12 mb-3">
                  <div class="col-3">
                    <label>Country: </label>
                  </div>
                  <div class="col-9">
                    <span>{{ userData.contactAddress?.[1]?.country }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Description: </label>
              </div>
              <div class="col-9">
                <span>{{ userData.description }}</span>
              </div>
            </div>
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Lead Source: </label>
              </div>
              <div class="col-9">
                <span>{{ userData.leadSourceStr }}</span>
              </div>
            </div>
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Report to: </label>
              </div>
              <div class="col-9">
                <span>{{ userData.reportName }}</span>
              </div>
            </div>
            <div class="d-flex col-6 mb-3">
              <div class="col-3">
                <label>Campaign </label>
              </div>
              <div class="col-9">
                <span>{{ userData.compaign }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-cancel" (click)="closeModel()">
      Close
    </button>
    <button type="button" class="btn btn-submit" (click)="edit(id)">
      Edit
    </button>
  </div>
</div>

<div class="container-fluid h-100 padding-30px">
  <div class="col-12">
    <div class="progress-spinner" *ngIf="loading">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <p-card>
      <p-accordion [multiple]="true" [activeIndex]="[0, 1, 2]">
        <p-accordionTab>
          <ng-template pTemplate="header">
            <div class="custom-accordian-header-design" style="width: 100%;">
              <span class="font-bold white-space-nowrap">My Calls</span>
              <div>
                <i class="bi bi-pencil-square icons-container"></i>
                <i
                  class="bi bi-arrow-clockwise icons-container"
                  (click)="onReload()"
                ></i>
                <i class="bi bi-x-lg"></i>
              </div>
            </div>
          </ng-template>
          <app-my-calls-list
            myCallsFlag="true"
            (callAPI)="onReload()"
          ></app-my-calls-list>
        </p-accordionTab>
        <p-accordionTab>
          <ng-template pTemplate="header">
            <div class="custom-accordian-header-design" style="width: 100%;">
              <span class="font-bold white-space-nowrap">My Meetings</span>
              <div>
                <i class="bi bi-pencil-square icons-container"></i>
                <i
                  class="bi bi-arrow-clockwise icons-container"
                  (click)="onReload()"
                ></i>
                <i class="bi bi-x-lg"></i>
              </div>
            </div>
          </ng-template>
          <app-my-meetings-list (callAPI)="onReload()"></app-my-meetings-list>
        </p-accordionTab>
        <p-accordionTab>
          <ng-template pTemplate="header">
            <div class="custom-accordian-header-design" style="width: 100%;">
              <span class="font-bold white-space-nowrap"
                >My Top Open Opportunities</span
              >
              <div>
                <i class="bi bi-pencil-square icons-container"></i>
                <i
                  class="bi bi-arrow-clockwise icons-container"
                  (click)="onReload()"
                ></i>
                <i class="bi bi-x-lg"></i>
              </div>
            </div>
          </ng-template>
          <app-my-top-oppertunities-list
            myOppertunityFlag="true"
            (callAPI)="onReload()"
          ></app-my-top-oppertunities-list>
        </p-accordionTab>
      </p-accordion>
    </p-card>
  </div>
</div>

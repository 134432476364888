<div class="card">
  <div class="progress-spinner" *ngIf="loading">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <p-scrollPanel [style]="{ width: '100%', height: 'auto' }">
    <p-card class="custom-card-design">
      <p-table
        #paperDt
        [scrollable]="true"
        [scrollHeight]="'300px'"
        [value]="historyData"
        [lazy]="true"
        (onLazyLoad)="loadPaperLazy($event)"
        [rows]="datatableRowsVal"
        [showCurrentPageReport]="true"
        [totalRecords]="totalRecords"
        [rowsPerPageOptions]="rowPerPage"
        styleClass="p-datatable-customers p-datatable-gridlines"
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        dataKey="id"
      >
        <ng-template pTemplate="caption">
          <div class="list-card-design">
            <p-menubar [model]="items"> </p-menubar>
            <div style="display: flex; padding-top: 6px">
              <div class="searchbox">
                <span class="p-input-icon-left p-ml-auto">
                  <i class="pi pi-search"></i>
                  <input
                    pInputText
                    type="text"
                    (input)="paperDt.filterGlobal(globalFilter, 'contains')"
                    placeholder="Search"
                    [(ngModel)]="globalFilter"
                  />
                </span>
              </div>
            </div>
          </div>
        </ng-template>
        <!-- table header -->
        <ng-template pTemplate="header">
          <tr>
            <th
              class="text-align-center"
              *ngFor="let tableHeading of cols"
              [pSortableColumn]="tableHeading?.sortField"
              [style.width]="tableHeading.colWidth"
            >
              {{ tableHeading.colName }}
              <p-sortIcon
                *ngIf="tableHeading.isSort"
                [field]="tableHeading?.sortField"
              ></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-history let-rowIndex="rowIndex">
          <tr>
            <td class="text-align-center">
              {{ history.srNo }}
            </td>
            <td class="text-align-center">
              {{ history.subject }}
            </td>
            <td class="text-align-center" *ngIf="history.srNo">
              {{
                history?.id && history?.tabletype == "LEAD_HISTORY"
                  ? "Note"
                  : history.statusPrimaryStr && history.statusAlternateStr
                    ? history.statusPrimaryStr +
                      " " +
                      history.statusAlternateStr
                    : history.statusPrimaryStr
                      ? history.statusPrimaryStr
                      : history.statusAlternateStr
                        ? history.statusAlternateStr
                        : ""
              }}
            </td>
            <td class="text-align-center">
              {{ history.relatedToName }}
            </td>
            <td class="text-align-center">
              {{ history.createdDate }}
            </td>
            <td class="text-align-center">
              {{ history.modifiedDate }}
            </td>
            <td class="text-align-center">
              {{ history.endDateTime }}
            </td>
            <td class="text-align-center">
              {{ history.assignedName }}
            </td>

            <td class="actionBar">
              <div class="actionBarContainer">
                <a title="View" (click)="viewHistoryNoteById(history)">
                  <i class="bi bi-eye-fill"></i>
                </a>
                <a title="Edit" (click)="openTask(history)">
                  <i class="bi bi-pencil-square"></i>
                </a>
                <a title="Delete">
                  <i
                    class="bi bi-trash-fill"
                    (click)="
                      openDeleteConfirmationModal(
                        history,
                        deleteConfirmationModal
                      )
                    "
                  ></i>
                </a>
              </div>
            </td>
          </tr>
        </ng-template>
        <!-- Empty Message -->
        <ng-template pTemplate="emptymessage" let-history>
          <tr>
            <td class="text-align-center" [attr.colspan]="cols?.length">
              {{ emptyMessage }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-card>
    <ng-template #deleteConfirmationModal let-modal>
      <div class="modal-header">
        <h4 class="modal-title">{{ modelTitle }}</h4>
        <button
          type="button"
          class="btn-close"
          aria-describedby="modal-title"
          (click)="modal.dismiss('Cross click')"
        ></button>
      </div>
      <div class="modal-body">
        {{ modelBody }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-cancel" (click)="modal.dismiss()">
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-submit"
          style="margin-left: 10px !important"
          (click)="deleteHistoryById(); modal.close()"
        >
          Delete
        </button>
      </div>
    </ng-template>
  </p-scrollPanel>
</div>

<div class="modal-header">
  <h5 class="modal-title model-lable">{{ header }}</h5>
  <button
    type="button"
    class="btn-close"
    (click)="closeModel()"
    aria-label="Close"
  ></button>
</div>
<div class="modal-body">
  <div class="container-fluid h-100">
    <div class="col-12">
      <div class="custom-view-account">
        <div class="row">
          <div class="d-flex col-6 mb-3">
            <div class="col-3">
              <label>Contact: </label>
            </div>
            <div class="col-9">
              <span>{{ historyData?.contactAccountName }}</span>
            </div>
          </div>
          <div class="d-flex col-6 mb-3">
            <div class="col-3">
              <label>Related To: </label>
            </div>
            <div class="col-9">
              <span>{{ historyData?.relatedToName }}</span>
            </div>
          </div>
          <div class="d-flex col-6 mb-3">
            <div class="col-3">
              <label>Subject: </label>
            </div>
            <div class="col-9">
              <span>{{ historyData?.subject }}</span>
            </div>
          </div>
          <div class="d-flex col-6 mb-3">
            <div class="col-3">
              <label>Assigned To: </label>
            </div>
            <div class="col-9">
              <span>{{ historyData?.assignedName }}</span>
            </div>
          </div>
          <div class="d-flex col-6 mb-3">
            <div class="col-3">
              <label>Attchment: </label>
            </div>
            <div class="col-9">
              <span>{{ historyData?.attachment }}</span>
            </div>
          </div>
          <div class="d-flex col-6 mb-3">
            <div class="col-3">
              <label>Note:</label>
            </div>
            <div class="col-9">
              <span>{{ historyData?.note }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-cancel" (click)="closeModel()">
          Close
        </button>
        <button type="button" class="btn btn-submit" (click)="edit(id)">
          Edit
        </button>
      </div>
    </div>
  </div>
</div>

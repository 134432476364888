import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LEAD_SOURCE, TYPE } from "src/app/helpers/constant-data/account.model";
import { API_POINTS } from "src/app/helpers/model/endpoint.model";
import { ApiService } from "src/app/helpers/services/api.service";
import { ToastService } from "src/app/helpers/services/toast.service";
import { cleanArray } from "src/app/shared/utlis/common-functions";

@Component({
  selector: "app-contacts-create",
  templateUrl: "./contacts-create.component.html",
  styleUrls: ["./contacts-create.component.css"],
})
export class ContactsCreateComponent implements OnInit {
  contactForm!: FormGroup;
  endpoint: any = API_POINTS;
  dropdownOptions: any = LEAD_SOURCE;
  dropdownType: any = TYPE;
  accountOptions: any;
  userDetailOptions: any;
  loading = false;
  editContactId: any;
  isInvalid: boolean = false;
  validationErrors: any = [];

  constructor(
    public _fb: FormBuilder,
    public api: ApiService,
    private router: Router,
    private toast: ToastService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.editContactId = params["id"];
    });
    this.contactForm = this._fb.group({
      organizationId: [1],
      firstName: ["", [Validators.maxLength(200)]],
      lastName: ["", [Validators.maxLength(200), Validators.required]],
      officePhone: ["", [Validators.maxLength(15)]],
      mobile: ["", [Validators.maxLength(15)]],
      title: ["", [Validators.maxLength(500)]],
      department: ["", [Validators.maxLength(500)]],
      accountName: ["", [Validators.required]],
      fax: ["", [Validators.maxLength(500)]],
      emailAddressDetails: this._fb.array([this.createEmailAddress()]),
      primaryAddressDetails: this.createPrimaryAddress(),
      otherAddressDetails: this.createOtherAddress(),
      copyAddress: false,
      description: ["", [Validators.maxLength(500)]],
      leadSource: ["", [Validators.maxLength(500)]],
      reportTo: [""],
      campaign: ["", [Validators.maxLength(500)]],
    });

    this.contactForm
      .get("primaryAddressDetails")!
      .valueChanges.subscribe(() => {
        this.updateCopyCheckbox();
      });

    this.contactForm.get("otherAddressDetails")!.valueChanges.subscribe(() => {
      this.updateCopyCheckbox();
    });

    // Subscribe to changes in the copyAddress checkbox
    this.contactForm.get("copyAddress")!.valueChanges.subscribe((value) => {
      if (value) {
        const { city, state, country, postalCode, address } =
          this.contactForm.get("primaryAddressDetails")!.value;
        const formOtherAddress: any = this.contactForm.get(
          "otherAddressDetails",
        ) as FormArray;
        formOtherAddress.patchValue({
          city: city,
          state: state,
          country: country,
          postalCode: postalCode,
          address: address,
        });
      } else {
        this.contactForm.get("otherAddressDetails")!.reset();
        const formOtherAddress: any = this.contactForm.get(
          "otherAddressDetails",
        ) as FormArray;
        formOtherAddress.patchValue({
          contactAddressType: "OTHER_CONTACT_ADDRESS",
        });
      }
    });

    this.getMasterData();

    if (this.editContactId) {
      this.getContactListByID();
    }
  }

  getMasterData() {
    this.loading = true;
    this.api.get(this.endpoint.getContactMasterData).subscribe(
      (result: any) => {
        if (result.status == "SUCCESS") {
          this.accountOptions = result?.data?.accountList;
          this.userDetailOptions = result?.data?.userList;
        } else {
          this.toast.setErrorPopupMessage(result.message);
        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.toast.setErrorPopupMessage(error?.error?.message);
      },
    );
  }
  getContactListByID() {
    this.api
      .get(this.endpoint.getContactByID + this.editContactId)
      .subscribe((res: any) => {
        const data: any = res?.data;
        this.contactForm.patchValue({
          firstName: data?.firstName,
          lastName: data?.lastName,
          officePhone: data?.officePhone,
          mobile: data?.mobile,
          title: data?.title,
          department: data?.department,
          accountName: data?.accountId,
          fax: data?.fax,
          leadSource: data?.leadSource,
          campaign: data?.compaign,
          copyAddress: data?.copyAddress,
          reportTo: data?.reportTo,
          description: data?.description,
        });
        const formAddress = this.contactForm.get(
          "emailAddressDetails",
        ) as FormArray;

        const newEmails: any = [data?.emailAddress];

        const newEmailsArray = newEmails[0]
          .split(",")
          .map((email: any) => email.trim());
        newEmailsArray.forEach((emailAddress: any) => {
          formAddress.push(
            this._fb.group({
              emailAddress: [
                emailAddress,
                [
                  Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$"),
                  Validators.maxLength(200),
                ],
              ],
            }),
          );
        });
        this.emailAddressDetailsArray.removeAt(0);

        // newEmails?.forEach((item:any) => {
        //   this.emailAddressDetailsArray?.push(this._fb.group(item));
        // });
        const primaryAddress = data?.contactAddress;
        const formPrimaryAddress: any = this.contactForm.get(
          "primaryAddressDetails",
        ) as FormArray;
        formPrimaryAddress.patchValue({
          city: primaryAddress[0].city,
          state: primaryAddress[0].state,
          country: primaryAddress[0].country,
          postalCode: primaryAddress[0].postalCode,
          address: primaryAddress[0].address,
        });

        const otherAddress = data?.contactAddress;
        const formOtherAddress: any = this.contactForm.get(
          "otherAddressDetails",
        ) as FormArray;
        formOtherAddress.patchValue({
          city: otherAddress[1].city,
          state: otherAddress[1].state,
          country: otherAddress[1].country,
          postalCode: otherAddress[1].postalCode,
          address: otherAddress[1].address,
        });
      });
  }
  createPrimaryAddress() {
    return this._fb.group({
      address: ["", [Validators.maxLength(500)]],
      city: ["", [Validators.maxLength(200)]],
      state: ["", [Validators.maxLength(200)]],
      postalCode: ["", [Validators.maxLength(200)]],
      country: ["", [Validators.maxLength(200)]],
      contactAddressType: ["PRIMARY_CONTACT_ADDRESS"],
    });
  }

  createOtherAddress() {
    return this._fb.group({
      address: ["", [Validators.maxLength(500)]],
      city: ["", [Validators.maxLength(200)]],
      state: ["", [Validators.maxLength(200)]],
      postalCode: ["", [Validators.maxLength(200)]],
      country: ["", [Validators.maxLength(200)]],
      contactAddressType: ["OTHER_CONTACT_ADDRESS"],
    });
  }
  get formAltaControls(): any {
    return this.contactForm["controls"];
  }
  get primaryAddressDetails(): FormArray {
    return this.contactForm.get("primaryAddressDetails") as FormArray;
  }
  get otherAddressDetails(): FormArray {
    return this.contactForm.get("otherAddressDetails") as FormArray;
  }
  get copyAddress() {
    return this.contactForm.get("copyAddress");
  }
  get emailAddressDetailsArray(): FormArray {
    return this.contactForm.get("emailAddressDetails") as FormArray;
  }
  createEmailAddress() {
    return this._fb.group({
      emailAddress: [
        null,
        [
          Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$"),
          Validators.maxLength(200),
        ],
      ],
    });
  }
  addEmailAddress() {
    this.emailAddressDetailsArray.push(this.createEmailAddress());
  }
  removeEmailAddress(index: number) {
    this.emailAddressDetailsArray.removeAt(index);
  }
  onSubmit() {
    let body = {
      organizationId: 1,
      accountId: parseInt(this.contactForm.value.accountName),
      firstName: this.contactForm.value.firstName,
      lastName: this.contactForm.value.lastName,
      officePhone: this.contactForm.value.officePhone,
      mobile: this.contactForm.value.mobile,
      title: this.contactForm.value.title,
      department: this.contactForm.value.department,
      fax: this.contactForm.value.fax,
      emailAddress: cleanArray(
        this.emailAddressDetailsArray.value.map((obj: any) => obj.emailAddress),
      ),
      addresscheckbox: this.contactForm.value.addresscheckbox,
      copyAddress: this.contactForm.value.copyAddress,
      description: this.contactForm.value.description,
      leadSource: this.contactForm.value.leadSource,
      reportTo: parseInt(this.contactForm.value.reportTo),
      compaign: this.contactForm.value.campaign,
      contactAddress: [
        this.contactForm.value.primaryAddressDetails,
        this.contactForm.value.otherAddressDetails,
      ],
    };
    Object.values(this.contactForm?.controls).forEach((control: any) =>
      control?.markAsTouched(),
    );
    if (this.contactForm.valid) {
      if (!this.editContactId) {
        this.api.post(this.endpoint.createContact, body).subscribe(
          (res: any) => {
            if (res.status === "SUCCESS") {
              this.toast.setSuccessPopupMessage(res?.message);
              this.contactForm.reset();
              this.router.navigate(["contact"]);
            }
          },
          (error: any) => {
            this.toast.setErrorPopupMessage(error.error.message);
          },
        );
      } else {
        this.api
          .put(this.endpoint.editContact + this.editContactId, body)
          .subscribe(
            (res: any) => {
              if (res.status === "SUCCESS") {
                this.toast.setSuccessPopupMessage(res?.message);
                this.contactForm.reset();
                this.router.navigate(["contact"]);
              }
            },
            (error: any) => {
              this.toast.setErrorPopupMessage(error.error.message);
            },
          );
      }
    }
  }
  onCancel() {
    return window.history.back();
  }

  // copy address
  updateCopyCheckbox() {
    const primaryAddress = this.contactForm.get("primaryAddressDetails")!.value;
    const otherAddress = this.contactForm.get("otherAddressDetails")!.value;
    const copyAddressControl = this.contactForm.get("copyAddress");

    if (
      primaryAddress.city === otherAddress.city &&
      primaryAddress.state === otherAddress.state &&
      primaryAddress.country === otherAddress.country &&
      primaryAddress.postalCode === otherAddress.postalCode &&
      primaryAddress.address === otherAddress.address
    ) {
      copyAddressControl!.setValue(true, { emitEvent: false });
    } else {
      copyAddressControl!.setValue(false, { emitEvent: false });
    }
  }

  copyAllAddress() {
    const primaryAddress = this.contactForm.get("primaryAddressDetails")!.value;
    this.contactForm.get("otherAddressDetails")!.patchValue(primaryAddress);
  }

  onCopyAddressChange() {
    if (this.contactForm.get("copyAddress")!.value) {
      this.copyAllAddress();
    }
  }
}

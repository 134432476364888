import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { SharedModule } from "./shared/shared.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ApiInterceptor } from "./helpers/interceptor/api.interceptor";
import { ToastModule } from "primeng/toast";
import { ConfirmationService, MessageService } from "primeng/api";
import { MenubarModule } from "primeng/menubar";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox";
import { DropdownModule } from "primeng/dropdown";
import { CalendarModule } from "primeng/calendar";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { PanelModule } from "primeng/panel";
import { InputTextModule } from "primeng/inputtext";
import { TabViewModule } from "primeng/tabview";
import { TabMenuModule } from "primeng/tabmenu";
import { HeaderComponent } from "./components/header/header.component";
import { SimplebarAngularModule } from "simplebar-angular";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
@NgModule({
  declarations: [AppComponent, HeaderComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    ToastModule,
    BrowserAnimationsModule,
    ButtonModule,
    MenubarModule,
    CheckboxModule,
    DropdownModule,
    TabMenuModule,
    CalendarModule,
    ConfirmDialogModule,
    TabViewModule,
    PanelModule,
    InputTextModule,
    SimplebarAngularModule,
  ],
  providers: [
    MessageService,
    ConfirmationService,
    NgbActiveModal,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

<div class="modal-header">
  <h5 class="modal-title model-lable">View Oppertunity</h5>
  <button
    type="button"
    class="btn-close"
    (click)="closeModel()"
    aria-label="Close"
  ></button>
</div>
<div class="modal-body">
  <div class="container-fluid h-100">
    <div class="col-12">
      <div class="custom-view-account">
        <div class="row mb-3">
          <div class="d-flex col-6 mb-3">
            <div class="col-3">
              <label>Opportunity Name: </label>
            </div>
            <div class="col-9">
              <span>{{ oppertunityData.opportunityName }}</span>
            </div>
          </div>
          <div class="d-flex col-6 mb-3">
            <div class="col-3">
              <label>Account Name: </label>
            </div>
            <div class="col-9">
              <span>{{ oppertunityData.accountName }}</span>
            </div>
          </div>
          <div class="d-flex col-6 mb-3">
            <div class="col-3">
              <label>Currency: </label>
            </div>
            <div class="col-9">
              <span>{{ oppertunityData.currencyStr }}</span>
            </div>
          </div>
          <div class="d-flex col-6 mb-3">
            <div class="col-3">
              <label>Expected Close Date: </label>
            </div>
            <div class="col-9">
              <span>{{ oppertunityData.expectedCloseDate }}</span>
            </div>
          </div>

          <div class="d-flex col-6 mb-3">
            <div class="col-3">
              <label>Opportunity Amount : </label>
            </div>
            <div class="col-9">
              <span>{{ oppertunityData.opportunityAmmount }}</span>
            </div>
          </div>
          <div class="d-flex col-6 mb-3">
            <div class="col-3">
              <label>Type: </label>
            </div>
            <div class="col-9">
              <span>{{ oppertunityData.opportunityTypeStr }}</span>
            </div>
          </div>
          <div class="d-flex col-6 mb-3">
            <div class="col-3">
              <label>Sales Stages: </label>
            </div>
            <div class="col-9">
              <span>{{ oppertunityData.salesStageStr }}</span>
            </div>
          </div>
          <div class="d-flex col-6 mb-3">
            <div class="col-3">
              <label>Lead Source: </label>
            </div>
            <div class="col-9">
              <span>{{ oppertunityData.leadSourceStr }}</span>
            </div>
          </div>
          <div class="d-flex col-6 mb-3">
            <div class="col-4">
              <label>Probability(%): </label>
            </div>
            <div class="col-9">
              <span>{{ oppertunityData.probabilityPercentage }}</span>
            </div>
          </div>
          <div class="d-flex col-6 mb-3">
            <div class="col-3">
              <label>Campaign: </label>
            </div>
            <div class="col-9">
              <span>{{ oppertunityData.campaign }}</span>
            </div>
          </div>
          <div class="d-flex col-6 mb-3">
            <div class="col-3">
              <label>Next Step: </label>
            </div>
            <div class="col-9">
              <span>{{ oppertunityData.nextStep }}</span>
            </div>
          </div>
          <div class="d-flex col-6 mb-3">
            <div class="col-3">
              <label>Description: </label>
            </div>
            <div class="col-9">
              <span>{{ oppertunityData.description }}</span>
            </div>
          </div>
          <div class="d-flex col-6 mb-3">
            <div class="col-3">
              <label>Assigned To: </label>
            </div>
            <div class="col-9">
              <span>{{ oppertunityData.assignedName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-cancel" (click)="closeModel()">
    Close
  </button>
  <button type="button" class="btn btn-submit" (click)="edit(id)">Edit</button>
</div>

import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ApiService } from "src/app/helpers/services/api.service";
import { ToastService } from "src/app/helpers/services/toast.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"],
})
export class ChangePasswordComponent implements OnInit {
  showOldPassword: boolean = false;
  showNewPassword: boolean = false;
  showConfirmNewPassword: boolean = false;
  changePasswordForm!: FormGroup;

  constructor(
    private _fb: FormBuilder,
    private api_service: ApiService,
    private toster: ToastService,
    private router: Router
  ) {}

  ngOnInit() {
    this.changePasswordForm = this._fb.group(
      {
        oldPassword: ["", [Validators.required]],
        newPassword: ["", [Validators.required, Validators.minLength(6)]],
        confirmNewPassword: [
          "",
          [Validators.required, Validators.minLength(6)],
        ],
      },
      { validator: this.passwordMatchValidator }
    );
  }

  get f(): any {
    return this.changePasswordForm.controls;
  }

  get passwordsMismatch(): boolean {
    return this.changePasswordForm.hasError("passwordMismatch");
  }

  toggleShowOldPassword(): void {
    this.showOldPassword = !this.showOldPassword;
  }
  toggleShowNewPassword(): void {
    this.showNewPassword = !this.showNewPassword;
  }
  toggleShowConfirmNewPassword(): void {
    this.showConfirmNewPassword = !this.showConfirmNewPassword;
  }

  passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const newPassword = control.get("newPassword")?.value;
    const confirmNewPassword = control.get("confirmNewPassword")?.value;
    return newPassword &&
      confirmNewPassword &&
      newPassword !== confirmNewPassword
      ? { passwordMismatch: true }
      : null;
  }

  onSubmit() {
    if (this.changePasswordForm.invalid) {
      this.changePasswordForm.markAllAsTouched();
      return;
    }
    const oldPassword = this.changePasswordForm.value.oldPassword;
    const newPassword = this.changePasswordForm.value.newPassword;
    const confirmNewPassword = this.changePasswordForm.value.confirmNewPassword;

    this.api_service
      .changePwdByOldPwd(oldPassword, newPassword, confirmNewPassword)
      .subscribe((res) => {
        this.toster.setSuccessPopupMessage(res.message);

        this.api_service.logout().subscribe(
          (res: any) => {
            localStorage.removeItem("access-token");
            localStorage.clear();
            this.router.navigate(["/"]);
            this.toster.setSuccessPopupMessage(
              "Login Again using New Password"
            );
          },
          (error: any) => {
            this.toster.setErrorPopupMessage(error?.error?.message);
          }
        );
      });
  }
}

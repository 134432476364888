<div>
  <div class="progress-spinner" *ngIf="loading">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <div class="mb-3">
    <label for="formFile" class="form-label">Excel File</label>
    <input
      class="form-control"
      style="width: auto !important; height: auto"
      type="file"
      id="formFile"
      (change)="onUpload($event)"
    />
  </div>
  <p-dialog
    [draggable]="false"
    [resizable]="true"
    [(visible)]="addErrorImportPopup"
    showEffect="fade"
    [modal]="false"
    header="Excel Import Errors"
    [style]="{ width: '100vw', height: '80vh', minHeight: '80vh' }"
  >
    <p-table
      *ngIf="addErrorImportPopup"
      [value]="uploadedFilesError"
      [tableStyle]="{ 'min-width': '50rem' }"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Row Number</th>
          <th>Column Name</th>
          <th>Error Messages</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr *ngFor="let internalData of data.columns">
          <td>{{ data?.rowNumber }}</td>
          <td>{{ internalData?.columnName }}</td>
          <td>{{ internalData?.errorMessage }}</td>
        </tr>
      </ng-template>
    </p-table>
  </p-dialog>
  <div class="d-flex justify-content-end">
    <button
      class="btn btn-primary custom-button-design btn-default"
      (click)="onImportFile()"
    >
      Import
    </button>
  </div>
  <div class="mt-3">
    <label class="download-label-design">Download Blank Template</label>
    <p-divider></p-divider>
    <button
      class="btn pl-2 btn btn-primary custom-button-design btn-default"
      (click)="onDownloadLead()"
    >
      <i
        class="bi bi-file-earmark-excel-fill"
        style="padding-right: 6px !important"
      ></i
      >Download
    </button>
  </div>
</div>

<p-tabView styleClass="no-dropdown-tabs" [(activeIndex)]="activeTabIndex">
  <p-tabPanel header="Overview" [selected]="true">
    <div class="h-100">
      <div class="row margin-right-0px">
        <div class="progress-spinner" *ngIf="loading">
          <p-progressSpinner></p-progressSpinner>
        </div>
        <div class="col-lg-12">
          <p-scrollPanel [style]="{ width: '100%', height: 'auto' }">
            <p-card class="custom-card-design">
              <!-- table -->
              <div class="table-responsive" *ngIf="activeTabIndex == 0">
                <p-table
                  #paperDt
                  [scrollable]="true"
                  [scrollHeight]="'300px'"
                  [value]="overviewUsers"
                  [lazy]="true"
                  (onLazyLoad)="loadPaperLazy($event)"
                  [rows]="datatableRowsVal"
                  [showCurrentPageReport]="true"
                  [totalRecords]="totalRecords"
                  [rowsPerPageOptions]="rowPerPage"
                  styleClass="p-datatable-customers p-datatable-gridlines"
                  [paginator]="true"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  dataKey="id"
                >
                  <ng-template pTemplate="caption">
                    <div
                      class="row d-flex justify-content-end"
                      style="width: 100%; padding-right: 15px"
                    >
                      <div
                        class="searchbox col-lg-3 col-md-4 col-sm-12 width-100"
                      >
                        <span class="p-input-icon-left p-ml-auto">
                          <i class="pi pi-search"></i>
                          <input
                            pInputText
                            type="text"
                            (input)="
                              paperDt.filterGlobal(globalFilter, 'contains')
                            "
                            placeholder="Search"
                            [(ngModel)]="globalFilter"
                          />
                        </span>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                    <tr>
                      <th
                        class="text-align-center"
                        *ngFor="let tableHeading of cols"
                        [pSortableColumn]="tableHeading?.sortField"
                        [style.width]="tableHeading.colWidth"
                      >
                        {{ tableHeading.colName }}
                        <p-sortIcon
                          *ngIf="tableHeading.isSort"
                          [field]="tableHeading?.sortField"
                        ></p-sortIcon>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-user>
                    <tr>
                      <td>
                        {{ user.srNo }}
                      </td>
                      <td>{{ user.subject || "-" }}</td>
                      <td>
                        {{
                          user?.id && user?.tabletype == "LEAD_HISTORY"
                            ? "Note"
                            : user.statusPrimaryStr && user.statusAlternateStr
                              ? user.statusPrimaryStr +
                                " " +
                                user.statusAlternateStr
                              : user.statusPrimaryStr
                                ? user.statusPrimaryStr
                                : user.statusAlternateStr
                                  ? user.statusAlternateStr
                                  : ""
                        }}
                      </td>
                      <td>{{ user.relatedToName || "-" }}</td>
                      <td>{{ user.createdDate || "-" }}</td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-user>
                    <tr>
                      <td
                        class="text-align-center"
                        [attr.colspan]="cols?.length"
                      >
                        {{ emptyMessage }}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </p-card>
          </p-scrollPanel>
        </div>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Tasks">
    <div class="h-100">
      <div class="row margin-right-0px">
        <div class="progress-spinner" *ngIf="loading">
          <p-progressSpinner></p-progressSpinner>
        </div>
        <div class="col-lg-12">
          <p-scrollPanel [style]="{ width: '100%', height: 'auto' }">
            <p-card class="custom-card-design">
              <!-- table -->
              <div class="table-responsive" *ngIf="activeTabIndex == 1">
                <p-table
                  #paperDt
                  [scrollable]="true"
                  [scrollHeight]="'300px'"
                  [value]="taskData"
                  [lazy]="true"
                  (onLazyLoad)="taskLoadPaperLazy($event)"
                  [rows]="datatableRowsVal"
                  [showCurrentPageReport]="true"
                  [totalRecords]="totalRecords"
                  [rowsPerPageOptions]="rowPerPage"
                  styleClass="p-datatable-customers p-datatable-gridlines"
                  [paginator]="true"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  dataKey="id"
                >
                  <ng-template pTemplate="caption">
                    <div
                      class="row d-flex justify-content-end"
                      style="width: 100%; padding-right: 15px"
                    >
                      <div
                        class="searchbox col-lg-3 col-md-4 col-sm-12 width-100"
                      >
                        <span class="p-input-icon-left p-ml-auto">
                          <i class="pi pi-search"></i>
                          <input
                            pInputText
                            type="text"
                            (input)="
                              paperDt.filterGlobal(globalFilter, 'contains')
                            "
                            placeholder="Search"
                            [(ngModel)]="globalFilter"
                          />
                        </span>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                    <tr>
                      <th
                        class="text-align-center"
                        *ngFor="let tableHeading of cols"
                        [pSortableColumn]="tableHeading?.sortField"
                        [style.width]="tableHeading.colWidth"
                      >
                        {{ tableHeading.colName }}
                        <p-sortIcon
                          *ngIf="tableHeading.isSort"
                          [field]="tableHeading?.sortField"
                        ></p-sortIcon>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-user>
                    <tr>
                      <td>
                        {{ user.srNo }}
                      </td>
                      <td>{{ user.subject || "-" }}</td>
                      <td>
                        {{
                          user.statusPrimaryStr && user.statusAlternateStr
                            ? user.statusPrimaryStr +
                              " " +
                              user.statusAlternateStr
                            : user.statusPrimaryStr
                              ? user.statusPrimaryStr
                              : user.statusAlternateStr
                                ? user.statusAlternateStr
                                : ""
                        }}
                      </td>
                      <td>{{ user.relatedToName || "-" }}</td>
                      <td>{{ user.createdDate || "-" }}</td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-user>
                    <tr>
                      <td
                        class="text-align-center"
                        [attr.colspan]="cols?.length"
                      >
                        {{ emptyMessage }}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </p-card>
          </p-scrollPanel>
        </div>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Meetings">
    <div class="h-100">
      <div class="row margin-right-0px">
        <div class="progress-spinner" *ngIf="loading">
          <p-progressSpinner></p-progressSpinner>
        </div>
        <div class="col-lg-12">
          <p-scrollPanel [style]="{ width: '100%', height: 'auto' }">
            <p-card class="custom-card-design">
              <!-- table -->
              <div class="table-responsive" *ngIf="activeTabIndex == 2">
                <p-table
                  #paperDt
                  [scrollable]="true"
                  [scrollHeight]="'300px'"
                  [value]="meetingData"
                  [lazy]="true"
                  (onLazyLoad)="meetLoadPaperLazy($event)"
                  [rows]="datatableRowsVal"
                  [showCurrentPageReport]="true"
                  [totalRecords]="totalRecords"
                  [rowsPerPageOptions]="rowPerPage"
                  styleClass="p-datatable-customers p-datatable-gridlines"
                  [paginator]="true"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  dataKey="id"
                >
                  <ng-template pTemplate="caption">
                    <div
                      class="row d-flex justify-content-end"
                      style="width: 100%; padding-right: 15px"
                    >
                      <div
                        class="searchbox col-lg-3 col-md-4 col-sm-12 width-100"
                      >
                        <span class="p-input-icon-left p-ml-auto">
                          <i class="pi pi-search"></i>
                          <input
                            pInputText
                            type="text"
                            (input)="
                              paperDt.filterGlobal(globalFilter, 'contains')
                            "
                            placeholder="Search"
                            [(ngModel)]="globalFilter"
                          />
                        </span>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                    <tr>
                      <th
                        class="text-align-center"
                        *ngFor="let tableHeading of cols"
                        [pSortableColumn]="tableHeading?.sortField"
                        [style.width]="tableHeading.colWidth"
                      >
                        {{ tableHeading.colName }}
                        <p-sortIcon
                          *ngIf="tableHeading.isSort"
                          [field]="tableHeading?.sortField"
                        ></p-sortIcon>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-user>
                    <tr>
                      <td>
                        {{ user.srNo }}
                      </td>
                      <td>{{ user.subject || "-" }}</td>
                      <td>
                        {{
                          user.statusPrimaryStr && user.statusAlternateStr
                            ? user.statusPrimaryStr +
                              " " +
                              user.statusAlternateStr
                            : user.statusPrimaryStr
                              ? user.statusPrimaryStr
                              : user.statusAlternateStr
                                ? user.statusAlternateStr
                                : ""
                        }}
                      </td>
                      <td>{{ user.relatedToName || "-" }}</td>
                      <td>{{ user.createdDate || "-" }}</td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-user>
                    <tr>
                      <td
                        class="text-align-center"
                        [attr.colspan]="cols?.length"
                      >
                        {{ emptyMessage }}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </p-card>
          </p-scrollPanel>
        </div>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Calls">
    <div class="h-100">
      <div class="row margin-right-0px">
        <div class="progress-spinner" *ngIf="loading">
          <p-progressSpinner></p-progressSpinner>
        </div>
        <div class="col-lg-12">
          <p-scrollPanel [style]="{ width: '100%', height: 'auto' }">
            <p-card class="custom-card-design">
              <!-- table -->
              <div class="table-responsive" *ngIf="activeTabIndex == 3">
                <p-table
                  #paperDt
                  [scrollable]="true"
                  [scrollHeight]="'300px'"
                  [value]="callData"
                  [lazy]="true"
                  (onLazyLoad)="callLoadPaperLazy($event)"
                  [rows]="datatableRowsVal"
                  [showCurrentPageReport]="true"
                  [totalRecords]="totalRecords"
                  [rowsPerPageOptions]="rowPerPage"
                  styleClass="p-datatable-customers p-datatable-gridlines"
                  [paginator]="true"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  dataKey="id"
                >
                  <ng-template pTemplate="caption">
                    <div
                      class="row d-flex justify-content-end"
                      style="width: 100%; padding-right: 15px"
                    >
                      <div
                        class="searchbox col-lg-3 col-md-4 col-sm-12 width-100"
                      >
                        <span class="p-input-icon-left p-ml-auto">
                          <i class="pi pi-search"></i>
                          <input
                            pInputText
                            type="text"
                            (input)="
                              paperDt.filterGlobal(globalFilter, 'contains')
                            "
                            placeholder="Search"
                            [(ngModel)]="globalFilter"
                          />
                        </span>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                    <tr>
                      <th
                        class="text-align-center"
                        *ngFor="let tableHeading of cols"
                        [pSortableColumn]="tableHeading?.sortField"
                        [style.width]="tableHeading.colWidth"
                      >
                        {{ tableHeading.colName }}
                        <p-sortIcon
                          *ngIf="tableHeading.isSort"
                          [field]="tableHeading?.sortField"
                        ></p-sortIcon>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-user>
                    <tr>
                      <td>
                        {{ user.srNo }}
                      </td>
                      <td>{{ user.subject || "-" }}</td>
                      <td>
                        {{
                          user.statusPrimaryStr && user.statusAlternateStr
                            ? user.statusPrimaryStr +
                              " " +
                              user.statusAlternateStr
                            : user.statusPrimaryStr
                              ? user.statusPrimaryStr
                              : user.statusAlternateStr
                                ? user.statusAlternateStr
                                : ""
                        }}
                      </td>
                      <td>{{ user.relatedToName || "-" }}</td>
                      <td>{{ user.createdDate || "-" }}</td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-user>
                    <tr>
                      <td
                        class="text-align-center"
                        [attr.colspan]="cols?.length"
                      >
                        {{ emptyMessage }}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </p-card>
          </p-scrollPanel>
        </div>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Notes">
    <div class="h-100">
      <div class="row margin-right-0px">
        <div class="progress-spinner" *ngIf="loading">
          <p-progressSpinner></p-progressSpinner>
        </div>
        <div class="col-lg-12">
          <p-scrollPanel [style]="{ width: '100%', height: 'auto' }">
            <p-card class="custom-card-design">
              <!-- table -->
              <div class="table-responsive" *ngIf="activeTabIndex == 4">
                <p-table
                  #paperDt
                  [scrollable]="true"
                  [scrollHeight]="'300px'"
                  [value]="notesData"
                  [lazy]="true"
                  (onLazyLoad)="notesLoadPaperLazy($event)"
                  [rows]="datatableRowsVal"
                  [showCurrentPageReport]="true"
                  [totalRecords]="totalRecords"
                  [rowsPerPageOptions]="rowPerPage"
                  styleClass="p-datatable-customers p-datatable-gridlines"
                  [paginator]="true"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                  dataKey="id"
                >
                  <ng-template pTemplate="caption">
                    <div
                      class="row d-flex justify-content-end"
                      style="width: 100%; padding-right: 15px"
                    >
                      <div
                        class="searchbox col-lg-3 col-md-4 col-sm-12 width-100"
                      >
                        <span class="p-input-icon-left p-ml-auto">
                          <i class="pi pi-search"></i>
                          <input
                            pInputText
                            type="text"
                            (input)="
                              paperDt.filterGlobal(globalFilter, 'contains')
                            "
                            placeholder="Search"
                            [(ngModel)]="globalFilter"
                          />
                        </span>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                    <tr>
                      <th
                        class="text-align-center"
                        *ngFor="let tableHeading of cols"
                        [pSortableColumn]="tableHeading?.sortField"
                        [style.width]="tableHeading.colWidth"
                      >
                        {{ tableHeading.colName }}
                        <p-sortIcon
                          *ngIf="tableHeading.isSort"
                          [field]="tableHeading?.sortField"
                        ></p-sortIcon>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-user>
                    <tr>
                      <td>
                        {{ user.srNo }}
                      </td>
                      <td>{{ user.subject || "-" }}</td>
                      <td>Notes</td>
                      <td>{{ user.relatedToIdName || "-" }}</td>
                      <td>{{ user.createdDate || "-" }}</td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage" let-user>
                    <tr>
                      <td
                        class="text-align-center"
                        [attr.colspan]="cols?.length"
                      >
                        {{ emptyMessage }}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </p-card>
          </p-scrollPanel>
        </div>
      </div>
    </div>
  </p-tabPanel>
</p-tabView>
<div class="modal-footer">
  <button type="submit" class="btn btn-cancel" (click)="closeModel()">
    Close
  </button>
</div>

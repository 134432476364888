import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ApiService } from "src/app/helpers/services/api.service";
import { ToastService } from "src/app/helpers/services/toast.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent {
  email: string = "";

  forgotPasswordForm!: FormGroup;

  constructor(
    public _fb: FormBuilder,
    public http: HttpClient,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.forgotPasswordForm = this._fb.group({
      email: ["", [Validators.required, Validators.email]],
    });
  }

  get f(): any {
    return this.forgotPasswordForm["controls"];
  }

  onSubmit() {
    const forgotPasswordData = {
      email: this.email,
    };

    if (this.forgotPasswordForm.invalid) {
      this.forgotPasswordForm.markAllAsTouched();
      return;
    }
    this.http
      .post(`${environment.apiURL}/v1/user/userForgetPwd`, forgotPasswordData)
      .subscribe(
        (res: any) => {
          this.toast.setSuccessPopupMessage(res.message);
        },
        (error) => {
          this.toast.setErrorPopupMessage(error?.error?.message);
        }
      );
  }
}

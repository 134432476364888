import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { API_POINTS } from "src/app/helpers/model/endpoint.model";
import { ApiService } from "src/app/helpers/services/api.service";
import { ToastService } from "src/app/helpers/services/toast.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent {
  /* variables */
  loginForm!: FormGroup;
  showPassword: boolean = false;
  endpoint: any = API_POINTS;
  token: string = "";
  returnUrl: string = "";
  refreshToken: string = "";

  constructor(
    private http: HttpClient,
    public _fb: FormBuilder,
    private _router: Router,
    private _active_router: ActivatedRoute,
    public api_service: ApiService,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.loginForm = this._fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", [Validators.required, Validators.minLength(6)]],
    });
    // for active route
    this.returnUrl =
      this._active_router.snapshot.queryParams["returnUrl"] || "/lead";
  }
  ngAfterViewInit(): void {
    if (localStorage.getItem("access-token")) {
      this._router.navigateByUrl("/lead");
    }
  }

  /* form validation */
  get f(): any {
    return this.loginForm["controls"];
  }

  /* submit login form */
  onSubmit() {
    Object.values(this.loginForm?.controls).forEach((control: any) =>
      control?.markAsTouched()
    );
    if (this.loginForm.valid) {
      this.http
        .post(
          `${environment.apiURL}${this.endpoint.login}`,
          this.loginForm.value
        )
        .subscribe(
          (res: any) => {
            if (res.status === "SUCCESS") {
              localStorage.setItem("userData", JSON.stringify(res));
            }

            if (res && res["jwtToken"]) {
              this.token = res["jwtToken"];
              this.refreshToken = res["refreshToken"];
              localStorage.setItem("access-token", this.token);
              localStorage.setItem("refresh-token", this.refreshToken);
              this._router.navigate(["lead"]);
              this.api_service.startRefreshTokenTimer();
              this.loginForm.reset();
              // this.validateToken(this.token);
            }
          },
          (error: any) => {
            // Handle error
            this.toast.setErrorPopupMessage(error?.error?.message);
          }
        );
    }
  }
}

import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  constructor(private messageService: MessageService) {}

  setSuccessPopupMessage(message: any) {
    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 5000,
    });
  }
  setServerError(message: any) {
    this.messageService.add({
      severity: "warn",
      summary: "warning",
      detail: message,
      life: 5000,
    });
  }
  setErrorPopupMessage(message: any) {
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 5000,
    });
  }
}

<div class="modal-dialog-scrollable">
  <div class="modal-header">
    <h4 class="modal-title model-lable">
      {{
        !editListData?.id
          ? "Create Note Or Attachment"
          : "Update Note Or Attachment"
      }}
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="onCancel()"
    ></button>
  </div>
  <div class="modal-body custom-model-design">
    <div class="container-fluid h-100">
      <div class="progress-spinner" *ngIf="loading">
        <p-progressSpinner></p-progressSpinner>
      </div>
      <div class="col-12 padding-top-bottom-20">
        <form [formGroup]="form" class="custom-form-container">
          <div class="row">
            <div class="col-6 form-col">
              <label>Contact</label>
              <p-dropdown
                styleClass="width-100-perc"
                [options]="userMasterData?.contactResponsesList"
                formControlName="contact"
                optionLabel="name"
                optionValue="id"
                [showClear]="true"
                placeholder="Contact"
              ></p-dropdown>
            </div>
            <div class="col-6 form-col mb-2">
              <label>Related To</label>
              <div class="col-12">
                <div class="col-12" simplebar>
                  <p-dropdown
                    styleClass="width-100-perc"
                    [options]="allRetatedToData"
                    formControlName="relatedToPrimary"
                    optionLabel="key"
                    optionValue="value"
                    [showClear]="true"
                    placeholder="Related To"
                    (onChange)="
                      getRelatedtoData(formControl?.relatedToPrimary?.value)
                    "
                  ></p-dropdown>
                </div>
                <div class="col-12" style="padding-top: 10px">
                  <p-dropdown
                    styleClass="width-100-perc"
                    [options]="idRelatedToData"
                    formControlName="relatedToSecondary"
                    optionLabel="name"
                    optionValue="id"
                    [showClear]="true"
                    placeholder="Related To"
                  ></p-dropdown>
                </div>
              </div>
            </div>
            <div class="col-6 form-col">
              <label>Subject<span class="text-invalid"> *</span></label>
              <input
                pInputText
                type="text"
                class="form-control"
                placeholder="Subject"
                formControlName="subject"
              />
              <div
                *ngIf="
                  formControl.subject?.invalid &&
                  (formControl.subject?.dirty || formControl.subject?.touched)
                "
                class="text-invalid"
                role="alert"
              >
                <small *ngIf="formControl.subject?.errors?.required">
                  Subject is required.
                </small>
                <small *ngIf="formControl.subject?.errors?.maxlength">
                  Subject cannot be more than 200 characters long.
                </small>
              </div>
              <div class="text-invalid" *ngIf="isInvalid">
                {{ validationErrors["subject"] }}
              </div>
            </div>
            <div class="col-6 form-col custom-select">
              <label>Assigned To</label>
              <div>
                <p-dropdown
                  styleClass="width-100-perc"
                  [options]="userMasterData?.userResponsesList"
                  formControlName="assignedTo"
                  optionLabel="name"
                  optionValue="id"
                  [showClear]="true"
                  placeholder="--- Select User ---"
                ></p-dropdown>
              </div>
            </div>
            <div class="col-6 form-col">
              <label>Attachment</label>
              <input
                class="form-control"
                style="width: auto !important; height: auto"
                type="file"
                id="formFile"
                (change)="onUpload($event)"
              />
            </div>
            <div class="col-6 form-col" *ngIf="this.editListData?.attachment">
              <label style="display: flex !important"
                >Previous Attached File</label
              >
              <button
                class="btn custom-download-btn"
                type="submit"
                (click)="onDownloadAttachment()"
              >
                Download File
              </button>
            </div>
            <div class="col-12 form-col">
              <label>Note </label>
              <textarea
                pInputText
                formControlName="note"
                rows="4"
                cols="50"
                type="text"
                class="form-control"
              ></textarea>
              <div
                *ngIf="
                  formControl.note?.invalid &&
                  (formControl.note?.dirty || formControl.note?.touched)
                "
                class="text-invalid"
                role="alert"
              >
                <small *ngIf="formControl.note?.errors?.maxlength">
                  Note cannot be more than 500 characters long.
                </small>
              </div>
              <div class="text-invalid" *ngIf="isInvalid">
                {{ validationErrors["note"] }}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="col-12 text-end">
      <button type="cancel" class="btn btn-cancel" (click)="onCancel()">
        Cancel
      </button>
      <button type="submit" class="btn btn-submit" (click)="onSubmit()">
        {{ !editListData?.id ? "Submit" : "Update" }}
      </button>
    </div>
  </div>
</div>

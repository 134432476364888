<div class="container-fluid p-0">
  <div class="d-flex row h-100">
    <div class="col-7 p-0">
      <img
        src="../../../assets/images/image.jpg"
        alt="forgot password"
        class="float-start"
        width="100%"
        height="100%"
      />
    </div>
    <div class="col-5 container-parent">
      <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
        <div class="text-center">
          <h2>Reset Password</h2>
        </div>
        <div class="form-col">
          <div class="password-container">
            <label>Password<span class="text-invalid"> *</span></label>
            <input
              formControlName="password"
              [type]="showPassword ? 'text' : 'password'"
              class="form-control"
              placeholder="Password"
            />
            <div class="view-password">
              <i
                alt="show"
                [class]="showPassword ? 'bi bi-eye' : 'bi bi-eye-slash'"
                (click)="toggleShowPassword()"
              ></i>
            </div>
          </div>
          <div
            *ngIf="
              f.password.invalid && (f.password.dirty || f.password.touched)
            "
            class="text-invalid"
          >
            <small *ngIf="f.password.errors?.required">
              Password is required.
            </small>
            <small *ngIf="f.password.errors?.minlength">
              At least 6 characters long.
            </small>
          </div>
          <div class="password-container mt-2">
            <label>Confirm Password<span class="text-invalid"> *</span></label>
            <input
              formControlName="confirmPassword"
              [type]="showConfirmPassword ? 'text' : 'password'"
              class="form-control"
              placeholder="Confirm Password"
            />
            <div class="view-password">
              <i
                alt="show"
                [class]="showConfirmPassword ? 'bi bi-eye' : 'bi bi-eye-slash'"
                (click)="toggleShowConfirmPassword()"
              ></i>
            </div>
          </div>
          <div
            *ngIf="
              f.confirmPassword.invalid && (f.confirmPassword.dirty || f.confirmPassword.touched)
            "
            class="text-invalid"
          >
            <small *ngIf="f.confirmPassword.errors?.required">
              Confirm Password is required.
            </small>
            <small *ngIf="f.confirmPassword.errors?.minlength">
              At least 6 characters long.
            </small>
          </div>
          <div *ngIf="passwordsMismatch" class="text-invalid">
            <small>Passwords do not match.</small>
          </div>
        </div>
        <div class="row d-flex mt-4">
          <div class="col-12 text-end">
            <button type="submit" class="btn btn-lg-login text-white btn-lg">
              Reset Password
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
